import { ORDERS_WARNING_DISABLED } from 'constants/defaults'
import { RootState } from 'state/store'

export const selectShowModal = (state: RootState) => state.app.showModal?.type

export const selectShowModalWithParams = (state: RootState) => state.app.showModal

export const selectShowPositionModal = (state: RootState) => state.app.showPositionModal

export const selectShowConditionalOrderModal = (state: RootState) =>
	state.app.showConditionalOrderModal

export const selectTransaction = (state: RootState) => state.app.transaction

export const selectAckedOrdersWarning = (state: RootState) => {
	return ORDERS_WARNING_DISABLED || state.app.acknowledgedOrdersWarning
}

export const selectShowBanner = (state: RootState) => state.app.showBanner
