import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { monitorTransaction } from 'contexts/RelayerContext'
import { handleTransactionError } from 'state/app/reducer'
import { selectTransaction } from 'state/app/selectors'
import { useAppSelector } from 'state/hooks'

import useMonitorTrades from './useMonitorTrades'

export default function useMonitorTransactions() {
	const dispatch = useDispatch()
	const transaction = useAppSelector(selectTransaction)
	useMonitorTrades()

	useEffect(() => {
		if (transaction?.hash && !transaction.order) {
			monitorTransaction({
				txHash: transaction.hash,
				onTxFailed: (err) => {
					dispatch(handleTransactionError({ message: err.failureReason ?? 'transaction_failed' }))
				},
			})
		}
	}, [transaction?.hash, transaction?.order, dispatch])
}
