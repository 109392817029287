import { DepositableAssetKeysV3Arb, V3_DEPOSITABLE_ASSETS_ARB } from '@kwenta/sdk/constants'

export const V3_DEPOSIT_OPTIONS_ARB: { label: string; value: DepositableAssetKeysV3Arb }[] =
	Object.keys(V3_DEPOSITABLE_ASSETS_ARB).map((key) => ({
		label: V3_DEPOSITABLE_ASSETS_ARB[key as DepositableAssetKeysV3Arb],
		value: key as DepositableAssetKeysV3Arb,
	}))

// TODO: This is very simplistic as does not fully take into consideration the liquidation reward
// However it is not possible to calculate without new methods being exposed on the contract
export const REQUIRED_MARGIN_RATIO = 0.03
