import { PerpsProvider } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'

export const PERPS_VERSIONS_ENABLED =
	process.env.NEXT_PUBLIC_PERPS_VERSIONS_ENABLED ??
	'snx_v3_base,snx_v2_op,perennial_v2_arb,snx_v3_arb'

export const SNX_V2_ENABLED = PERPS_VERSIONS_ENABLED.includes('snx_v2_op')
export const SNX_V3_ENABLED = PERPS_VERSIONS_ENABLED.includes('snx_v3_base')
export const PERENNIAL_V2_ENABLED = PERPS_VERSIONS_ENABLED.includes('perennial_v2_arb')

export const DEFAULT_PERPS_PROVIDER = (process.env.NEXT_PUBLIC_DEFAULT_PERPS_PROVIDER ??
	(SNX_V3_ENABLED ? PerpsProvider.SNX_V3_BASE : PerpsProvider.SNX_V2_OP)) as PerpsProvider

export const SUPPORTED_PROVIDERS = PERPS_VERSIONS_ENABLED.split(',') as PerpsProvider[]

export const DEFAULT_MAX_EXECUTOR_FEE_USD = wei(1)
