import axios from 'axios';
import { queryMirrorPosts } from '../queries/mirror';
import { MAX_MIRROR_POSTS, arweaveURL } from '../constants/mirror';
export default class MirrorService {
    async getMirrorPosts() {
        const postData = await queryMirrorPosts();
        const postsWithDuplicates = await Promise.all(postData.map((data) => axios.get(`${arweaveURL}/${data.txId}`).then((res) => ({
            title: res.data.content.title,
            body: res.data.wnft?.description,
            imageURI: res.data.wnft?.imageURI,
            contentDigest: data.contentDigest,
        }))));
        return postsWithDuplicates
            .map((post) => ({
            ...post,
            imageURI: postsWithDuplicates.find((p) => p.contentDigest === post.contentDigest && !!p.imageURI)?.imageURI,
            body: postsWithDuplicates.find((p) => p.contentDigest === post.contentDigest && !!p.body)
                ?.body,
        }))
            .filter((post, index, posts) => index === posts.findIndex((p) => p.contentDigest === post.contentDigest))
            .filter((post) => post.title && post.body)
            .slice(0, MAX_MIRROR_POSTS);
    }
}
