import { WalletClientType } from '@kwenta/sdk/types'
import { PerpsProvider } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'

import { fetchBalances } from 'state/balances/actions'
import { fetchPerpsAccounts, fetchSubAccountsForAccount } from 'state/futures/actions'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import {
	fetchClaimableRewards,
	fetchClaimableRewardsParams,
	fetchEscrowData,
	fetchMigrationDetails,
	fetchStakingData,
} from 'state/staking/actions'
import { setSelectedUserMigrationInfo } from 'state/stakingMigration/reducer'
import type { ThunkConfig } from 'state/types'

import { disconnect, setWalletAddress } from './reducer'
import { SetWalletPayload } from './types'

export const resetWalletAddress = createAsyncThunk<void, SetWalletPayload, ThunkConfig>(
	'wallet/resetWalletAddress',
	async ({ address, selectedType }, { dispatch, getState }) => {
		const perpsProvider = selectPerpsProvider(getState())
		dispatch(setWalletAddress({ address, selectedType }))
		if (selectedType === 'signer') {
			dispatch(setSelectedUserMigrationInfo(address))
			dispatch(fetchPerpsAccounts({ providers: [perpsProvider] }))
			dispatch(fetchBalances())
			dispatch(fetchClaimableRewards())
			dispatch(fetchClaimableRewardsParams())
			dispatch(fetchStakingData())
			dispatch(fetchEscrowData())
			dispatch(fetchMigrationDetails())
		}
		Sentry.setUser({ id: address })
	}
)

export const setWalletClient = createAsyncThunk<
	void,
	WalletClientType | null | undefined,
	ThunkConfig
>('wallet/setWalletClient', async (walletClient, { dispatch, extra: { sdk } }) => {
	if (!!walletClient) {
		await sdk.setWalletClient(walletClient)
	} else {
		dispatch(disconnect())
		dispatch({ type: 'balances/clearBalances' })
	}
	dispatch(fetchSubAccountsForAccount([PerpsProvider.SNX_V2_OP, PerpsProvider.SNX_V3_BASE]))
})
