import { V3SynthMarketKey } from '@kwenta/sdk/constants'
import Wei, { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from 'state/store'
import {
	unserializeBalances,
	unserializeTokenAllowances,
	unserializeTokenBalances,
	unserializeV3Balances,
} from 'utils/balances'

export const selectBalances = createSelector(
	(state: RootState) => state.balances,
	(balances) => {
		const tokenBalances = unserializeTokenBalances(balances.tokenBalances)
		const bals = unserializeBalances(
			balances.synthBalancesMap,
			balances.totalUSDBalance || '0',
			balances.susdWalletBalance || '0'
		)
		return {
			...bals,
			tokenBalances,
		}
	}
)

export const selectTokenAllowances = createSelector(
	(state: RootState) => state.balances.tokenAllowances,
	(tokenAllowances) => unserializeTokenAllowances(tokenAllowances)
)

export const selectSynthV3BalancesAndAllowances = createSelector(
	(state: RootState) => state.balances.synthV3Balances,
	(synthV3Balances) => unserializeV3Balances(synthV3Balances)
)

export const selectSynthV3Balances = createSelector(
	(state: RootState) => state.balances.synthV3Balances,
	(synthV3Balances) => {
		const values = unserializeV3Balances(synthV3Balances)
		return Object.fromEntries(
			Object.entries(values).map(([key, value]) => [key, value.balance])
		) as Record<V3SynthMarketKey, Wei>
	}
)

export const selectV3DepositableBalances = createSelector(
	selectSynthV3Balances,
	selectBalances,
	(synthV3Balances, { tokenBalances }) => {
		const usdcBal = wei(tokenBalances.USDC ?? 0)
		const wethBal = wei(tokenBalances.WETH ?? 0)
		return {
			...synthV3Balances,
			USDC: usdcBal,
			WETH: wethBal,
		}
	}
)

export const selectUsdcBalance = createSelector(
	selectV3DepositableBalances,
	(balances) => balances.USDC ?? wei('0')
)
export const selectEthWalletBalance = createSelector(
	(state: RootState) => state.balances,
	(balances) => wei(balances.ethWalletBalance ?? 0)
)
