// TODO: Make this dynamic
import { FuturesMarketKey, FuturesMarketAsset, PerpsProvider } from '../types';
import { MarketAssetByKey, inverseObj } from '../utils';
import { PERPS_PYTH_IDS } from './futures';
export const V3_SYNTH_MARKET_IDS_BASE = {
    SNX_USD: 0,
    SNX_USDC: 1,
};
export const V3_SYNTH_MARKET_IDS_ARB = {
    SNX_USD: 0,
    SNX_BTC: 1,
    SNX_ETH: 2,
};
export const V3_PERPS_MARKET_IDS = {
    [FuturesMarketKey.sETHPERP]: '100',
    [FuturesMarketKey.sBTCPERP]: '200',
    [FuturesMarketKey.sSNXPERP]: '300',
    [FuturesMarketKey.sSOLPERP]: '400',
    [FuturesMarketKey.sWIFPERP]: '500',
    [FuturesMarketKey.sWPERP]: '600',
    [FuturesMarketKey.sENAPERP]: '700',
    [FuturesMarketKey.sDOGEPERP]: '800',
    [FuturesMarketKey.sAVAXPERP]: '900',
    [FuturesMarketKey.sOPPERP]: '1000',
    [FuturesMarketKey.sORDIPERP]: '1100',
    [FuturesMarketKey.sPEPEPERP]: '1200',
    [FuturesMarketKey.sRUNEPERP]: '1300',
    [FuturesMarketKey.sBONKPERP]: '1400',
    [FuturesMarketKey.sFTMPERP]: '1500',
    [FuturesMarketKey.sARBPERP]: '1600',
    [FuturesMarketKey.sMATICPERP]: '1700',
    [FuturesMarketKey.sBNBPERP]: '1800',
    [FuturesMarketKey.sLINKPERP]: '1900',
    [FuturesMarketKey.sPENDLEPERP]: '2000',
    [FuturesMarketKey.sINJPERP]: '2100',
    [FuturesMarketKey.sGMXPERP]: '2200',
    [FuturesMarketKey.sTIAPERP]: '2300',
    [FuturesMarketKey.sSUIPERP]: '2400',
    [FuturesMarketKey.sTONPERP]: '2500',
    [FuturesMarketKey.sARKMPERP]: '2600',
    [FuturesMarketKey.sGALAPERP]: '2700',
    [FuturesMarketKey.sTAOPERP]: '2800',
    [FuturesMarketKey.sBOMEPERP]: '2900',
    [FuturesMarketKey.sETHFIPERP]: '3000',
    [FuturesMarketKey.sSTXPERP]: '3100',
    [FuturesMarketKey.sAXLPERP]: '3200',
    [FuturesMarketKey.sAAVEPERP]: '3300',
    [FuturesMarketKey.sADAPERP]: '3400',
    [FuturesMarketKey.sALGOPERP]: '3500',
    [FuturesMarketKey.sAPTPERP]: '3600',
    [FuturesMarketKey.sATOMPERP]: '3700',
    [FuturesMarketKey.sAXSPERP]: '3800',
    [FuturesMarketKey.sBALPERP]: '3900',
    [FuturesMarketKey.sBCHPERP]: '4000',
    [FuturesMarketKey.sBLURPERP]: '4100',
    [FuturesMarketKey.sCOMPPERP]: '4200',
    [FuturesMarketKey.sCRVPERP]: '4300',
    [FuturesMarketKey.sDOTPERP]: '4400',
    [FuturesMarketKey.sDYDXPERP]: '4500',
    [FuturesMarketKey.sEOSPERP]: '4600',
    [FuturesMarketKey.sETCPERP]: '4700',
    [FuturesMarketKey.sETHBTCPERP]: '4800',
    [FuturesMarketKey.sFILPERP]: '4900',
    [FuturesMarketKey.sFLOWPERP]: '5000',
    [FuturesMarketKey.sFXSPERP]: '5100',
    [FuturesMarketKey.sGRTPERP]: '5200',
    [FuturesMarketKey.sICPPERP]: '5300',
    [FuturesMarketKey.sIMXPERP]: '5400',
    [FuturesMarketKey.sJTOPERP]: '5500',
    [FuturesMarketKey.sJUPPERP]: '5600',
    [FuturesMarketKey.sLDOPERP]: '5700',
    [FuturesMarketKey.sLTCPERP]: '5800',
    [FuturesMarketKey.sMEMEPERP]: '6000',
    [FuturesMarketKey.sNEARPERP]: '6100',
    [FuturesMarketKey.sPYTHPERP]: '6200',
    [FuturesMarketKey.sRNDRPERP]: '6300',
    [FuturesMarketKey.sSEIPERP]: '6400',
    [FuturesMarketKey.sSHIBPERP]: '6500',
    [FuturesMarketKey.sSTRKPERP]: '6600',
    [FuturesMarketKey.sSUSHIPERP]: '6700',
    [FuturesMarketKey.sTRXPERP]: '6800',
    [FuturesMarketKey.sUNIPERP]: '6900',
    [FuturesMarketKey.sXLMPERP]: '7000',
    [FuturesMarketKey.sXRPPERP]: '7100',
    [FuturesMarketKey.sYFIPERP]: '7200',
};
export const PREDEFINED_ORACLES = [
    PERPS_PYTH_IDS.sETH,
    PERPS_PYTH_IDS.sBTC,
    PERPS_PYTH_IDS.WIF,
    PERPS_PYTH_IDS.SNX,
    PERPS_PYTH_IDS.SOL,
    PERPS_PYTH_IDS.W,
    PERPS_PYTH_IDS.ARB,
    PERPS_PYTH_IDS.AVAX,
    PERPS_PYTH_IDS.BNB,
    PERPS_PYTH_IDS.BONK,
    PERPS_PYTH_IDS.DOGE,
    PERPS_PYTH_IDS.ENA,
    PERPS_PYTH_IDS.FTM,
    PERPS_PYTH_IDS.MATIC,
    PERPS_PYTH_IDS.OP,
    PERPS_PYTH_IDS.ORDI,
    PERPS_PYTH_IDS.PEPE,
    PERPS_PYTH_IDS.RUNE,
    PERPS_PYTH_IDS.LINK,
    PERPS_PYTH_IDS.PENDLE,
    PERPS_PYTH_IDS.INJ,
    PERPS_PYTH_IDS.GMX,
    PERPS_PYTH_IDS.TIA,
    PERPS_PYTH_IDS.SUI,
    PERPS_PYTH_IDS.TON,
    PERPS_PYTH_IDS.ARKM,
    PERPS_PYTH_IDS.GALA,
    PERPS_PYTH_IDS.TAO,
    PERPS_PYTH_IDS.BOME,
    PERPS_PYTH_IDS.ETHFI,
    PERPS_PYTH_IDS.STX,
    PERPS_PYTH_IDS.AXL,
    PERPS_PYTH_IDS.AAVE,
    PERPS_PYTH_IDS.ADA,
    PERPS_PYTH_IDS.ALGO,
    PERPS_PYTH_IDS.APT,
    PERPS_PYTH_IDS.ATOM,
    PERPS_PYTH_IDS.AXS,
    PERPS_PYTH_IDS.BAL,
    PERPS_PYTH_IDS.BCH,
    PERPS_PYTH_IDS.BLUR,
    PERPS_PYTH_IDS.COMP,
    PERPS_PYTH_IDS.CRV,
    PERPS_PYTH_IDS.DOT,
    PERPS_PYTH_IDS.DYDX,
    PERPS_PYTH_IDS.EOS,
    PERPS_PYTH_IDS.ETC,
    PERPS_PYTH_IDS.ETHBTC,
    PERPS_PYTH_IDS.FIL,
    PERPS_PYTH_IDS.FLOW,
    PERPS_PYTH_IDS.FXS,
    PERPS_PYTH_IDS.GRT,
    PERPS_PYTH_IDS.ICP,
    PERPS_PYTH_IDS.IMX,
    PERPS_PYTH_IDS.JTO,
    PERPS_PYTH_IDS.JUP,
    PERPS_PYTH_IDS.LDO,
    PERPS_PYTH_IDS.LTC,
    PERPS_PYTH_IDS.MEME,
    PERPS_PYTH_IDS.NEAR,
    PERPS_PYTH_IDS.PYTH,
    PERPS_PYTH_IDS.SEI,
    PERPS_PYTH_IDS.SHIB,
    PERPS_PYTH_IDS.STRK,
    PERPS_PYTH_IDS.SUSHI,
    PERPS_PYTH_IDS.TRX,
    PERPS_PYTH_IDS.UNI,
    PERPS_PYTH_IDS.XLM,
    PERPS_PYTH_IDS.XRP,
    PERPS_PYTH_IDS.YFI,
];
export const PREDEFINED_ORACLES_BY_MARKET_ID = {
    [FuturesMarketKey.sETHPERP]: PERPS_PYTH_IDS.sETH,
    [FuturesMarketKey.sBTCPERP]: PERPS_PYTH_IDS.sBTC,
    [FuturesMarketKey.sSNXPERP]: PERPS_PYTH_IDS.SNX,
    [FuturesMarketKey.sSOLPERP]: PERPS_PYTH_IDS.SOL,
    [FuturesMarketKey.sWIFPERP]: PERPS_PYTH_IDS.WIF,
    [FuturesMarketKey.sWPERP]: PERPS_PYTH_IDS.W,
    [FuturesMarketKey.sARBPERP]: PERPS_PYTH_IDS.ARB,
    [FuturesMarketKey.sAVAXPERP]: PERPS_PYTH_IDS.AVAX,
    [FuturesMarketKey.sBNBPERP]: PERPS_PYTH_IDS.BNB,
    [FuturesMarketKey.sBONKPERP]: PERPS_PYTH_IDS.BONK,
    [FuturesMarketKey.sDOGEPERP]: PERPS_PYTH_IDS.DOGE,
    [FuturesMarketKey.sENAPERP]: PERPS_PYTH_IDS.ENA,
    [FuturesMarketKey.sFTMPERP]: PERPS_PYTH_IDS.FTM,
    [FuturesMarketKey.sMATICPERP]: PERPS_PYTH_IDS.MATIC,
    [FuturesMarketKey.sOPPERP]: PERPS_PYTH_IDS.OP,
    [FuturesMarketKey.sORDIPERP]: PERPS_PYTH_IDS.ORDI,
    [FuturesMarketKey.sPEPEPERP]: PERPS_PYTH_IDS.PEPE,
    [FuturesMarketKey.sRUNEPERP]: PERPS_PYTH_IDS.RUNE,
    [FuturesMarketKey.sLINKPERP]: PERPS_PYTH_IDS.LINK,
    [FuturesMarketKey.sPENDLEPERP]: PERPS_PYTH_IDS.PENDLE,
    [FuturesMarketKey.sINJPERP]: PERPS_PYTH_IDS.INJ,
    [FuturesMarketKey.sGMXPERP]: PERPS_PYTH_IDS.GMX,
    [FuturesMarketKey.sTIAPERP]: PERPS_PYTH_IDS.TIA,
    [FuturesMarketKey.sSUIPERP]: PERPS_PYTH_IDS.SUI,
    [FuturesMarketKey.sTONPERP]: PERPS_PYTH_IDS.TON,
    [FuturesMarketKey.sARKMPERP]: PERPS_PYTH_IDS.ARKM,
    [FuturesMarketKey.sGALAPERP]: PERPS_PYTH_IDS.GALA,
    [FuturesMarketKey.sTAOPERP]: PERPS_PYTH_IDS.TAO,
    [FuturesMarketKey.sBOMEPERP]: PERPS_PYTH_IDS.BOME,
    [FuturesMarketKey.sETHFIPERP]: PERPS_PYTH_IDS.ETHFI,
    [FuturesMarketKey.sSTXPERP]: PERPS_PYTH_IDS.STX,
    [FuturesMarketKey.sAXLPERP]: PERPS_PYTH_IDS.AXL,
    [FuturesMarketKey.sAAVEPERP]: PERPS_PYTH_IDS.AAVE,
    [FuturesMarketKey.sADAPERP]: PERPS_PYTH_IDS.ADA,
    [FuturesMarketKey.sALGOPERP]: PERPS_PYTH_IDS.ALGO,
    [FuturesMarketKey.sAPTPERP]: PERPS_PYTH_IDS.APT,
    [FuturesMarketKey.sATOMPERP]: PERPS_PYTH_IDS.ATOM,
    [FuturesMarketKey.sAXSPERP]: PERPS_PYTH_IDS.AXS,
    [FuturesMarketKey.sBALPERP]: PERPS_PYTH_IDS.BAL,
    [FuturesMarketKey.sBCHPERP]: PERPS_PYTH_IDS.BCH,
    [FuturesMarketKey.sBLURPERP]: PERPS_PYTH_IDS.BLUR,
    [FuturesMarketKey.sCOMPPERP]: PERPS_PYTH_IDS.COMP,
    [FuturesMarketKey.sCRVPERP]: PERPS_PYTH_IDS.CRV,
    [FuturesMarketKey.sDOTPERP]: PERPS_PYTH_IDS.DOT,
    [FuturesMarketKey.sDYDXPERP]: PERPS_PYTH_IDS.DYDX,
    [FuturesMarketKey.sEOSPERP]: PERPS_PYTH_IDS.EOS,
    [FuturesMarketKey.sETCPERP]: PERPS_PYTH_IDS.ETC,
    [FuturesMarketKey.sETHBTCPERP]: PERPS_PYTH_IDS.ETHBTC,
    [FuturesMarketKey.sFILPERP]: PERPS_PYTH_IDS.FIL,
    [FuturesMarketKey.sFLOWPERP]: PERPS_PYTH_IDS.FLOW,
    [FuturesMarketKey.sFXSPERP]: PERPS_PYTH_IDS.FXS,
    [FuturesMarketKey.sGRTPERP]: PERPS_PYTH_IDS.GRT,
    [FuturesMarketKey.sICPPERP]: PERPS_PYTH_IDS.ICP,
    [FuturesMarketKey.sIMXPERP]: PERPS_PYTH_IDS.IMX,
    [FuturesMarketKey.sJTOPERP]: PERPS_PYTH_IDS.JTO,
    [FuturesMarketKey.sJUPPERP]: PERPS_PYTH_IDS.JUP,
    [FuturesMarketKey.sLDOPERP]: PERPS_PYTH_IDS.LDO,
    [FuturesMarketKey.sLTCPERP]: PERPS_PYTH_IDS.LTC,
    [FuturesMarketKey.sMEMEPERP]: PERPS_PYTH_IDS.MEME,
    [FuturesMarketKey.sNEARPERP]: PERPS_PYTH_IDS.NEAR,
    [FuturesMarketKey.sPYTHPERP]: PERPS_PYTH_IDS.PYTH,
    [FuturesMarketKey.sRNDRPERP]: PERPS_PYTH_IDS.RNDR,
    [FuturesMarketKey.sSEIPERP]: PERPS_PYTH_IDS.SEI,
    [FuturesMarketKey.sSHIBPERP]: PERPS_PYTH_IDS.SHIB,
    [FuturesMarketKey.sSTRKPERP]: PERPS_PYTH_IDS.STRK,
    [FuturesMarketKey.sSUSHIPERP]: PERPS_PYTH_IDS.SUSHI,
    [FuturesMarketKey.sTRXPERP]: PERPS_PYTH_IDS.TRX,
    [FuturesMarketKey.sUNIPERP]: PERPS_PYTH_IDS.UNI,
    [FuturesMarketKey.sXLMPERP]: PERPS_PYTH_IDS.XLM,
    [FuturesMarketKey.sXRPPERP]: PERPS_PYTH_IDS.XRP,
    [FuturesMarketKey.sYFIPERP]: PERPS_PYTH_IDS.YFI,
};
export const V3_SYNTH_MARKET_KEYS_BASE = Object.keys(V3_SYNTH_MARKET_IDS_BASE);
export const V3_SYNTH_MARKET_KEYS_ARB = Object.keys(V3_SYNTH_MARKET_IDS_ARB);
export const V3_SYNTH_MARKET_KEYS = [...V3_SYNTH_MARKET_KEYS_BASE, ...V3_SYNTH_MARKET_KEYS_ARB];
export const V3_PERPS_MARKET_IDS_TO_KEYS = inverseObj(V3_PERPS_MARKET_IDS);
export const V3_SYNTH_MARKET_IDS = {
    [PerpsProvider.SNX_V3_ARB]: V3_SYNTH_MARKET_IDS_ARB,
    [PerpsProvider.SNX_V3_BASE]: V3_SYNTH_MARKET_IDS_BASE,
};
export const v3PerpsMarketIdToAssetKey = (marketId) => {
    const key = V3_PERPS_MARKET_IDS_TO_KEYS[marketId];
    return MarketAssetByKey[key];
};
export const v3PythIdByMarketId = (marketId) => {
    const key = V3_PERPS_MARKET_IDS_TO_KEYS[marketId];
    return PREDEFINED_ORACLES_BY_MARKET_ID[key];
};
export const v3SynthKeyToV2Asset = (key) => {
    const asset = key.replace('SNX_', '');
    if (asset === 'ETH')
        return FuturesMarketAsset.sETH;
    if (asset === 'BTC')
        return FuturesMarketAsset.sBTC;
    if (asset === 'USD')
        return 'sUSD';
    return asset;
};
export const ORDERS_KEEPER_ADDRESSES = {
    84532: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    8453: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    // TODO: Mainnet keeper
};
export const KWENTA_PERPS_V3_REFERRAL_ADDRESS = '0x3bD64247d879AF879e6f6e62F81430186391Bdb8';
export const V3_DEPOSITABLE_ASSETS_BASE = {
    USDC: 'USDC',
};
export const V3_DEPOSITABLE_ASSETS_ARB = {
    USDC: 'USDC',
    WETH: 'WETH',
    SNX_USD: 'sUSD',
};
