import { erc20Abi, maxUint160, maxUint256, maxUint48 } from 'viem';
import { DEFAULT_1INCH_SLIPPAGE, KWENTA_ONE_INCH_REFERRAL_ADDRESS, ONE_INCH_PROTOCOLS, PERMIT2_ADDRESS, PERMIT2_DOMAIN_NAME, PERMIT_TYPES, } from '../constants';
import { weiFromWei } from '../utils';
import { wei } from '@kwenta/wei';
import axios from 'axios';
export function permit2Domain(chainId) {
    return {
        name: PERMIT2_DOMAIN_NAME,
        verifyingContract: PERMIT2_ADDRESS,
        chainId,
    };
}
export default class TokensService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    oneInchApiUrl(chainId) {
        return `${process.env.NEXT_PUBLIC_SERVICES_PROXY}/1inch/swap/v5.2/${chainId}/`;
    }
    /**
     * @desc Get permit nonce
     * @param owner Wallet address
     * @param token ERC20 address
     * @param spender Spender address
     * @returns Permit 2 allowance data including allowance, expiry and noce
     */
    async getPermit2AllowanceData(owner, token, spender, chainId) {
        const Permit2 = this.sdk.context.contractConfigs[chainId]?.common.Permit2;
        if (!Permit2)
            throw new Error('Permit2 contract not found');
        const client = this.sdk.context.clients[chainId];
        if (!client)
            throw new Error('Unsupported chain ID ' + chainId);
        const [amount, expiry, nonce] = await client.readContract({
            ...Permit2,
            functionName: 'allowance',
            args: [owner, token, spender],
        });
        return {
            allowance: weiFromWei(amount.toString()),
            nonce: Number(nonce.toString()),
            expiry: Number(expiry.toString()),
        };
    }
    /**
     *
     * @param sdk
     * @param tokenAddress
     * @param owner
     * @param spender
     * @param amount
     * @param deadline
     * @returns Permit2 typed data
     */
    async getPermit2TypedData({ tokenAddress, owner, spender, amount, deadline, }, chainId) {
        const { Permit2 } = this.sdk.context.contractConfigs[chainId]?.common ?? {};
        if (!Permit2)
            throw new Error('Permit2 contract not found');
        if (!chainId)
            throw new Error('Chain ID not set');
        const { nonce } = await this.getPermit2AllowanceData(owner, tokenAddress, spender, chainId);
        const details = {
            token: tokenAddress,
            amount: BigInt(amount ?? maxUint160),
            expiration: BigInt(deadline ?? maxUint48),
            nonce: nonce,
        };
        const message = {
            details,
            spender,
            // TODO: Set reasonable deadlines
            sigDeadline: deadline ?? maxUint48,
        };
        const domain = permit2Domain(chainId);
        return {
            domain,
            account: owner,
            primaryType: 'PermitSingle',
            types: PERMIT_TYPES,
            message: message,
        };
    }
    async approveTokenSpend({ address, spender, signer, amount = maxUint256, chainId, }) {
        return await this.sdk.context.publicClient(chainId).simulateContract({
            abi: erc20Abi,
            address,
            account: signer ?? this.sdk.context.walletAddress,
            functionName: 'approve',
            args: [spender, amount],
        });
    }
    async checkAllowance(address, spender, chainId, signer) {
        const allowance = await this.sdk.context.publicClient(chainId).readContract({
            abi: erc20Abi,
            address,
            functionName: 'allowance',
            args: [signer ?? this.sdk.context.walletAddress, spender],
        });
        return allowance;
    }
    async checkAllowancesForAssets(assets, spenders, chainId, signer) {
        const calls = spenders
            .map((spender) => {
            return assets.map((asset) => {
                const tokenContract = this.sdk.context.contractConfigs[chainId]?.common[asset];
                if (!tokenContract)
                    throw new Error(`${asset} contract not found on current network`);
                return {
                    contract: tokenContract,
                    asset: asset,
                    spender: spender,
                };
            });
        })
            .flat();
        const res = await this.sdk.context.publicClient(chainId).multicall({
            allowFailure: false,
            contracts: calls.map(({ contract, spender }) => ({
                ...contract,
                functionName: 'allowance',
                args: [signer ?? this.sdk.context.walletAddress, spender],
            })),
        });
        if (!res)
            throw new Error('Allowances multicall failed');
        return res.reduce((acc, r, i) => {
            const call = calls[i];
            acc[call.asset] = {
                ...acc[call.asset],
                [call.spender]: wei(r),
            };
            return acc;
        }, {});
    }
    async transferToken({ address, to, amount, signer, chainId, }) {
        const { request } = await this.sdk.context.publicClient(chainId).simulateContract({
            abi: erc20Abi,
            address,
            account: signer ?? this.sdk.context.walletAddress,
            functionName: 'transfer',
            args: [to, amount],
        });
        return request;
    }
    async getBalanceOf(token, owner, chainId) {
        const tokenContract = this.sdk.context.contractConfigs[chainId]?.common[token];
        if (!tokenContract)
            throw new Error(`${token} not found on current network`);
        const usdcBal = await this.sdk.context.publicClient(chainId).readContract({
            abi: tokenContract.abi,
            address: tokenContract.address,
            functionName: 'balanceOf',
            args: [owner],
        });
        const decimals = await this.sdk.context.publicClient(chainId).readContract({
            abi: tokenContract.abi,
            address: tokenContract.address,
            functionName: 'decimals',
        });
        return wei(usdcBal ?? 0, Number(decimals));
    }
    async swapOneInch(params) {
        const prepared = await this.getOneInchSwapParams(params);
        const { from, to, data, value } = prepared.tx;
        return {
            account: from,
            to: to,
            data: data,
            value: BigInt(value),
        };
    }
    getOneInchQuoteSwapParams(fromTokenAddress, toTokenAddress, amount, decimals) {
        return {
            fromTokenAddress,
            toTokenAddress,
            amount: wei(amount, decimals).toString(0, true),
        };
    }
    async getOneInchSwapParams({ fromTokenAddress, toTokenAddress, amount, fromTokenDecimals, fromAddress, chainId, }) {
        const params = this.getOneInchQuoteSwapParams(fromTokenAddress, toTokenAddress, amount, fromTokenDecimals);
        const res = await axios.get(this.oneInchApiUrl(chainId) + 'swap', {
            params: {
                fromTokenAddress: params.fromTokenAddress,
                toTokenAddress: params.toTokenAddress,
                amount: params.amount,
                fromAddress: fromAddress ?? this.sdk.context.walletAddress,
                slippage: DEFAULT_1INCH_SLIPPAGE,
                protocols: ONE_INCH_PROTOCOLS,
                referrerAddress: KWENTA_ONE_INCH_REFERRAL_ADDRESS,
                disableEstimate: true,
                includeTokenInfo: true,
            },
        });
        return res.data;
    }
}
