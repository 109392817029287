import { publicActionsL2 } from 'viem/op-stack';
import { GAS_BUFFER, TRANSACTION_EVENTS_MAP } from '../constants/transactions';
import { createEmitter, getRevertReason } from '../utils/transactions';
import { SupportedNetworkIds, } from '../types';
export default class TransactionsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    // Copied over from: https://github.com/Synthetixio/js-monorepo
    hash(transactionHash) {
        const emitter = createEmitter();
        setTimeout(() => this.watchTransaction(transactionHash, emitter), 5);
        return emitter;
    }
    async watchTransaction(hash, emitter) {
        emitter.emit(TRANSACTION_EVENTS_MAP.txSent, { transactionHash: hash });
        try {
            const chainId = this.sdk.context.walletClient?.chain.id;
            if (!chainId)
                throw new Error('Wallet not connected');
            const client = this.sdk.context.clients[chainId] ?? this.sdk.context.signerPublicClient;
            const { status, blockNumber } = await client.waitForTransactionReceipt({
                hash,
            });
            if (status === 'success') {
                emitter.emit(TRANSACTION_EVENTS_MAP.txConfirmed, {
                    status,
                    blockNumber,
                    transactionHash: hash,
                });
            }
        }
        catch (err) {
            try {
                const tx = await this.sdk.context.signerPublicClient.getTransaction({ hash });
                const revertReason = await getRevertReason({
                    txHash: hash,
                    networkId: tx.chainId,
                    blockNumber: tx.blockNumber,
                    client: this.sdk.context.signerPublicClient,
                });
                emitter.emit(TRANSACTION_EVENTS_MAP.txFailed, {
                    transactionHash: tx.hash,
                    failureReason: revertReason,
                });
            }
            catch (e) {
                emitter.emit(TRANSACTION_EVENTS_MAP.txFailed, {
                    transactionHash: hash,
                    failureReason: 'Transaction reverted for an unknown reason',
                });
            }
        }
    }
    async switchToTargetChain(targetChain) {
        /**
         * Switches blockchain network and delays the execution to ensure the switch.
         * This solution is inspired by a workaround for a known issue in the wagmi library.
         * Reference: https://github.com/wevm/wagmi/issues/1565
         */
        await this.sdk.context.walletClient.switchChain({ id: targetChain });
        await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    async signTypedData(params) {
        return this.sdk.context.walletClient.signTypedData(params);
    }
    async writeContract(params, targetChain) {
        if (targetChain && targetChain !== this.sdk.context.signerChainId) {
            await this.switchToTargetChain(targetChain);
        }
        const walletClient = this.sdk.context.walletClient; // Directly access the wallet client here.
        // Wrap the wallet client's writeContract method with gas estimation.
        const writeContractWithGasEstimation = this.withGasEstimationForContract(walletClient.writeContract.bind(walletClient));
        // Now, use the wrapped function to write the contract.
        return await writeContractWithGasEstimation(params);
    }
    async sendTransaction(transaction, targetChain) {
        if (targetChain && targetChain !== this.sdk.context.signerChainId) {
            await this.switchToTargetChain(targetChain);
        }
        const walletClient = this.sdk.context.walletClient; // Directly access the wallet client here.
        // Wrap the wallet client's sendTransaction method with gas estimation.
        const sendTransactionWithGasEstimation = this.withGasEstimationForTransaction(walletClient.sendTransaction.bind(walletClient));
        // Now, use the wrapped function to send the transaction.
        return await sendTransactionWithGasEstimation(transaction);
    }
    async checkBalanceForGas(estimatedFee) {
        const userBalance = await this.sdk.context.signerPublicClient.getBalance({
            address: this.sdk.context.walletAddress,
        });
        if (BigInt(userBalance) < (estimatedFee * BigInt(120)) / BigInt(100)) {
            throw new Error('Insufficient funds for gas');
        }
    }
    withGasEstimationForTransaction(originalFunction) {
        return async (params) => {
            const estimateParams = {
                ...params,
                type: undefined,
                gasPrice: undefined,
                account: this.sdk.context.walletAddress,
            };
            const walletChainId = this.sdk.context.walletClient.chain.id;
            const client = this.sdk.context.clients[walletChainId];
            if (!client) {
                // eslint-disable-next-line no-console
                console.warn('Wallet and client chain IDs do not match.');
                // @ts-expect-error Fix types here
                return originalFunction(params);
            }
            if (client.chain.id === SupportedNetworkIds.ARB_MAINNET ||
                client.chain.id === SupportedNetworkIds.ARB_SEPOLIA) {
                // @ts-expect-error Fix types here
                const gasEstimate = await client.estimateGas(estimateParams);
                const gas = (gasEstimate * BigInt(GAS_BUFFER)) / BigInt(100);
                // @ts-expect-error Fix types here
                return originalFunction({ ...params, gas });
            }
            else {
                // @ts-expect-error Fix types here
                const estimatedGas = await client.extend(publicActionsL2()).estimateTotalGas(estimateParams);
                const gas = (estimatedGas * BigInt(GAS_BUFFER)) / BigInt(100);
                // @ts-expect-error Fix multicall type
                const estimatedFee = await client.extend(publicActionsL2()).estimateTotalFee(estimateParams);
                await this.checkBalanceForGas(estimatedFee);
                // @ts-expect-error Fix types here
                return originalFunction({ ...params, gas });
            }
        };
    }
    withGasEstimationForContract(originalFunction) {
        return async (params) => {
            const estimateParams = {
                ...params,
                type: undefined,
                gasPrice: undefined,
                account: this.sdk.context.walletAddress,
            };
            const walletChainId = this.sdk.context.walletClient.chain.id;
            const clientChainId = this.sdk.context.signerPublicClient.chain.id;
            if (walletChainId !== clientChainId) {
                // eslint-disable-next-line no-console
                console.warn('Wallet and client chain IDs do not match.');
                return originalFunction(params);
            }
            if (this.sdk.context.signerPublicClient.chain.id === SupportedNetworkIds.ARB_MAINNET ||
                this.sdk.context.signerPublicClient.chain.id === SupportedNetworkIds.ARB_SEPOLIA) {
                const estimatedGas = await this.sdk.context.signerPublicClient.estimateContractGas(estimateParams);
                const gas = (estimatedGas * BigInt(120)) / BigInt(100);
                return originalFunction({ ...params, gas });
            }
            const estimatedGas = await this.sdk.context.signerPublicClient
                .extend(publicActionsL2())
                // @ts-expect-error Fix multicall type
                .estimateContractTotalGas(estimateParams);
            const gas = (estimatedGas * BigInt(120)) / BigInt(100);
            const estimatedFee = await this.sdk.context.signerPublicClient
                .extend(publicActionsL2())
                // @ts-expect-error Fix multicall type
                .estimateContractTotalFee(estimateParams);
            await this.checkBalanceForGas(estimatedFee);
            return originalFunction({ ...params, gas });
        };
    }
}
