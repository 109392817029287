import request, { gql } from 'graphql-request';
import { arweaveURL } from '../constants/mirror';
export const queryMirrorPosts = async () => {
    const response = await request(`${arweaveURL}/graphql`, gql `
			query MirrorTransactions {
				transactions(
					tags: [
						{ name: "App-Name", values: ["MirrorXYZ"] }
						{ name: "Contributor", values: ["0xC065E02D562cc390F3569305990BFD13B17eEC6B"] }
					]
					sort: HEIGHT_DESC
					first: 20
				) {
					edges {
						node {
							id
							tags {
								name
								value
							}
						}
					}
				}
			}
		`);
    return (response?.transactions.edges || []).map((tx) => ({
        txId: tx.node.id,
        contentDigest: tx.node.tags.find((tag) => tag.name === 'Original-Content-Digest')?.value,
    }));
};
