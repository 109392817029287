import { HermesClient } from '@pythnetwork/hermes-client';
import { decodeAbiParameters, encodeAbiParameters } from 'viem';
import { notNill } from '../../utils';
export class PythAdapter {
    constructor(endpoint) {
        this.connection = new HermesClient(endpoint);
    }
    getOracleId() {
        return 'PYTH';
    }
    async fetchOffchainData(data, extraPriceIds = []) {
        const [updateType] = decodeAbiParameters([{ name: 'updateType', type: 'uint8' }], data);
        if (updateType === 1) {
            const [updateType, stalenessOrTime, priceIds] = decodeAbiParameters([
                { name: 'updateType', type: 'uint8' },
                { name: 'stalenessTolerance', type: 'uint64' },
                { name: 'priceIds', type: 'bytes32[]' },
            ], data);
            const allIds = [...new Set([...priceIds, ...extraPriceIds])].filter(notNill);
            const stalenessTolerance = stalenessOrTime;
            const updateDataResponse = await this.connection.getLatestPriceUpdates(allIds);
            const updateData = updateDataResponse.binary.data.map((x) => `0x${x}`);
            return {
                data: encodePriceUpdateData(updateData, allIds, BigInt(stalenessTolerance), updateType),
                ids: allIds,
            };
        }
        else if (updateType === 2) {
            const [updateType, requestedTime, priceId] = decodeAbiParameters([
                { name: 'updateType', type: 'uint8' },
                { name: 'requestedTime', type: 'uint64' },
                { name: 'priceIds', type: 'bytes32' },
            ], data);
            const allIds = [...new Set([priceId, ...extraPriceIds])].filter(notNill);
            const updateDataResponse = await this.connection.getLatestPriceUpdates(allIds);
            const updateData = updateDataResponse.binary.data.map((x) => `0x${x}`);
            return {
                data: encodePriceUpdateData(updateData, [priceId], requestedTime, updateType),
                ids: allIds,
            };
        }
        else {
            throw new Error(`update type ${updateType} not supported`);
        }
    }
}
export const encodePriceUpdateData = (updateData, ids, timestampOrTolerance, updateType) => {
    if (updateType === 2) {
        return encodeAbiParameters([
            { type: 'uint8', name: 'updateType' },
            { type: 'uint64', name: 'timestamp' },
            { type: 'bytes32[]', name: 'priceIds' },
            { type: 'bytes[]', name: 'updateData' },
        ], [updateType, timestampOrTolerance, ids, updateData]);
    }
    else {
        return encodeAbiParameters([
            { type: 'uint8', name: 'updateType' },
            { type: 'uint64', name: 'stalenessTolerance' },
            { type: 'bytes32[]', name: 'priceIds' },
            { type: 'bytes[]', name: 'updateData' },
        ], [updateType, timestampOrTolerance, ids, updateData]);
    }
};
