import keyBy from 'lodash/keyBy';
import { FuturesMarketKey } from '../types/futures';
export const ADDITIONAL_MARKETS = new Set([
    FuturesMarketKey.sAPEPERP,
    FuturesMarketKey.sDYDXPERP,
    FuturesMarketKey.sXAUPERP,
    FuturesMarketKey.sXAGPERP,
]);
export const CRYPTO_CURRENCY = [
    'KNC',
    'COMP',
    'REN',
    'LEND',
    'SNX',
    'BTC',
    'ETH',
    'XRP',
    'BCH',
    'LTC',
    'EOS',
    'BNB',
    'XTZ',
    'XMR',
    'ADA',
    'LINK',
    'TRX',
    'DASH',
    'ETC',
];
export const CRYPTO_CURRENCY_MAP = keyBy(CRYPTO_CURRENCY);
// For coingecko API
export const ETH_COINGECKO_ADDRESS = '0x4200000000000000000000000000000000000006';
export const KWENTA_ADDRESS = '0x920cf626a271321c151d027030d5d08af699456b';
export const ETH_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
