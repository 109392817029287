import { NetworkId } from '@kwenta/sdk/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useAccountAbstractionConnect from 'services/biconomy/useAccountAbstractionConnect'
import { createContainer } from 'unstated-next'
import { useAccount, useAccountEffect, useWalletClient, usePublicClient } from 'wagmi'

import { DEFAULT_CHAIN, SUPPORTED_CHAIN_IDS } from 'constants/network'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { selectShowTestnets } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetWalletAddress, setWalletClient } from 'state/wallet/actions'
import { disconnect, setWalletNetworkId } from 'state/wallet/reducer'
import {
	selectIsWatcherMode,
	selectSignerWallet,
	selectWatcherWallet,
} from 'state/wallet/selectors'

import { generateExplorerFunctions, getBaseUrl } from './blockExplorer'
import { wagmiConfig, wagmiConfigWithTestnet } from './config'

export let blockExplorer = generateExplorerFunctions(getBaseUrl(DEFAULT_CHAIN.id))

const useConnector = () => {
	const dispatch = useAppDispatch()
	const {
		isConnected: isAccountWalletConnected,
		chain: networkChain,
		address: connectedAddress,
	} = useAccount()

	const [providerReady, setProviderReady] = useState(false)

	const isWatcherMode = useAppSelector(selectIsWatcherMode)
	const watcherWallet = useAppSelector(selectWatcherWallet)
	const stateWallet = useAppSelector(selectSignerWallet)
	const chainWithTestnet = useAppSelector(selectShowTestnets)
	const perpsProvider = useAppSelector(selectPerpsProvider)

	const isWalletConnected = isAccountWalletConnected || (isWatcherMode && !!watcherWallet)
	const activeChain = isWatcherMode ? DEFAULT_CHAIN : networkChain

	useEffect(() => {
		if (connectedAddress !== stateWallet && !watcherWallet) {
			dispatch(resetWalletAddress({ address: connectedAddress, selectedType: 'signer' }))
		}
	}, [dispatch, connectedAddress, stateWallet, watcherWallet])

	useAccountEffect({
		onDisconnect: () => dispatch(disconnect()),
	})

	const network = useMemo(() => {
		// Allow mainnet for exchange page
		const supportedNetworks = [...SUPPORTED_CHAIN_IDS, 1]
		return supportedNetworks.includes(activeChain?.id ?? DEFAULT_CHAIN.id)
			? activeChain ?? DEFAULT_CHAIN
			: DEFAULT_CHAIN
	}, [activeChain])

	const config = useMemo(() => {
		return chainWithTestnet ? wagmiConfigWithTestnet : wagmiConfig
	}, [chainWithTestnet])

	const publicClient = usePublicClient({ config })
	const { data: walletClient } = useWalletClient({ config })

	const handleNetworkChange = useCallback((networkId: NetworkId) => {
		blockExplorer = generateExplorerFunctions(getBaseUrl(networkId))
	}, [])

	useAccountAbstractionConnect({ walletClient })

	useEffect(() => {
		dispatch(setWalletNetworkId(activeChain?.id ?? network.id))
	}, [activeChain?.id, dispatch, network.id])

	useEffect(() => {
		if (!!publicClient?.chain.id) {
			handleNetworkChange(publicClient.chain.id as NetworkId)
			setProviderReady(true)
		}
	}, [publicClient?.chain.id, handleNetworkChange, perpsProvider])

	useEffect(() => {
		if (!isWatcherMode && walletClient) {
			dispatch(setWalletClient(walletClient))
		}
	}, [dispatch, isWatcherMode, walletClient])

	return {
		activeChain,
		isWalletConnected,
		client: publicClient,
		walletClient,
		network,
		providerReady,
	}
}

const Connector = createContainer(useConnector)

export default Connector
