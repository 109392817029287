import { SL_TP_MAX_SIZE, SL_TP_SIZE_PERENNIAL, ZERO_WEI } from '@kwenta/sdk/constants'
import {
	DelayedOrder,
	FuturesMarket,
	FuturesMarketAsset,
	ConditionalOrderV2,
	PerpsV2Position,
	FuturesPositionHistory,
	FuturesTrade,
	FuturesVolumes,
	PositionSide,
	PricesMap,
	PerpsV3AsyncOrder,
	PerpsV3SettlementStrategy,
	PerpsMarketV2,
	PerpsMarketV3,
	PerpsV3Position,
	PerpsV3PositionHistory,
	PotentialTradeStatus,
	SwapDepositToken,
	OrderTypeEnum,
	FuturesMarketKey,
	PerpsV3Liquidation,
	PerennialFuturesMarket,
	PerpsProvider,
	FuturesMarginType,
	CommonPosHistory,
	RealizedPnl,
	UnrealizedPnl,
	IsolatedMarginPositionHistory,
} from '@kwenta/sdk/types'
import {
	AssetDisplayByAsset,
	MarketKeyByAsset,
	formatNumber,
	getMarketName,
} from '@kwenta/sdk/utils'
import Wei, { wei } from '@kwenta/wei'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'
import { TFunction } from 'i18next'
import { ConditionOrderTableItem, FuturesPositionTablePosition } from 'types/futures'

import { TableFilter } from 'sections/futures/UserInfo/TableFilters/TableFilters'
import { TransactionOrder } from 'state/app/types'
import { DelayedOrderWithDetails, MarkPrices, TradePanelInputs } from 'state/futures/common/types'
import { FundingRate } from 'state/futures/common/types'
import {
	IsolatedMarginBalanceInfo,
	IsolatedMarginTradePreview,
} from 'state/futures/isolatedMargin/types'
import { DashboardHistoryTableFilter } from 'state/futures/types'

import { SnxPerpsV3TradePreview, MarginInfo } from '../state/futures/snxPerpsV3/types'

export const getSynthDescription = (synth: FuturesMarketAsset, t: TFunction) => {
	const assetDisplayName = AssetDisplayByAsset[synth]
	return t('common.currency.futures-market-short-name', {
		currencyName: assetDisplayName,
	})
}

export const isDecimalFour = (marketKeyOrAsset: string) =>
	['sEUR', 'EUR', 'sDOGE', 'DOGE'].includes(marketKeyOrAsset)

export const perennialOrderDirection = ({ size, side }: { size: Wei; side?: PositionSide }) => {
	if (!side) return PositionSide.LONG
	// Perennial order sides indicate the open position side rather than the order trade side
	if (size.lt(0) && side === PositionSide.SHORT) return PositionSide.LONG
	if (size.lt(0) && side === PositionSide.LONG) return PositionSide.SHORT
	return side
}

export const orderPriceInvalidLabel = (
	orderPrice: string,
	leverageSide: PositionSide,
	currentPrice: Wei,
	orderType: OrderTypeEnum
): string | undefined => {
	if (!orderPrice || Number(orderPrice) <= 0) return
	const isLong = leverageSide === 'long'

	const isLimit = orderType === OrderTypeEnum.LIMIT || orderType === OrderTypeEnum.TAKE_PROFIT

	if (((isLong && isLimit) || (!isLong && !isLimit)) && wei(orderPrice).gt(currentPrice)) {
		return 'max ' + formatNumber(currentPrice)
	}
	if (((!isLong && isLimit) || (isLong && !isLimit)) && wei(orderPrice).lt(currentPrice))
		return 'min ' + formatNumber(currentPrice)
	return
}

export const updatePositionUpnl = (
	positionDetails: PerpsV2Position<string>,
	prices: MarkPrices,
	positionHistory: IsolatedMarginPositionHistory[]
): UnrealizedPnl => {
	const deserializedPositionDetails = unserializeV2Position(positionDetails)
	const offChainPrice = prices[MarketKeyByAsset[deserializedPositionDetails.asset]]
	const { position } = deserializedPositionDetails
	const thisPositionHistory = positionHistory.find(
		({ isOpen, asset }) => isOpen && asset === positionDetails.asset
	)
	const entry = thisPositionHistory?.avgEntryPrice ?? position?.avgEntryPrice

	if (!position || !offChainPrice || !entry)
		return {
			pnl: wei(0),
			pnlPct: wei(0),
		}

	const initialMargin = thisPositionHistory?.initialMargin ?? position.initialMargin

	let pnl

	if (positionDetails.provider === PerpsProvider.PERENNIAL_V2_ARB) {
		const pnlDiff = position.size.mul(
			position.lastPrice.sub(offChainPrice).mul(position.side === PositionSide.LONG ? -1 : 1)
		)
		pnl = pnlDiff.add(position.uPnl.pnl)
	} else {
		pnl = position.size.mul(
			entry.sub(offChainPrice).mul(position.side === PositionSide.LONG ? -1 : 1)
		)
	}

	const pnlPct = pnl.div(initialMargin)

	return {
		pnl: pnl,
		pnlPct: pnlPct,
	}
}

export const serializeMarket = (market: FuturesMarket): FuturesMarket<string> => {
	return {
		...market,
		openInterest: {
			...market.openInterest,
			shortUSD: market.openInterest.shortUSD.toString(),
			longUSD: market.openInterest.longUSD.toString(),
			long: market.openInterest.long.toString(),
			short: market.openInterest.short.toString(),
		},
		marketDebt: market.marketDebt.toString(),
		marketSkew: market.marketSkew.toString(),
		appMaxLeverage: market.appMaxLeverage.toString(),
		minInitialMargin: market.minInitialMargin.toString(),
		settlementFee: market.settlementFee.toString(),
		marketLimitUsd: {
			long: market.marketLimitUsd.long.toString(),
			short: market.marketLimitUsd.short.toString(),
		},
		marketLimitNative: {
			long: market.marketLimitNative.long.toString(),
			short: market.marketLimitNative.short.toString(),
		},
		settings: {
			skewScale: market.settings?.skewScale?.toString(),
		},
	}
}

export const unserializeMarket = (m: FuturesMarket<string>): FuturesMarket => {
	return {
		...m,
		openInterest: {
			...m.openInterest,
			shortUSD: wei(m.openInterest.shortUSD),
			longUSD: wei(m.openInterest.longUSD),
			short: wei(m.openInterest.short),
			long: wei(m.openInterest.long),
		},
		marketDebt: wei(m.marketDebt),
		marketSkew: wei(m.marketSkew),
		appMaxLeverage: wei(m.appMaxLeverage),
		minInitialMargin: wei(m.minInitialMargin),
		settlementFee: wei(m.settlementFee),
		marketLimitUsd: {
			long: wei(m.marketLimitUsd.long),
			short: wei(m.marketLimitUsd.short),
		},
		marketLimitNative: {
			long: wei(m.marketLimitNative.long),
			short: wei(m.marketLimitNative.short),
		},
		settings: {
			skewScale: m.settings?.skewScale ? wei(m.settings.skewScale) : undefined,
		},
	}
}

export const serializeV2Market = (m: PerpsMarketV2): PerpsMarketV2<string> => {
	return {
		...serializeMarket(m),
		marginType: FuturesMarginType.ISOLATED_MARGIN,
		currentFundingRate: m.currentFundingRate.toString(),
		provider: PerpsProvider.SNX_V2_OP,
		marketAddress: m.marketAddress,
		contractMaxLeverage: m.contractMaxLeverage.toString(),
		currentFundingVelocity: m.currentFundingVelocity.toString(),
		feeRates: {
			makerFee: m.feeRates.makerFee.toString(),
			takerFee: m.feeRates.takerFee.toString(),
		},
		settings: {
			...m.settings,
			skewScale: m.settings.skewScale.toString(),
		},
	}
}

export const unserializeV2Market = (m: PerpsMarketV2<string>): PerpsMarketV2 => {
	return {
		...unserializeMarket(m),
		provider: PerpsProvider.SNX_V2_OP,
		marginType: FuturesMarginType.ISOLATED_MARGIN,
		marketAddress: m.marketAddress,
		contractMaxLeverage: wei(m.contractMaxLeverage),
		currentFundingVelocity: wei(m.currentFundingVelocity ?? 0),
		currentFundingRate: wei(m.currentFundingRate),
		feeRates: {
			makerFee: wei(m.feeRates.makerFee),
			takerFee: wei(m.feeRates.takerFee),
		},
		settings: {
			...m.settings,
			skewScale: wei(m.settings.skewScale),
		},
	}
}

export const serializePerennialMarket = (
	m: PerennialFuturesMarket
): PerennialFuturesMarket<string> => {
	return {
		...serializeMarket(m),
		provider: PerpsProvider.PERENNIAL_V2_ARB,
		marginType: FuturesMarginType.ISOLATED_MARGIN,
		marketAddress: m.marketAddress,
		perennialAsset: m.perennialAsset,
		contractMaxLeverage: m.contractMaxLeverage.toString(),
		currentFundingVelocity: m.currentFundingVelocity.toString(),
		marginParameter: m.marginParameter.toString(),
		currentFundingRate: {
			long: m.currentFundingRate.long.toString(),
			short: m.currentFundingRate.short.toString(),
		},
		feeRates: {
			makerFee: m.feeRates.makerFee.toString(),
			takerFee: m.feeRates.takerFee.toString(),
		},
		marketHours: {
			nextOpen: m.marketHours.nextOpen,
			nextClose: m.marketHours.nextClose,
		},
	}
}

export const unserializePerennialMarket = (
	m: PerennialFuturesMarket<string>
): PerennialFuturesMarket => {
	return {
		...unserializeMarket(m),
		provider: PerpsProvider.PERENNIAL_V2_ARB,
		marginType: FuturesMarginType.ISOLATED_MARGIN,
		marketAddress: m.marketAddress,
		perennialAsset: m.perennialAsset,
		contractMaxLeverage: wei(m.contractMaxLeverage),
		currentFundingVelocity: wei(m.currentFundingVelocity ?? 0),
		marginParameter: wei(m.marginParameter ?? 0),
		currentFundingRate: {
			long: wei(m.currentFundingRate.long),
			short: wei(m.currentFundingRate.short),
		},
		feeRates: {
			makerFee: wei(m.feeRates.makerFee),
			takerFee: wei(m.feeRates.takerFee),
		},
		marketHours: {
			nextOpen: m.marketHours.nextOpen,
			nextClose: m.marketHours.nextClose,
		},
	}
}

export const serializeV3Market = (m: PerpsMarketV3): PerpsMarketV3<string> => {
	return {
		...serializeMarket(m),
		marketId: m.marketId,
		marginType: FuturesMarginType.CROSS_MARGIN,
		provider: PerpsProvider.SNX_V3_BASE,
		settlementStrategies: m.settlementStrategies.map(serializeSettlementStrategy),
		currentFundingRate: m.currentFundingRate.toString(),
		feeRates: {
			makerFee: m.feeRates.makerFee.toString(),
			takerFee: m.feeRates.takerFee.toString(),
		},
		settings: {
			skewScale: m.settings.skewScale.toString(),
			maxLiquidationLimitAccumulationMultiplier:
				m.settings.maxLiquidationLimitAccumulationMultiplier.toString(),
			maxSecondsInLiquidationWindow: m.settings.maxSecondsInLiquidationWindow.toString(),
			minimumInitialMarginRatio: m.settings.minimumInitialMarginRatio.toString(),
			flagRewardRatio: m.settings.flagRewardRatio.toString(),
			maintenanceMarginScalar: m.settings.maintenanceMarginScalar.toString(),
			minimumPositionMargin: m.settings.minimumPositionMargin.toString(),
			initialMarginRatio: m.settings.initialMarginRatio.toString(),
			interestRate: m.settings.interestRate.toString(),
		},
	}
}

export const unserializeV3Market = (m: PerpsMarketV3<string>): PerpsMarketV3 => {
	return {
		...unserializeMarket(m),
		marketId: m.marketId,
		provider: PerpsProvider.SNX_V3_BASE,
		marginType: FuturesMarginType.CROSS_MARGIN,
		settlementStrategies: m.settlementStrategies.map(unserializeSettlementStrategy),
		currentFundingRate: wei(m.currentFundingRate),
		feeRates: {
			makerFee: wei(m.feeRates.makerFee),
			takerFee: wei(m.feeRates.takerFee),
		},
		settings: {
			skewScale: wei(m.settings.skewScale),
			maxLiquidationLimitAccumulationMultiplier: wei(
				m.settings.maxLiquidationLimitAccumulationMultiplier
			),
			maxSecondsInLiquidationWindow: wei(m.settings.maxSecondsInLiquidationWindow),
			minimumInitialMarginRatio: wei(m.settings.minimumInitialMarginRatio),
			flagRewardRatio: wei(m.settings.flagRewardRatio),
			maintenanceMarginScalar: wei(m.settings.maintenanceMarginScalar),
			minimumPositionMargin: wei(m.settings.minimumPositionMargin),
			initialMarginRatio: wei(m.settings.initialMarginRatio),
			interestRate: wei(m.settings.interestRate ?? 0),
		},
	}
}

export const serializeV2Markets = (markets: PerpsMarketV2[]): PerpsMarketV2<string>[] => {
	return markets.map((m) => serializeV2Market(m))
}

export const unserializeV2Markets = (markets: PerpsMarketV2<string>[]): PerpsMarketV2[] => {
	return markets.map((m) => unserializeV2Market(m))
}

export const serializePerennialMarkets = (
	markets: PerennialFuturesMarket[]
): PerennialFuturesMarket<string>[] => {
	return markets.map((m) => serializePerennialMarket(m))
}

export const unserializePerennialMarkets = (
	markets: PerennialFuturesMarket<string>[]
): PerennialFuturesMarket[] => {
	return markets.map((m) => unserializePerennialMarket(m))
}

export const serializeV3Markets = (markets: PerpsMarketV3[]): PerpsMarketV3<string>[] => {
	return markets.map((m) => serializeV3Market(m))
}

export const unserializeV3Markets = (markets: PerpsMarketV3<string>[]): PerpsMarketV3[] => {
	return markets.map((m) => unserializeV3Market(m))
}

export const serializeSettlementStrategy = (
	strat: PerpsV3SettlementStrategy
): PerpsV3SettlementStrategy<string> => {
	return {
		...strat,
		settlementDelay: strat.settlementDelay.toString(),
		settlementWindowDuration: strat.settlementWindowDuration.toString(),
		settlementReward: strat.settlementReward.toString(),
	}
}

export const unserializeSettlementStrategy = (
	strat: PerpsV3SettlementStrategy<string>
): PerpsV3SettlementStrategy => {
	return {
		...strat,
		settlementDelay: wei(strat.settlementDelay),
		settlementWindowDuration: wei(strat.settlementWindowDuration),
		settlementReward: wei(strat.settlementReward),
	}
}

export const serializeIsolatedMarginBalanceInfo = (
	overview: IsolatedMarginBalanceInfo
): IsolatedMarginBalanceInfo<string> => {
	return {
		freeMargin: overview.freeMargin.toString(),
		keeperEthBal: overview.keeperEthBal.toString(),
		walletEthBal: overview.walletEthBal.toString(),
		allowance: overview.allowance.toString(),
		factoryApproved: overview.factoryApproved,
		idleMarginByMarket: serializeIdleMargin(overview.idleMarginByMarket),
		totalMarginByMarket: serializeIdleMargin(overview.totalMarginByMarket ?? {}),
		balances: {
			[SwapDepositToken.SUSD]: overview.balances[SwapDepositToken.SUSD].toString(),
			[SwapDepositToken.USDC]: overview.balances[SwapDepositToken.USDC].toString(),
			// [SwapDepositToken.USDT]: overview.balances[SwapDepositToken.USDT].toString(),
			[SwapDepositToken.DAI]: overview.balances[SwapDepositToken.DAI].toString(),
			// [SwapDepositToken.LUSD]: overview.balances[SwapDepositToken.LUSD].toString(),
		},
		allowances: {
			[SwapDepositToken.SUSD]: overview.allowances[SwapDepositToken.SUSD].toString(),
			[SwapDepositToken.USDC]: overview.allowances[SwapDepositToken.USDC].toString(),
			// [SwapDepositToken.USDT]: overview.allowances[SwapDepositToken.USDT].toString(),
			[SwapDepositToken.DAI]: overview.allowances[SwapDepositToken.DAI].toString(),
			// [SwapDepositToken.LUSD]: overview.allowances[SwapDepositToken.LUSD].toString(),
		},
	}
}

export const unserializeIsolatedMarginBalanceInfo = (
	balanceInfo: IsolatedMarginBalanceInfo<string>
): IsolatedMarginBalanceInfo<Wei> => {
	return {
		freeMargin: wei(balanceInfo.freeMargin),
		keeperEthBal: wei(balanceInfo.keeperEthBal),
		walletEthBal: wei(balanceInfo.walletEthBal),
		allowance: wei(balanceInfo.allowance),
		factoryApproved: balanceInfo.factoryApproved,
		idleMarginByMarket: unserializeIdleMargin(balanceInfo.idleMarginByMarket),
		totalMarginByMarket: unserializeIdleMargin(balanceInfo.totalMarginByMarket ?? {}),
		balances: {
			[SwapDepositToken.SUSD]: wei(balanceInfo.balances[SwapDepositToken.SUSD]),
			[SwapDepositToken.USDC]: wei(balanceInfo.balances[SwapDepositToken.USDC]),
			// [SwapDepositToken.USDT]: wei(balanceInfo.balances[SwapDepositToken.USDT]),
			[SwapDepositToken.DAI]: wei(balanceInfo.balances[SwapDepositToken.DAI]),
			// [SwapDepositToken.LUSD]: wei(balanceInfo.balances[SwapDepositToken.LUSD]),
		},
		allowances: {
			[SwapDepositToken.SUSD]: wei(balanceInfo.allowances[SwapDepositToken.SUSD]),
			[SwapDepositToken.USDC]: wei(balanceInfo.allowances[SwapDepositToken.USDC]),
			// [SwapDepositToken.USDT]: wei(balanceInfo.allowances[SwapDepositToken.USDT]),
			[SwapDepositToken.DAI]: wei(balanceInfo.allowances[SwapDepositToken.DAI]),
			// [SwapDepositToken.LUSD]: wei(balanceInfo.allowances[SwapDepositToken.LUSD]),
		},
	}
}

export const serializeFuturesVolumes = (volumes: FuturesVolumes) => {
	return Object.keys(volumes).reduce<FuturesVolumes<string>>((acc, k) => {
		acc[k] = {
			trades: volumes[k].trades.toString(),
			volume: volumes[k].volume.toString(),
		}
		return acc
	}, {})
}

export const unserializeFuturesVolumes = (volumes: FuturesVolumes<string>) => {
	return Object.keys(volumes).reduce<FuturesVolumes>((acc, k) => {
		acc[k] = {
			trades: wei(volumes[k].trades),
			volume: wei(volumes[k].volume),
		}
		return acc
	}, {})
}

export const unserializeTradeInputs = (tradeInputs: TradePanelInputs<string>): TradePanelInputs => {
	return {
		orderPrice: {
			price: wei(tradeInputs.orderPrice.price || 0),
			invalidLabel: tradeInputs.orderPrice.invalidLabel,
		},
		marginDelta: wei(tradeInputs.marginDelta || 0),
		orderType: tradeInputs.orderType,
		nativeSize: wei(tradeInputs.nativeSize || 0),
		susdSize: wei(tradeInputs.susdSize || 0),
		stopLossPrice: tradeInputs.stopLossPrice ? wei(tradeInputs.stopLossPrice || 0) : undefined,
		takeProfitPrice: tradeInputs.takeProfitPrice
			? wei(tradeInputs.takeProfitPrice || 0)
			: undefined,
	}
}

export const serializeConditionalOrdersV2 = (
	orders: ConditionalOrderV2[]
): ConditionalOrderV2<string>[] => {
	return orders.map((o) => ({
		...o,
		size: o.size.toString(),
		desiredFillPrice: o.desiredFillPrice.toString(),
		targetPrice: o.targetPrice?.toString() ?? null,
		marginDelta: o.marginDelta.toString(),
	}))
}

export const unserializeConditionalOrdersV2 = (
	orders: ConditionalOrderV2<string>[]
): ConditionalOrderV2[] => {
	return orders.map((o) => ({
		...o,
		size: wei(o.size),
		targetPrice: wei(o.targetPrice),
		desiredFillPrice: wei(o.desiredFillPrice || 0),
		marginDelta: wei(o.marginDelta),
	}))
}

export const serializeDelayedOrder = (
	order: DelayedOrderWithDetails
): DelayedOrderWithDetails<string> => ({
	...order,
	size: order.size.toString(),
	commitDeposit: order.commitDeposit.toString(),
	keeperDeposit: order.keeperDeposit.toString(),
	desiredFillPrice: order.desiredFillPrice.toString(),
	targetRoundId: order.targetRoundId?.toString() ?? '',
})

export const serializeDelayedOrders = (
	orders: DelayedOrderWithDetails[]
): DelayedOrderWithDetails<string>[] => orders.map((o) => serializeDelayedOrder(o))

export const unserializeDelayedOrder = (
	order: DelayedOrderWithDetails<string>
): DelayedOrderWithDetails => ({
	...order,
	size: wei(order.size),
	commitDeposit: wei(order.commitDeposit),
	keeperDeposit: wei(order.keeperDeposit),
	desiredFillPrice: wei(order.desiredFillPrice),
	targetRoundId: wei(order.targetRoundId),
})

export const unserializeDelayedOrders = (
	orders: DelayedOrderWithDetails<string>[]
): DelayedOrderWithDetails[] => orders.map((o) => unserializeDelayedOrder(o))

export const serializeV3AsyncOrder = (order: PerpsV3AsyncOrder): PerpsV3AsyncOrder<string> => ({
	...order,
	sizeDelta: order.sizeDelta.toString(),
	acceptablePrice: order.sizeDelta.toString(),
})

export const unserializeV3AsyncOrder = (order: PerpsV3AsyncOrder<string>): PerpsV3AsyncOrder => ({
	...order,
	sizeDelta: wei(order.sizeDelta),
	acceptablePrice: wei(order.sizeDelta),
})

export const serializePrices = (prices: PricesMap) => {
	return Object.entries(prices).reduce<PricesMap<string>>((acc, [key, price]) => {
		acc[key as FuturesMarketAsset] = price.toString()
		return acc
	}, {})
}

export const serializeV3Positions = (positions: PerpsV3Position[]): PerpsV3Position<string>[] => {
	return positions.map((p) => ({
		...p,
		accruedFunding: p.accruedFunding.toString(),
		owedInterest: p.owedInterest ? p.owedInterest.toString() : '0',
		size: p.size.toString(),
		pnl: p.pnl.toString(),
		pnlPct: p.pnlPct.toString(),
	}))
}

export const unserializeV3Positions = (positions: PerpsV3Position<string>[]): PerpsV3Position[] => {
	return positions.map((p) => ({
		...p,
		accruedFunding: wei(p.accruedFunding),
		owedInterest: wei(p.owedInterest ?? '0'),
		size: wei(p.size),
		pnl: wei(p.pnl),
		pnlPct: wei(p.pnlPct),
	}))
}

export const serializeV3Liquidations = (
	liquidations: PerpsV3Liquidation[]
): PerpsV3Liquidation<string>[] => {
	return liquidations.map((t) => ({
		...t,
		amount: t.amount.toString(),
		notionalAmount: t.notionalAmount.toString(),
		estimatedPrice: t.estimatedPrice.toString(),
	}))
}

export const unserializeV3Liquidations = (
	liquidations: PerpsV3Liquidation<string>[]
): PerpsV3Liquidation[] => {
	return liquidations.map((t) => ({
		...t,
		amount: wei(t.amount),
		notionalAmount: wei(t.notionalAmount),
		estimatedPrice: wei(t.estimatedPrice),
	}))
}

export const serializeV2Position = (p: PerpsV2Position): PerpsV2Position<string> => {
	return {
		...p,
		remainingMargin: p.remainingMargin.toString(),
		accessibleMargin: p.accessibleMargin.toString(),
		position: p.position
			? {
					...p.position,
					notionalValue: p.position.notionalValue.toString(),
					accruedFunding: p.position.accruedFunding.toString(),
					owedInterest: p.position.owedInterest?.toString(),
					initialMargin: p.position.initialMargin.toString(),
					lastPrice: p.position.lastPrice.toString(),
					size: p.position.size.toString(),
					liquidationPrice: p.position.liquidationPrice.toString(),
					initialLeverage: p.position.initialLeverage.toString(),
					leverage: p.position.leverage.toString(),
					rPnl: p.position.rPnl
						? {
								pnl: p.position.rPnl.pnl.toString(),
								netPnlPct: p.position.rPnl.netPnlPct.toString(),
								netPnl: p.position.rPnl.netPnl.toString(),
						  }
						: undefined,
					uPnl: {
						pnl: p.position.uPnl.pnl.toString(),
						pnlPct: p.position.uPnl.pnlPct.toString(),
					},
					marginRatio: p.position.marginRatio.toString(),
					avgEntryPrice: p.position.avgEntryPrice?.toString(),
					maxLeverage: p.position.maxLeverage?.toString(),
					totalFees: p.position.totalFees?.toString(),
			  }
			: null,
	}
}

export const unserializeV2Position = (p: PerpsV2Position<string>): PerpsV2Position => {
	return {
		...p,
		remainingMargin: wei(p.remainingMargin),
		accessibleMargin: wei(p.accessibleMargin),
		position: p.position
			? {
					...p.position,
					notionalValue: wei(p.position.notionalValue),
					accruedFunding: wei(p.position.accruedFunding),
					owedInterest: wei(p.position.owedInterest ?? '0'),
					initialMargin: wei(p.position.initialMargin),
					lastPrice: wei(p.position.lastPrice),
					size: wei(p.position.size),
					liquidationPrice: wei(p.position.liquidationPrice),
					initialLeverage: wei(p.position.initialLeverage),
					leverage: wei(p.position.leverage),
					uPnl: {
						pnl: wei(p.position.uPnl.pnl),
						pnlPct: wei(p.position.uPnl.pnlPct),
					},
					rPnl: p.position.rPnl
						? {
								pnl: wei(p.position.rPnl.pnl),
								netPnlPct: wei(p.position.rPnl.netPnlPct),
								netPnl: wei(p.position.rPnl.netPnl),
						  }
						: undefined,
					marginRatio: wei(p.position.marginRatio),
					avgEntryPrice: p.position.avgEntryPrice ? wei(p.position.avgEntryPrice) : undefined,
					maxLeverage: p.position.maxLeverage ? wei(p.position.maxLeverage) : undefined,
					totalFees: p.position.totalFees ? wei(p.position.totalFees) : undefined,
			  }
			: null,
	}
}

export const serializePositionHistoryItem = (
	p: FuturesPositionHistory
): FuturesPositionHistory<string> => {
	return {
		...p,
		size: p.size.toString(),
		feesPaid: p.feesPaid.toString(),
		netFunding: p.netFunding.toString(),
		entryPrice: p.entryPrice.toString(),
		exitPrice: p.exitPrice ? p.exitPrice.toString() : null,
		pnl: p.pnl.toString(),
		pnlWithFeesPaid: p.pnlWithFeesPaid.toString(),
		totalVolume: p.totalVolume.toString(),
		avgEntryPrice: p.avgEntryPrice.toString(),
	}
}

export const serializePositionHistory = (
	positions: PerpsV3PositionHistory[] | IsolatedMarginPositionHistory[]
): FuturesPositionHistory<string>[] => {
	return positions.map(serializePositionHistoryItem)
}

export const unserializePositionHistoryItem = (
	p: FuturesPositionHistory<string>
): FuturesPositionHistory => {
	return {
		...p,
		size: wei(p.size),
		feesPaid: wei(p.feesPaid),
		netFunding: wei(p.netFunding),
		entryPrice: wei(p.entryPrice),
		exitPrice: p.exitPrice ? wei(p.exitPrice) : null,
		pnl: wei(p.pnl),
		pnlWithFeesPaid: wei(p.pnlWithFeesPaid),
		totalVolume: wei(p.totalVolume),
		avgEntryPrice: wei(p.avgEntryPrice),
	}
}

export const serializePerpsV3PositionHistory = (
	positions: PerpsV3PositionHistory[]
): PerpsV3PositionHistory<string>[] => {
	return positions.map((p) => ({
		...p,
		...serializePositionHistoryItem(p),
		liquidatedAmount: p.liquidatedAmount?.toString(),
		totalReducedNotional: p.totalReducedNotional.toString(),
		interestCharged: p.interestCharged.toString(),
	}))
}

export const unserializePerpsV3PositionHistory = (
	positions: PerpsV3PositionHistory<string>[]
): PerpsV3PositionHistory[] => {
	return positions.map((p) => ({
		...p,
		...unserializePositionHistoryItem(p),
		liquidatedAmount: p.liquidatedAmount ? wei(p.liquidatedAmount) : undefined,
		totalReducedNotional: wei(p.totalReducedNotional),
		interestCharged: wei(p.interestCharged ?? 0),
	}))
}

export const serializeIsolatedPositionHistory = (
	positions: IsolatedMarginPositionHistory[]
): IsolatedMarginPositionHistory<string>[] => {
	return positions.map((p) => ({
		...p,
		...serializePositionHistoryItem(p),
		netTransfers: p.netTransfers.toString(),
		leverage: p.leverage.toString(),
		totalDeposits: p.totalDeposits.toString(),
		initialMargin: p.initialMargin.toString(),
		margin: p.margin.toString(),
		liquidationFee: p.liquidationFee?.toString(),
		keeperFeesPaid: p.keeperFeesPaid?.toString(),
		interestCharged: p.interestCharged.toString(),
	}))
}

export const unserializeIsolatedMarginPositionHistory = (
	positions: IsolatedMarginPositionHistory<string>[]
): IsolatedMarginPositionHistory[] => {
	return positions.map((p) => ({
		...p,
		...unserializePositionHistoryItem(p),
		netTransfers: wei(p.netTransfers),
		leverage: wei(p.leverage),
		totalDeposits: wei(p.totalDeposits),
		initialMargin: wei(p.initialMargin),
		margin: wei(p.margin),
		liquidationFee: wei(p.liquidationFee ?? 0),
		keeperFeesPaid: wei(p.keeperFeesPaid ?? 0),
		interestCharged: wei(p.interestCharged),
	}))
}

export const serializeTrades = (trades: FuturesTrade[]): FuturesTrade<string>[] => {
	return trades.map((t) => ({
		...t,
		margin: t.margin?.toString(),
		sizeDelta: t.sizeDelta.toString(),
		fillPrice: t.fillPrice.toString(),
		pnl: t.pnl.toString(),
		totalFees: t.totalFees.toString(),
		fundingAccrued: t.fundingAccrued.toString(),
		pnlWithFeesPaid: t.pnlWithFeesPaid.toString(),
		interestCharged: t.interestCharged ? t.interestCharged.toString() : undefined,
	}))
}

export const unserializeTrades = (trades: FuturesTrade<string>[]): FuturesTrade<Wei>[] => {
	return trades.map((t) => ({
		...t,
		margin: t.margin ? wei(t.margin) : undefined,
		sizeDelta: wei(t.sizeDelta),
		fillPrice: wei(t.fillPrice),
		pnl: wei(t.pnl),
		totalFees: wei(t.totalFees),
		fundingAccrued: wei(t.fundingAccrued),
		pnlWithFeesPaid: wei(t.pnlWithFeesPaid),
		interestCharged: t.interestCharged ? wei(t.interestCharged) : undefined,
	}))
}

export const unserializeFundingRates = (rates: FundingRate<string>[]): FundingRate[] => {
	return rates.map((r) => ({ ...r, fundingRate: r.fundingRate ? wei(r.fundingRate) : null }))
}

export const formatDelayedOrders = (orders: DelayedOrder[], markets: PerpsMarketV2[]) => {
	return orders
		.filter((o) => o.size.abs().gt(0))
		.reduce((acc, o) => {
			const market = markets.find((m) => m.marketAddress === o.marketAddress)
			if (!market) return acc

			acc.push({
				...o,
				marketKey: market.marketKey,
				asset: market.asset,
				market: getMarketName(market.asset),
				executableAtTimestamp:
					market && o.isOffchain // Manual fix for an incorrect
						? o.submittedAtTimestamp +
						  (o.isOffchain
								? market.settings.offchainDelayedOrderMinAge * 1000
								: market.settings.minDelayTimeDelta * 1000)
						: o.executableAtTimestamp,
			})
			return acc
		}, [] as DelayedOrderWithDetails[])
}

export const serializeCrossMarginTradePreview = (
	preview: SnxPerpsV3TradePreview
): SnxPerpsV3TradePreview<string> => ({
	...preview,
	liqPrice: preview.liqPrice.toString(),
	newSize: preview.newSize.toString(),
	settlementFee: preview.settlementFee.toString(),
	sizeDelta: preview.sizeDelta.toString(),
	fillPrice: preview.fillPrice.toString(),
	fee: preview.fee.toString(),
	notionalValue: preview.notionalValue.toString(),
	priceImpact: preview.priceImpact.toString(),
	requiredMargin: preview.requiredMargin.toString(),
})

export const unserializeCrossMarginTradePreview = (
	preview: SnxPerpsV3TradePreview<string>
): SnxPerpsV3TradePreview => ({
	...preview,
	liqPrice: wei(preview.liqPrice),
	newSize: wei(preview.newSize),
	settlementFee: wei(preview.settlementFee),
	sizeDelta: wei(preview.sizeDelta),
	fillPrice: wei(preview.fillPrice),
	fee: wei(preview.fee),
	notionalValue: wei(preview.notionalValue),
	priceImpact: wei(preview.priceImpact),
	requiredMargin: wei(preview.requiredMargin),
})

export const serializeIdleMargin = (idleMargin: Partial<Record<FuturesMarketAsset, Wei>>) => {
	return Object.keys(idleMargin).reduce<Partial<Record<FuturesMarketAsset, string>>>((acc, key) => {
		acc[key as FuturesMarketAsset] = idleMargin[key as FuturesMarketAsset]!.toString()
		return acc
	}, {} as Partial<Record<FuturesMarketAsset, string>>)
}

export const unserializeIdleMargin = (idleMargin: Partial<Record<FuturesMarketAsset, string>>) => {
	return Object.keys(idleMargin).reduce<Partial<Record<FuturesMarketAsset, Wei>>>((acc, key) => {
		acc[key as FuturesMarketAsset] = wei(idleMargin[key as FuturesMarketAsset])
		return acc
	}, {} as Partial<Record<FuturesMarketAsset, Wei>>)
}

// Disable stop loss when it is within 0% of the liquidation price
const SL_LIQ_DISABLED_PERCENT = 0

// Warn users when their stop loss is within 7.5% of their liquidation price
const SL_LIQ_PERCENT_WARN = 0.075

export const furthestSLPrice = (liqPrice: Wei | undefined, leverageSide: PositionSide) => {
	// liquidation price can be zero when using 1x leverage or less
	if (liqPrice?.eq(0)) return leverageSide === PositionSide.LONG ? wei(0) : wei('10000000000000')
	return leverageSide === PositionSide.LONG
		? liqPrice?.mul(1 + SL_LIQ_DISABLED_PERCENT)
		: liqPrice?.mul(1 - SL_LIQ_DISABLED_PERCENT)
}

export const takeProfitValidity = (
	takeProfitPrice: string,
	side: PositionSide,
	offchainPrice: Wei,
	onChainPrice?: Wei
) => {
	const closestPrice =
		side === 'long'
			? onChainPrice && onChainPrice.gt(offchainPrice)
				? onChainPrice
				: offchainPrice
			: onChainPrice && onChainPrice.lt(offchainPrice)
			? onChainPrice
			: offchainPrice

	const invalid =
		side === 'long'
			? takeProfitPrice !== '' && wei(takeProfitPrice || 0).lt(closestPrice)
			: takeProfitPrice !== '' && wei(takeProfitPrice || 0).gt(closestPrice)

	const minMaxLabel = side === 'long' ? 'Min: ' : 'Max: '

	return {
		invalidLabel: invalid
			? minMaxLabel + formatNumber(closestPrice, { suggestDecimals: true })
			: undefined,
		closestPrice,
	}
}

export const stopLossValidity = (
	stopLossPrice: string,
	liqPrice: Wei | undefined,
	side: PositionSide,
	offchainPrice: Wei,
	onChainPrice?: Wei
) => {
	const furthestPrice = furthestSLPrice(liqPrice, side)

	const chainPrice = onChainPrice && onChainPrice.gt(0) ? onChainPrice : undefined

	const closestPrice =
		side === 'long'
			? chainPrice && chainPrice.lt(offchainPrice)
				? chainPrice
				: offchainPrice
			: chainPrice && chainPrice.gt(offchainPrice)
			? chainPrice
			: offchainPrice

	if (stopLossPrice === '' || stopLossPrice === undefined)
		return {
			invalidLabel: undefined,
			minMaxStopPrice: furthestPrice,
		}

	if (!furthestPrice || !liqPrice)
		return { furthestPrice, closestPrice, invalidLabel: 'No position data' }

	const formattedClosest = formatNumber(closestPrice, { suggestDecimals: true })
	const formattedFurthest = formatNumber(furthestPrice, { suggestDecimals: true })

	let invalidLabel: string | undefined = undefined
	if (side === 'long') {
		if (wei(stopLossPrice || 0).lt(furthestPrice || 0)) {
			invalidLabel = 'Min: ' + formattedFurthest
		}
		if (wei(stopLossPrice || 0).gt(closestPrice)) {
			invalidLabel = 'Max: ' + formattedClosest
		}
	} else {
		if (wei(stopLossPrice || 0).gt(furthestPrice || 0)) {
			invalidLabel = 'Max: ' + formattedFurthest
		}
		if (wei(stopLossPrice || 0).lt(closestPrice)) {
			invalidLabel = 'Min: ' + formattedClosest
		}
	}

	const percent = wei(stopLossPrice || 0)
		.div(liqPrice.gt(0) ? liqPrice : 1)
		.sub(1)
		.abs()

	return {
		invalidLabel,
		furthestPrice,
		closestPrice,
		showWarning: percent.lt(SL_LIQ_PERCENT_WARN),
	}
}

export const previewStatusToI18nMsg = (status: PotentialTradeStatus) => {
	switch (status) {
		case PotentialTradeStatus.INSUFFICIENT_FREE_MARGIN:
			return 'futures.market.trade.preview.min-margin-required'
		case PotentialTradeStatus.MAX_POSITION_SIZE_EXCEEDED:
			return 'futures.market.trade.preview.max-size-exceeded'
		default:
			return 'futures.market.trade.preview.error'
	}
}

export const formatV3AssetKey = (asset: string) => asset.replace('_V3', '')

export type PositionTablePosition = {
	id: string
	rank: number
	timestamp: number
	openTimestamp: number
	asset: string
	status: string
	side: PositionSide
	trades: number
	totalVolume: Wei
	feesPaid: Wei
	rPnl?: RealizedPnl
	uPnl: UnrealizedPnl
	interestCharged: Wei
	totalPnl: {
		pnl: Wei
		netPnl: Wei
		netPnlPct: Wei
	}
	funding: Wei
	currencyIconKey: string
	marketShortName: string
}

export function formatPositionForTable(
	positions: FuturesPositionTablePosition[],
	history: CommonPosHistory[],
	provider: PerpsProvider
) {
	let activePositons: PositionTablePosition[] = []
	if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
		activePositons = positions.map((activePos, i) => {
			const {
				openTimestamp = 0,
				side,
				size,
				uPnl,
				rPnl,
				initialMargin,
				accruedFunding,
				avgEntryPrice,
				feesPaid,
			} = activePos.details!

			const totalPnl = uPnl.pnl.add(rPnl?.pnl ?? 0)
			const totalNetPnl = uPnl.pnl.add(rPnl?.netPnl ?? 0)
			const totalPnlPct = initialMargin.gt(0) ? totalNetPnl.div(initialMargin) : wei(0)

			return {
				id: activePos.market.asset,
				rank: i + 1,
				timestamp: openTimestamp,
				openTimestamp: activePos.details?.openTimestamp ?? openTimestamp,
				asset: activePos.market.asset,
				side,
				status: 'Open',
				feesPaid: feesPaid ?? wei(0),
				trades: activePos.details?.trades ?? 0,
				totalVolume: size.mul(avgEntryPrice),
				rPnl,
				uPnl,
				totalPnl: {
					pnl: totalPnl,
					netPnl: totalNetPnl,
					netPnlPct: totalPnlPct,
				},
				funding: accruedFunding,
				interestCharged: activePos.details?.owedInterest ?? wei(0),
				currencyIconKey: MarketKeyByAsset[activePos.market.asset],
				marketShortName: getMarketName(activePos.market.asset),
			}
		})
	}
	const historyPositions: PositionTablePosition[] = history
		.sort((a, b) => b.openTimestamp - a.openTimestamp)
		.map((stat, i) => {
			const totalDeposit = stat.initialMargin
				? stat.initialMargin.add(
						stat.provider !== PerpsProvider.PERENNIAL_V2_ARB ? stat.totalDeposits : 0
				  )
				: wei(0)
			const openPosition = stat.isOpen
				? positions.find((p) => p.market.marketKey === stat.marketKey)
				: null

			const funding = openPosition?.details?.accruedFunding ?? stat.netFunding
			const rPnl = stat.pnlWithFeesPaid

			const totalPnl = stat.pnl.add(openPosition?.details?.uPnl.pnl ?? 0)
			const totalNetPnl = rPnl.add(openPosition?.details?.uPnl.pnl ?? 0)
			const totalPnlPct = totalDeposit.gt(0) ? totalNetPnl.div(totalDeposit) : wei(0)

			return {
				...stat,
				totalVolume: stat.totalVolume.abs(),
				rank: i + 1 + activePositons.length,
				timestamp: stat.openTimestamp,
				currencyIconKey: MarketKeyByAsset[stat.asset],
				marketShortName: getMarketName(stat.asset),
				status: stat.isOpen ? 'Open' : stat.isLiquidated ? 'Liquidated' : 'Closed',
				funding,
				interestCharged: stat.interestCharged ?? ZERO_WEI,
				rPnl: {
					netPnlPct:
						stat.initialMargin && totalDeposit.gt(0) ? rPnl.div(totalDeposit).mul(100) : wei(0),
					netPnl: rPnl,
					pnl: stat.pnl,
				},
				uPnl: openPosition?.details?.uPnl ?? {
					pnl: ZERO_WEI,
					pnlPct: ZERO_WEI,
				},
				totalPnl: {
					pnl: totalPnl,
					netPnl: totalNetPnl,
					netPnlPct: totalPnlPct,
				},
			}
		})
	return [...activePositons, ...historyPositions]
}

export const orderConditionFromTargetPrice = (
	targetPrice: Wei,
	currentPrice: Wei
): 'isPriceAbove' | 'isPriceBelow' => {
	return targetPrice.lt(currentPrice) ? 'isPriceBelow' : 'isPriceAbove'
}

export const calculateKeeperFeesForOrders = (
	orders: {
		marketKey: FuturesMarketKey
		maxExecutorFee: Wei
		orderType: OrderTypeEnum
	}[]
) => {
	return orders.reduce((acc, o) => {
		return acc.add(o.maxExecutorFee)
	}, wei(0))
}

export const serializeIsolatedMarginTradePreview = (
	preview: IsolatedMarginTradePreview
): IsolatedMarginTradePreview<string> => ({
	...preview,
	newSize: preview.newSize.toString(),
	sizeDelta: preview.sizeDelta.toString(),
	liqPrice: preview.liqPrice.toString(),
	margin: preview.margin.toString(),
	fillPrice: preview.fillPrice.toString(),
	fee: preview.fee.toString(),
	settlementFee: preview.settlementFee.toString(),
	notionalValue: preview.notionalValue.toString(),
	priceImpact: preview.priceImpact.toString(),
	tradeFee: preview.tradeFee.toString(),
	keeperEthDeposit: preview.keeperEthDeposit.toString(),
	maxLeverage: preview.maxLeverage?.toString(),
})

export const unserializeIsolatedMarginTradePreview = (
	preview: IsolatedMarginTradePreview<string>
): IsolatedMarginTradePreview => ({
	...preview,
	settlementFee: wei(preview.settlementFee),
	newSize: wei(preview.newSize),
	sizeDelta: wei(preview.sizeDelta),
	liqPrice: wei(preview.liqPrice),
	margin: wei(preview.margin),
	fillPrice: wei(preview.fillPrice),
	fee: wei(preview.fee),
	notionalValue: wei(preview.notionalValue),
	priceImpact: wei(preview.priceImpact),
	tradeFee: wei(preview.tradeFee),
	keeperEthDeposit: wei(preview.keeperEthDeposit),
	maxLeverage: preview.maxLeverage ? wei(preview.maxLeverage) : undefined,
})

export const calculateV3LiqPrice = (
	accountMargin: MarginInfo<Wei>,
	{ newSize, fillPrice, sizeDelta, requiredMargin }: SnxPerpsV3TradePreview,
	market: PerpsMarketV3
) => {
	const marginDelta = requiredMargin.sub(accountMargin.requiredInitialMargin)

	const deltaMaintenanceMargin = marginDelta.mul(market.settings.maintenanceMarginScalar)
	const newMaintenanceMargin = accountMargin.requiredMaintenanceMargin.add(deltaMaintenanceMargin)

	const minPosMargin = newSize.sub(sizeDelta).eq(0) ? market.settings.minimumPositionMargin : wei(0)
	const maintenanceMargin = newMaintenanceMargin.add(minPosMargin)

	const liqPrice =
		fillPrice && !newSize.eq(0)
			? maintenanceMargin.sub(accountMargin.availableMargin).div(newSize).add(fillPrice)
			: wei(0)
	return liqPrice.gt(0) ? liqPrice : wei(0)
}

export function shouldFilterUserInfoTable(date: Date, tableFilter: TableFilter | null) {
	if (!tableFilter) return true
	const { from: _from, to: _to, span } = tableFilter
	const now = new Date()
	const from = _from ? new Date(_from) : undefined
	const to = _to ? new Date(_to) : undefined

	let pastDate

	switch (span) {
		case '1d':
			pastDate = subDays(now, 1)
			break
		case '1w':
			pastDate = subWeeks(now, 1)
			break
		case '1m':
			pastDate = subMonths(now, 1)
			break
		case '3m':
			pastDate = subMonths(now, 3)
			break
		default:
			break
	}

	if (from && to) {
		return date >= from && date <= to
	} else if (from && !to) {
		return date >= from
	}

	if (!pastDate) return true

	return date >= pastDate && date <= now
}

export function shouldFilterHistoryTableByDate(
	date: Date,
	tableFilter: DashboardHistoryTableFilter | null
) {
	if (!tableFilter) return true
	const { from: _from, to: _to } = tableFilter
	const from = _from ? new Date(_from) : undefined
	const to = _to ? new Date(_to) : undefined

	if (from && to) {
		return date >= from && date <= to
	} else if (from && !to) {
		return date >= from
	}

	return true
}

export function shouldFilterHistoryTable(
	item: {
		asset?: string
		type?: string
		status?: string
		side?: string
		committedTxHash?: string
		settlementTxHash?: string
	},
	tableFilter: DashboardHistoryTableFilter | null
) {
	if (!tableFilter) return true
	const { asset, type, status, side, search } = tableFilter

	if (
		(asset && asset !== item.asset && asset !== 'all') ||
		(type && type !== item.type && type !== 'all') ||
		(status && status !== item.status && status !== 'all') ||
		(side && side !== item.side && side !== 'all') ||
		(search && !item.committedTxHash?.includes(search) && !item.settlementTxHash?.includes(search))
	) {
		return false
	}

	return true
}

export function createFilterOptions(
	items: string[],
	labelModifier?: (x?: string | null) => string | null
) {
	return Array.from(new Set(items)).map((item) => ({
		value: item,
		label: labelModifier ? labelModifier(item) || item : item,
	}))
}

export const capitalize = (s?: string | null) => (s ? s.charAt(0).toUpperCase() + s.slice(1) : '')

export const serializeTransactionOrder = (order: TransactionOrder): TransactionOrder<string> => ({
	...order,
	newSize: order.newSize.toString(),
	sizeDelta: order.sizeDelta.toString(),
	price: order.price.toString(),
})

export const unserializeTransactionOrder = (
	preview: TransactionOrder<string>
): TransactionOrder => ({
	...preview,
	newSize: wei(preview.newSize),
	sizeDelta: wei(preview.sizeDelta),
	price: wei(preview.price),
})

export const formatActiveIsolatedPositions = (
	positions: PerpsV2Position<string>[],
	markets: (PerpsMarketV3 | PerpsMarketV2 | PerennialFuturesMarket)[],
	orders: ConditionOrderTableItem[],
	positionHistory: IsolatedMarginPositionHistory[],
	prices: MarkPrices
) => {
	return (
		positions?.reduce<FuturesPositionTablePosition[]>((acc, p) => {
			const pos = unserializeV2Position(p)
			const uPnl = updatePositionUpnl(p, prices, positionHistory)
			const market = markets.find((m) => m.marketKey === pos.marketKey)
			const history = positionHistory.find((ph) => {
				return ph.isOpen && ph.asset === pos.asset
			})
			const accruedFunding = pos.position?.accruedFunding.add(history?.netFunding ?? ZERO_WEI)

			const rPnl =
				(pos.provider === PerpsProvider.PERENNIAL_V2_ARB
					? pos.position?.rPnl?.pnl
					: history?.pnl) ?? wei(0)

			const rNetPnl =
				pos.provider === PerpsProvider.PERENNIAL_V2_ARB
					? pos.position?.rPnl?.netPnl ?? ZERO_WEI
					: (pos.position?.accruedFunding || ZERO_WEI)
							.add(pos.position?.owedInterest || ZERO_WEI)
							.add(history?.pnlWithFeesPaid || ZERO_WEI)

			const initialMargin = history?.initialMargin ?? pos.position?.initialMargin

			const rPnlPct =
				pos.provider === PerpsProvider.PERENNIAL_V2_ARB
					? pos.position?.rPnl?.netPnlPct ?? wei(0)
					: initialMargin
					? rNetPnl?.div(initialMargin)
					: wei(0)

			if (market) {
				const SL_TP_SIZE =
					market.provider === PerpsProvider.PERENNIAL_V2_ARB ? SL_TP_SIZE_PERENNIAL : SL_TP_MAX_SIZE

				const stopLoss = orders.find((o) => {
					return (
						o.marketKey === market.marketKey &&
						o.size.abs().eq(SL_TP_SIZE) &&
						o.reduceOnly &&
						o.orderType === OrderTypeEnum.STOP_LOSS
					)
				})
				const takeProfit = orders.find(
					(o) =>
						o.marketKey === market.marketKey &&
						o.size.abs().eq(SL_TP_SIZE) &&
						o.reduceOnly &&
						o.orderType === OrderTypeEnum.TAKE_PROFIT
				)

				const position: FuturesPositionTablePosition = {
					id: history?.id ?? pos.position?.id ?? '',
					details: pos.position
						? {
								...history,
								...pos.position,
								avgEntryPrice: history?.avgEntryPrice ?? pos.position.avgEntryPrice,
								rPnl: {
									pnl: rPnl,
									netPnl: rNetPnl,
									netPnlPct: rPnlPct,
								},
								uPnl: {
									pnl: uPnl.pnl,
									pnlPct: uPnl.pnlPct,
								},
								feesPaid: history?.feesPaid ?? pos.position.totalFees ?? wei(0),
								accruedFunding: accruedFunding!,
						  }
						: null,
					remainingMargin: pos.remainingMargin,
					stopLoss: stopLoss?.targetPrice,
					takeProfit: takeProfit?.targetPrice,
					market,
				}
				acc.push(position)
			}
			return acc
		}, []) ?? []
	)
}

export const providerIsCrossMargin = (provider?: PerpsProvider) =>
	provider === PerpsProvider.SNX_V3_BASE || provider === PerpsProvider.SNX_V3_ARB

export const isolatedMarginProvider = (
	provider: PerpsProvider
): PerpsProvider.PERENNIAL_V2_ARB | PerpsProvider.SNX_V2_OP | undefined =>
	provider === PerpsProvider.PERENNIAL_V2_ARB || provider === PerpsProvider.SNX_V2_OP
		? provider
		: undefined

export const crossMarginProvider = (
	provider: PerpsProvider
): PerpsProvider.SNX_V3_BASE | PerpsProvider.SNX_V3_ARB | undefined =>
	provider === PerpsProvider.SNX_V3_BASE || provider === PerpsProvider.SNX_V3_ARB
		? provider
		: undefined
