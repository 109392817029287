import {
	COMMON_ADDRESSES,
	DEFAULT_PRICE_IMPACT_DELTA_PERCENT,
	EST_TRADE_TX_COST_USDC,
	MIN_MARGIN_AMOUNT_V3,
	PERIOD_IN_SECONDS,
	SL_TP_MAX_SIZE,
	SL_TP_SIZE_PERENNIAL,
} from '@kwenta/sdk/constants'
import {
	ConditionalOrderV2,
	ConditionalOrderV3Response,
	DelegateChangeInfo,
	FuturesMarginType,
	FuturesMarketAsset,
	FuturesTrade,
	FuturesVolumes,
	NetworkId,
	OrderTypeEnum,
	PerennialArbNetworkIds,
	PerennialFuturesMarket,
	PerennialTxData,
	Period,
	PerpsProvider,
	PerpsV2Position,
	PerpsV3Position,
	PnlSnapshot,
	PositionSide,
	PotentialTradeStatus,
	SmartMarginOrderInputs,
	SnxV2NetworkIds,
	SnxV3NetworkIds,
	TransactionStatus,
	WriteContractParameters,
} from '@kwenta/sdk/types'
import {
	calculateDesiredFillPrice,
	floorNumber,
	fromWei6,
	getDefaultPriceImpact,
	getTradeStatusMessage,
	simulatedRequestToTxRequest,
	usdcDecimals,
} from '@kwenta/sdk/utils'
import Wei, { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { AbstractionToken } from 'types/accountAbstraction'
import { ConditionOrderTableItem } from 'types/futures'
import {
	Address,
	Hash,
	WalletClient,
	formatEther,
	formatUnits,
	getAddress,
	isHash,
	maxUint128,
	maxUint256,
	parseEther,
	parseUnits,
	zeroAddress,
} from 'viem'

import { notifyError } from 'components/ErrorNotifier'
import { IS_ONE_CLICK_TRADING_ENABLED } from 'constants/defaults'
import { monitorAndAwaitTransaction, monitorAndAwaitUserOp } from 'state/app/helpers'
import {
	handleTransactionError,
	setOpenModal,
	setOpenModalWithParams,
	setTransaction,
	updateTransactionStatus,
} from 'state/app/reducer'
import { fetchBalances, fetchWalletEthBalance } from 'state/balances/actions'
import { ZERO_STATE_TRADE_INPUTS } from 'state/constants'
import {
	clearCrossMarginTradeInputs,
	editCloseCMPositionPrice,
	editCrossMarginCloseAmount,
	editCrossMarginConditionalOrder,
	editCrossMarginPositionSize,
	editCrossMarginTradeOrderPrice,
	editCrossMarginTradeSize,
	fetchAccountCollateralBalances,
	fetchMarginSnapshotsV3,
	fetchPerpsV3Balances,
	removeStaleOrders,
} from 'state/futures/snxPerpsV3/actions'
import { handleFetchError } from 'state/helpers'
import { AppThunk } from 'state/store'
import { FetchStatus, ThunkConfig } from 'state/types'
import { setAbstractionAddress } from 'state/wallet/reducer'
import { selectAbstractionAddress, selectSignerNetwork, selectWallet } from 'state/wallet/selectors'
import { currentTimestamp } from 'utils/date'
import { DateInterval } from 'utils/dates'
import {
	formatDelayedOrders,
	orderPriceInvalidLabel,
	providerIsCrossMargin,
	serializeConditionalOrdersV2,
	serializeDelayedOrders,
	serializeFuturesVolumes,
	serializeIsolatedMarginTradePreview,
	serializeIsolatedPositionHistory,
	serializePerennialMarkets,
	serializePerpsV3PositionHistory,
	serializeTrades,
	serializeTransactionOrder,
	serializeV2Markets,
	serializeV2Position,
	serializeV3AsyncOrder,
	serializeV3Market,
	serializeV3Positions,
} from 'utils/futures'
import logError from 'utils/logError'

import { fetchAccountMarginInfo, submitFuturesTransaction } from './common/actions'
import {
	selectAccountData,
	selectIsConditionalOrder,
	selectLeverageSide,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketInfo,
	selectOneClickTradingSelected,
	selectPerennialV2Network,
	selectPerpsProvider,
	selectProviderNetworks,
	selectSnxPerpsV2Network,
	selectSnxV3BaseNetwork,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectTradePanelOrderPriceInput,
	selectTradePanelSlTpInputs,
	selectTradePanelTradePrice,
	selectTradingMode,
	selectV2Markets,
	selectV3Markets,
	selectSnxV3Network,
} from './common/selectors'
import {
	DebouncedPreviewParams,
	FundingRates,
	IsolatedTradePreviewParams,
	SnxPerpsV3TradePreviewParams,
} from './common/types'
import {
	approveIsolatedMargin,
	calculateSnxV2TradeFees,
	clearSmartMarginTradeInputs,
	editCloseIsolatedPositionPrice,
	editCloseIsolatedPositionSizeDelta,
	editIsolatedMarginPositionSize,
	editIsolatedMarginConditionalOrder,
	editSmartMarginTradeOrderPrice,
} from './isolatedMargin/actions'
import {
	selectAllIsolatedConditionalOrders,
	selectIsolatedAvailableMargin,
	selectIsolatedBalanceInfo,
	selectIsolatedMarginDelayedOrders,
	selectIsolatedMarginMarginDelta,
	selectOldestIsolatedGlobalTrade,
	selectPerennialActivePositions,
	selectSignerSupportsSnxV2Op,
	selectSnxV2Account,
} from './isolatedMargin/selectors'
import {
	selectPerennialAccountData,
	selectPerennialMarkets,
	selectPerennialPositionHistory,
} from './perennial/selectors'
import {
	clearTradePreview,
	handlePreviewError,
	incrementPreviewCount,
	setAbstractionEthBalance,
	setAbstractionTxCost,
	setAbstractionUsdcBalance,
	setAccount,
	setCancellingConditionalOrder,
	setDelegated,
	setEditConditonalOrderInputs,
	setEditPositionInputs,
	setFundingRatesHistory,
	setGlobalTradeHistory,
	setLeverageInput,
	setLeverageSide,
	setMarginDelta,
	setMarkets,
	setQueryStatus,
	setSelectedMarketAsset,
	setSession,
	setSubAccountsForWallet,
	setTradeInputs,
	setTradePreview,
	setTradeStopLoss,
	setTraderHistory,
	setVolumes,
	updateAccountData,
} from './reducer'
import {
	selectAbstractionDelegated,
	selectAbstractionUsedToken,
	selectAccountContext,
	selectAccountContexts,
	selectAddressBook,
	selectPosition,
	selectPreviewCount,
	selectSessionExpiry,
	selectTradePreview,
	selectTradePreviewKeeperDeposit,
} from './selectors'
import {
	selectAsyncCrossMarginOrder,
	selectCrossMarginAvailableMargin,
	selectMargineEngineEnabled,
	selectReservedMarginForOrders,
	selectSnxV3AccountContext,
	selectSnxV3Account,
	selectSnxV3GlobalTradesForMarket,
	selectV3SelectedMarketId,
} from './snxPerpsV3/selectors'
import { SnxPerpsV3TradePreview } from './snxPerpsV3/types'
import { DelegationAccountInfo, ManageModalType, TradingModes } from './types'

export const setMarketAsset = createAsyncThunk<void, FuturesMarketAsset, ThunkConfig>(
	'futures/setMarketAsset',
	async (asset, { dispatch, getState }) => {
		const provider = selectPerpsProvider(getState())
		dispatch(clearTradeInputs())
		dispatch(setSelectedMarketAsset({ asset, provider }))
	}
)

export const fetchMarkets = createAsyncThunk<void, { providers: PerpsProvider[] }, ThunkConfig>(
	'futures/fetchMarkets',
	async ({ providers }, { dispatch, getState, extra: { sdk } }) => {
		for (const p of providers) {
			dispatch(setQueryStatus({ key: 'get_markets', status: FetchStatus.Loading, provider: p }))
			try {
				if (p === PerpsProvider.SNX_V2_OP) {
					const v2Network = selectSnxPerpsV2Network(getState())
					const markets = await sdk.snxPerpsV2.getMarkets(v2Network)
					const serializedMarkets = serializeV2Markets(markets)
					dispatch(
						setMarkets({
							provider: PerpsProvider.SNX_V2_OP,
							markets: serializedMarkets,
						})
					)
				}

				if (p === PerpsProvider.SNX_V3_BASE || p === PerpsProvider.SNX_V3_ARB) {
					const v3Network = selectSnxV3Network(getState())
					const v3Markets = await sdk.snxPerpsV3.getMarkets(v3Network)
					dispatch(
						setMarkets({
							markets: v3Markets.map(serializeV3Market),
							provider: p,
						})
					)
				}

				if (p === PerpsProvider.PERENNIAL_V2_ARB) {
					const chainId = selectPerennialV2Network(getState())
					const perennialMarkets = await sdk.perennial.getMarkets(chainId)

					dispatch(
						setMarkets({
							markets: serializePerennialMarkets(perennialMarkets),
							provider: PerpsProvider.PERENNIAL_V2_ARB,
						})
					)
				}
				dispatch(setQueryStatus({ key: 'get_markets', status: FetchStatus.Success }))
			} catch (err) {
				handleFetchError(dispatch, 'get_markets', err, { provider: p })
			}
		}
	}
)

export const fetchDailyVolumes = createAsyncThunk<
	void,
	{ providers: PerpsProvider[] },
	ThunkConfig
>('futures/fetchDailyVolumes', async ({ providers }, { getState, dispatch, extra: { sdk } }) => {
	for (const p of providers) {
		dispatch(setQueryStatus({ key: 'get_daily_volumes', status: FetchStatus.Loading, provider: p }))
		const chain = selectProviderNetworks(getState())[p]
		try {
			let volumes: FuturesVolumes = {}
			switch (p) {
				case PerpsProvider.SNX_V2_OP:
					volumes = await sdk.snxPerpsV2.getDailyVolumes(chain as SnxV2NetworkIds)
					break
				case PerpsProvider.SNX_V3_BASE:
					volumes = await sdk.snxPerpsV3.getDailyVolumes(chain as SnxV3NetworkIds)
					break
				case PerpsProvider.SNX_V3_ARB:
					// TODO: ARB volumes
					break
				case PerpsProvider.PERENNIAL_V2_ARB:
					volumes = await sdk.perennial.getDailyVolumes(chain as PerennialArbNetworkIds)
					break
			}

			const serialized = serializeFuturesVolumes(volumes)
			dispatch(
				setVolumes({
					provider: p,
					volumes: serialized,
				})
			)
			dispatch(
				setQueryStatus({ key: 'get_daily_volumes', status: FetchStatus.Success, provider: p })
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_daily_volumes', err, { provider: p })
		}
	}
})

export const fetchMarketData = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchMarketData',
	async (providers, { dispatch }) => {
		await dispatch(fetchMarkets({ providers }))
		dispatch(fetchDailyVolumes({ providers }))
	}
)

export const fetchFundingRatesHistory = createAsyncThunk<
	void,
	{ marketAsset: FuturesMarketAsset; period: Period; provider: PerpsProvider }[],
	ThunkConfig
>('futures/fetchFundingRatesHistory', async (providers, { dispatch, getState, extra: { sdk } }) => {
	for (const p of providers) {
		const chain = selectProviderNetworks(getState())[p.provider]

		try {
			dispatch(
				setQueryStatus({
					key: 'get_funding_history',
					status: FetchStatus.Loading,
					provider: p.provider,
				})
			)
			let rates: FundingRates = []
			switch (p.provider) {
				case PerpsProvider.SNX_V2_OP:
					rates = await sdk.snxPerpsV2.getMarketFundingRatesHistory(
						p.marketAsset,
						PERIOD_IN_SECONDS[p.period]
					)
					break
				case PerpsProvider.SNX_V3_BASE:
					rates = await sdk.snxPerpsV3.getMarketFundingRatesHistory(
						p.marketAsset,
						chain as SnxV3NetworkIds,
						PERIOD_IN_SECONDS[p.period]
					)
					break
				case PerpsProvider.SNX_V3_ARB:
					// TODO: Arb rates
					break
				case PerpsProvider.PERENNIAL_V2_ARB:
					rates = await sdk.perennial.getMarketFundingRatesHistory(
						p.marketAsset,
						chain as PerennialArbNetworkIds,
						PERIOD_IN_SECONDS[p.period]
					)
					break
			}

			dispatch(setFundingRatesHistory({ asset: p.marketAsset, rates, provider: p.provider }))

			dispatch(
				setQueryStatus({
					key: 'get_funding_history',
					status: FetchStatus.Success,
					provider: p.provider,
				})
			)
		} catch (err) {
			handleFetchError(dispatch, 'get_funding_history', err, { provider: p.provider })
		}
	}
})

export const fetchActivePositions = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchActivePositions',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			try {
				const { wallet, network, accountId } = contexts[provider]
				if (!wallet || !accountId) continue

				dispatch(
					setQueryStatus({ key: 'get_positions', status: FetchStatus.Loading, provider: provider })
				)

				if (provider === PerpsProvider.SNX_V2_OP) {
					const markets = selectV2Markets(getState())

					const positions = await sdk.snxPerpsV2.getFuturesPositions(
						accountId,
						markets.map((m) => ({
							asset: m.asset,
							marketKey: m.marketKey,
							address: m.marketAddress,
						})),
						network as SnxV2NetworkIds
					)
					const serializedPositions = positions.map((p) => serializeV2Position(p))
					dispatch(
						updateAccountData({
							wallet: wallet,
							data: {
								provider: PerpsProvider.SNX_V2_OP,
								positions: serializedPositions,
								network: network as NetworkId,
								account: accountId,
							},
						})
					)
				} else if (provider === PerpsProvider.SNX_V3_BASE) {
					const markets = selectV3Markets(getState())
					const positions = await sdk.snxPerpsV3.getPositions(
						BigInt(accountId),
						markets.map((m) => BigInt(m.marketId)),
						network as SnxV3NetworkIds
					)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								network: network,
								account: accountId.toString(),
								positions: serializeV3Positions(positions),
								provider: PerpsProvider.SNX_V3_BASE,
							},
						})
					)
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					const positions = await sdk.perennial.getPositions(wallet, {
						chainId: network as PerennialArbNetworkIds,
					})
					const serializedPositions = positions.map((p) =>
						serializeV2Position({ ...p, provider: PerpsProvider.PERENNIAL_V2_ARB })
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								network: network,
								account: accountId.toString(),
								positions: serializedPositions,
								provider: PerpsProvider.PERENNIAL_V2_ARB,
							},
						})
					)
				}
				dispatch(setQueryStatus({ key: 'get_positions', status: FetchStatus.Success, provider }))
			} catch (err) {
				handleFetchError(dispatch, 'get_positions', err, { provider })
			}
		}
	}
)

export const fetchOpenConditionalOrders = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchOpenConditionalOrders',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())
		let orders: ConditionalOrderV3Response[] | ConditionalOrderV2[] = []
		for (const provider of providers) {
			try {
				const { network: networkId, accountId, wallet } = contexts[provider]
				if (!accountId || !networkId || !wallet) continue

				dispatch(
					setQueryStatus({ key: 'get_conditional_orders', status: FetchStatus.Loading, provider })
				)
				if (provider === PerpsProvider.SNX_V3_BASE) {
					orders = await sdk.snxPerpsV3.getConditionalOrders(
						BigInt(accountId),
						networkId as SnxV3NetworkIds
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								network: networkId,
								provider: PerpsProvider.SNX_V3_BASE,
								account: accountId.toString(),
								conditionalOrders: orders,
							},
						})
					)
				} else if (provider === PerpsProvider.SNX_V2_OP) {
					const markets = selectV2Markets(getState())

					const marketAddresses = markets.map((market) => market.marketAddress)
					const orders = await sdk.snxPerpsV2.getConditionalOrders(
						accountId,
						networkId as SnxV2NetworkIds
					)

					const delayedOrders = await sdk.snxPerpsV2.getDelayedOrders(
						accountId,
						marketAddresses,
						networkId as SnxV2NetworkIds
					)
					const nonzeroOrders = formatDelayedOrders(delayedOrders, markets)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.SNX_V2_OP,
								account: accountId,
								network: networkId,
								delayedOrders: serializeDelayedOrders(nonzeroOrders),
								conditionalOrders: serializeConditionalOrdersV2(orders),
							},
						})
					)
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					const orders = await sdk.perennial.getConditionalOrders(wallet, {
						chainId: networkId as PerennialArbNetworkIds,
					})

					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.PERENNIAL_V2_ARB,
								account: accountId,
								network: networkId,
								conditionalOrders: serializeConditionalOrdersV2(orders),
							},
						})
					)
				}

				dispatch(
					setQueryStatus({ key: 'get_conditional_orders', status: FetchStatus.Success, provider })
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_conditional_orders', err, { provider })
			}
		}
	}
)

export const fetchPendingMarketOrders = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchPendingMarketOrders',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			try {
				const { network: networkId, accountId, wallet } = contexts[provider]
				if (!accountId || !networkId || !wallet) continue

				dispatch(
					setQueryStatus({
						key: 'get_pending_market_orders',
						status: FetchStatus.Loading,
						provider,
					})
				)
				if (provider === PerpsProvider.SNX_V3_BASE) {
					const existingOrder = selectAsyncCrossMarginOrder(getState())
					const order = await sdk.snxPerpsV3.getPendingAsyncOrder(
						BigInt(accountId),
						networkId as SnxV3NetworkIds
					)
					const orderChanged = existingOrder?.executableStartTime !== order?.settlementTime
					if (orderChanged) {
						dispatch(fetchActivePositions([PerpsProvider.SNX_V3_BASE]))
						dispatch(removeStaleOrders())
					}
					dispatch(
						updateAccountData({
							wallet,
							data: {
								account: accountId.toString(),
								provider: PerpsProvider.SNX_V3_BASE,
								network: networkId,
								pendingAsyncOrder: order ? serializeV3AsyncOrder(order) : undefined,
							},
						})
					)
				} else if (provider === PerpsProvider.SNX_V2_OP) {
					const markets = selectV2Markets(getState())
					const marketAddresses = markets.map((market) => market.marketAddress)
					const delayedOrders = await sdk.snxPerpsV2.getDelayedOrders(
						accountId,
						marketAddresses,
						networkId as SnxV2NetworkIds
					)
					const nonzeroOrders = formatDelayedOrders(delayedOrders, markets)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.SNX_V2_OP,
								account: accountId,
								network: networkId,
								delayedOrders: serializeDelayedOrders(nonzeroOrders),
							},
						})
					)
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					// TODO: Perennial
				}

				dispatch(
					setQueryStatus({
						key: 'get_pending_market_orders',
						status: FetchStatus.Success,
						provider,
					})
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_pending_market_orders', err, { provider })
			}
		}
	}
)

export const fetchAllOpenOrders = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchAllOpenOrders',
	async (providers, { dispatch }) => {
		dispatch(fetchPendingMarketOrders(providers))
		dispatch(fetchOpenConditionalOrders(providers))
	}
)

export const fetchPerpsAccounts = createAsyncThunk<
	void,
	{ providers: PerpsProvider[]; refetch?: boolean },
	ThunkConfig
>(
	'futures/fetchPerpsAccounts',
	async ({ providers, refetch }, { getState, dispatch, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		const chainIds = selectProviderNetworks(getState())

		if (!wallet) return undefined

		for (const provider of providers) {
			dispatch(setQueryStatus({ key: 'get_accounts', status: FetchStatus.Loading, provider }))

			try {
				switch (provider) {
					case PerpsProvider.SNX_V2_OP:
						const v2Network = selectSnxPerpsV2Network(getState())
						const v2Accounts = getState().futures.accounts[PerpsProvider.SNX_V2_OP]

						// Already have an account fetched and persisted for this address
						if (v2Accounts?.[wallet]?.network === v2Network) return

						const fetchedAccounts = await sdk.snxPerpsV2.getSmartMarginAccounts(
							wallet,
							chainIds[provider] as SnxV2NetworkIds
						)
						const account = fetchedAccounts[0]
						if (account) {
							dispatch(
								setAccount({
									account,
									wallet,
									networkId: v2Network,
									provider: PerpsProvider.SNX_V2_OP,
								})
							)
						}

						break

					case PerpsProvider.SNX_V3_BASE:
						const marginEngineEnabled = selectMargineEngineEnabled(getState())
						const v3Network = selectSnxV3BaseNetwork(getState())

						const existsing = getState().futures.accounts[PerpsProvider.SNX_V3_BASE]?.[wallet]
						const existingAccount = wallet
							? existsing?.network === v3Network && existsing.marginEnginePermitted
							: undefined

						if (existingAccount && !refetch) return

						dispatch(setQueryStatus({ key: 'get_accounts', status: FetchStatus.Loading }))

						const accounts = await sdk.snxPerpsV3.getAccounts(
							wallet,
							marginEngineEnabled,
							v3Network
						)

						if (!accounts.length) return undefined
						// TODO: Support multiple accounts
						const { accountId, marginEnginePermitted } = accounts[0]
						dispatch(
							setAccount({
								account: accountId.toString(),
								networkId: v3Network,
								wallet,
								provider: PerpsProvider.SNX_V3_BASE,
								marginEnginePermitted,
							})
						)
						break

					case PerpsProvider.PERENNIAL_V2_ARB:
						const perennialNetwork = selectPerennialV2Network(getState())
						const res = await sdk.perennial.checkMarketFactoryApproval(wallet)
						if (res) {
							dispatch(
								setAccount({
									account: wallet,
									networkId: perennialNetwork,
									wallet,
									provider: PerpsProvider.PERENNIAL_V2_ARB,
								})
							)
							break
						}

					// TODO: Perennial
				}
				dispatch(setQueryStatus({ key: 'get_accounts', status: FetchStatus.Success, provider }))
			} catch (err) {
				handleFetchError(dispatch, 'get_accounts', err, { provider })
			}
		}
	}
)

export const fetchHighFrequencyAccountData = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchHighFrequencyAccountData',
	async (providers, { dispatch }) => {
		dispatch(fetchActivePositions(providers))
		dispatch(fetchAllOpenOrders(providers))
		dispatch(fetchAccountMarginInfo(providers))

		for (const provider of providers) {
			if (providerIsCrossMargin(provider)) {
				dispatch(fetchAccountCollateralBalances())
			}
		}
	}
)

export const fetchLowFrequencyAccountData = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchLowFrequencyAccountData',
	async (providers, { dispatch }) => {
		dispatch(fetchMarginTransfers(providers))

		for (const provider of providers) {
			if (providerIsCrossMargin(provider)) {
				dispatch(fetchPerpsV3Balances())
			}
		}
	}
)

export const fetchAccountData = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchAccountData',
	async (providers, { dispatch }) => {
		await dispatch(fetchHighFrequencyAccountData(providers))
		await dispatch(fetchLowFrequencyAccountData(providers))
	}
)

export const fetchMarginTransfers = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchMarginTransfers',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		const contexts = selectAccountContexts(getState())

		if (!wallet) return

		for (const provider of providers) {
			try {
				const { network, accountId, wallet } = contexts[provider]
				if (!accountId || !network || !wallet) continue

				dispatch(
					setQueryStatus({ key: 'get_margin_transfers', status: FetchStatus.Loading, provider })
				)
				switch (provider) {
					case PerpsProvider.SNX_V2_OP:
						const marketTransfers = await sdk.snxPerpsV2.getMarketMarginTransfers(
							accountId as Address,
							network as SnxV2NetworkIds
						)
						const accountTransfers = await sdk.snxPerpsV2.getSmartMarginAccountTransfers(
							accountId,
							network as SnxV2NetworkIds
						)

						dispatch(
							updateAccountData({
								wallet,
								data: {
									account: accountId,
									provider: PerpsProvider.SNX_V2_OP,
									network,
									marketMarginTransfers: marketTransfers,
									accountTransfers: accountTransfers,
								},
							})
						)
						break

					case PerpsProvider.SNX_V3_BASE:
						const accountTransfersV3 = await sdk.snxPerpsV3.getCollateralTransfers(
							network as SnxV3NetworkIds,
							BigInt(accountId)
						)
						dispatch(
							updateAccountData({
								wallet,
								data: {
									provider: PerpsProvider.SNX_V3_BASE,
									account: accountId.toString(),
									network,
									accountTransfers: accountTransfersV3,
								},
							})
						)
						break
					case PerpsProvider.PERENNIAL_V2_ARB:
					// TODO: Perennial
				}
				dispatch(
					setQueryStatus({ key: 'get_margin_transfers', status: FetchStatus.Success, provider })
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_margin_transfers', err, { provider })
			}
		}
	}
)

export const fetchOrderHistory = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchOrderHistory',
	async (providers, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			const { network, accountId, wallet } = contexts[provider]
			if (!accountId || !network || !wallet) continue

			dispatch(setQueryStatus({ key: 'get_order_history', status: FetchStatus.Loading, provider }))

			try {
				if (provider === PerpsProvider.SNX_V3_BASE) {
					const orders = await sdk.snxPerpsV3.getOrderHistory(
						BigInt(accountId),
						network as SnxV3NetworkIds
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								account: accountId,
								orderHistory: orders,
								network,
								provider: PerpsProvider.SNX_V3_BASE,
							},
						})
					)
				} else if (provider === PerpsProvider.SNX_V2_OP) {
					const orderHistory = await sdk.snxPerpsV2.getOrderHistory(
						wallet,
						network as SnxV2NetworkIds
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								network,
								account: accountId,
								provider: PerpsProvider.SNX_V2_OP,
								orderHistory,
							},
						})
					)
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					const orderHistory = await sdk.perennial.getConditionalOrderHistory(wallet, {
						chainId: network as PerennialArbNetworkIds,
						pageSize: 50,
						minTimestamp: 0,
						maxTimestamp: currentTimestamp(),
					})
					dispatch(
						updateAccountData({
							wallet,
							data: {
								network,
								account: accountId,
								provider: PerpsProvider.PERENNIAL_V2_ARB,
								orderHistory,
							},
						})
					)
				}
				dispatch(
					setQueryStatus({ key: 'get_order_history', status: FetchStatus.Success, provider })
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_order_history', err, { provider })
			}
		}
	}
)

export const fetchTradeHistory = createAsyncThunk<
	void,
	{ providers: PerpsProvider[]; pageSize?: number },
	ThunkConfig
>(
	'futures/fetchTradeHistory',
	async ({ providers, pageSize = 50 }, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			const { network, accountId, wallet } = contexts[provider]
			if (!accountId || !network || !wallet) continue

			dispatch(setQueryStatus({ key: 'get_trade_history', status: FetchStatus.Loading, provider }))

			try {
				let trades: FuturesTrade[] = []
				if (provider === PerpsProvider.SNX_V3_BASE) {
					trades = await sdk.snxPerpsV3.getTradeHistory(
						BigInt(accountId),
						network as SnxV3NetworkIds
					)
				} else if (provider === PerpsProvider.SNX_V2_OP) {
					trades = await sdk.snxPerpsV2.getTradeHistory({
						walletAddress: wallet,
						pageLength: pageSize,
						chainId: network as SnxV2NetworkIds,
					})
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					trades = await sdk.perennial.getTradeHistory({
						chainId: network as PerennialArbNetworkIds,
						walletAddress: wallet,
					})
				}
				dispatch(
					updateAccountData({
						wallet,
						data: {
							account: accountId,
							trades: serializeTrades(trades),
							network,
							provider: provider,
						},
					})
				)

				dispatch(
					setQueryStatus({ key: 'get_trade_history', status: FetchStatus.Success, provider })
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_trade_history', err, { provider })
			}
		}
	}
)

export const fetchGlobalTradeHistory = createAsyncThunk<
	void,
	{ providers: PerpsProvider[]; pageSize?: number; nextPage?: boolean },
	ThunkConfig
>(
	'futures/fetchGlobalTradeHistory',
	async ({ providers, pageSize = 50, nextPage }, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())
		const marketAsset = selectMarketAsset(getState())

		for (const provider of providers) {
			dispatch(setQueryStatus({ key: 'get_global_trades', status: FetchStatus.Loading, provider }))

			const { network } = contexts[provider]
			if (!network) continue

			try {
				let trades: FuturesTrade[] = []
				if (provider === PerpsProvider.SNX_V3_BASE) {
					const marketId = selectV3SelectedMarketId(getState())
					const existingTrades = selectSnxV3GlobalTradesForMarket(getState())
					if (!marketId) return
					const oldestTrade = existingTrades?.[existingTrades.length - 1]
					const maxTimestamp = nextPage
						? oldestTrade?.timestamp ?? currentTimestamp()
						: currentTimestamp()
					const minTimestamp = 0
					trades = await sdk.snxPerpsV3.getAllTradesByMarket(marketId, network as SnxV3NetworkIds, {
						minTimestamp,
						maxTimestamp,
						pageSize: 50,
					})
				} else {
					const oldestTrade = selectOldestIsolatedGlobalTrade(getState())
					if (provider === PerpsProvider.SNX_V2_OP) {
						trades = await sdk.snxPerpsV2.getTradeHistory({
							pageLength: pageSize,
							marketAsset,
							maxTimestamp: nextPage ? oldestTrade?.timestamp : undefined,
							chainId: network as SnxV2NetworkIds,
						})
					} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
						trades = await sdk.perennial.getGlobalTradesHistory({
							pageSize: 50,
							marketAsset,
							chainId: network as PerennialArbNetworkIds,
							minTimestamp: 0,
							maxTimestamp: nextPage
								? oldestTrade?.timestamp ?? currentTimestamp()
								: currentTimestamp(),
						})
					}
				}

				dispatch(
					setGlobalTradeHistory({
						trades: serializeTrades(trades),
						provider: provider,
						marketAsset,
					})
				)
				dispatch(
					setQueryStatus({ key: 'get_global_trades', status: FetchStatus.Success, provider })
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_global_trades', err, { provider })
			}
		}
	}
)

export const fetchPnlSnapshots = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchPnlSnapshots',
	async (_, { dispatch, getState, extra: { sdk } }) => {
		const { network: networkId, accountId, wallet, provider } = selectAccountContext(getState())
		if (!wallet || !accountId) return

		try {
			if (provider === PerpsProvider.SNX_V2_OP) return
			dispatch(setQueryStatus({ key: 'get_pnl_snapshots', status: FetchStatus.Loading }))

			let snapshots: PnlSnapshot[] = []
			if (provider === PerpsProvider.SNX_V3_BASE) {
				snapshots = await sdk.snxPerpsV3.getPnlSnapshots(
					BigInt(accountId),
					networkId as SnxV3NetworkIds
				)
			} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				snapshots = await sdk.perennial.getPnlUpdates(wallet, networkId as PerennialArbNetworkIds)
				snapshots = snapshots
					.reverse()
					.reduce((acc, s, i) => {
						const existing = acc[i - 1]
						if (existing) {
							acc.push({
								...s,
								pnl: existing.pnl.add(s.pnl),
							})
						} else {
							acc.push(s)
						}
						return acc
					}, [] as PnlSnapshot[])
					.reverse()
			}
			const serialized = snapshots.map((s) => ({
				...s,
				pnl: s.pnl.toString(),
			}))
			dispatch(
				updateAccountData({
					wallet,
					data: {
						network: networkId,
						provider: provider,
						account: accountId.toString(),
						pnlSnapshots: serialized,
					},
				})
			)
			dispatch(setQueryStatus({ key: 'get_pnl_snapshots', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_pnl_snapshots', err)
		}
	}
)

export const fetchDashboardChartData = (): AppThunk => (dispatch) => {
	dispatch(fetchMarginSnapshotsV3())
	dispatch(fetchPnlSnapshots())
}

export const debouncedPrepareTradePreview = debounce((dispatch, inputs: DebouncedPreviewParams) => {
	dispatch(fetchTradePreview(inputs))
}, 500)

export const stageTradePreview = createAsyncThunk<
	void,
	IsolatedTradePreviewParams | SnxPerpsV3TradePreviewParams,
	ThunkConfig
>('futures/stageTradePreview', async (inputs, { dispatch, getState }) => {
	dispatch(incrementPreviewCount())
	const debounceCount = selectPreviewCount(getState())
	debouncedPrepareTradePreview(dispatch, { ...inputs, debounceCount })
})

export const fetchTradePreview = createAsyncThunk<void, DebouncedPreviewParams, ThunkConfig>(
	'futures/fetchTradePreview',
	async (params, { dispatch, getState, extra: { sdk } }) => {
		const accountData = selectAccountData(getState())
		const { idleMarginByMarket } = selectIsolatedBalanceInfo(getState())

		const positions = accountData?.positions ?? []
		const position = positions.find((p) => p.marketKey === params.market.marketKey)

		const queryKey = 'get_trade_preview_' + params.action

		// Check the preview hasn't been cleared before query resolves
		const count = selectPreviewCount(getState())

		if (count !== params.debounceCount) {
			const existing = getState().futures.tradePreviews[params.provider]
			dispatch(
				setTradePreview({
					preview: existing,
					provider: params.provider,
				})
			)
			dispatch(setQueryStatus({ key: queryKey, status: FetchStatus.Success }))
			return
		}

		if (!params.orderPrice && params.isConditional) {
			// Clear trade preview if waiting for price
			dispatch(
				setTradePreview({
					preview: undefined,
					provider: PerpsProvider.SNX_V2_OP,
				})
			)
			return
		}

		if (
			params.provider === PerpsProvider.SNX_V2_OP ||
			params.provider === PerpsProvider.PERENNIAL_V2_ARB
		) {
			const isolatedPosition = position as PerpsV2Position<string> | undefined
			const freeMargin = selectIsolatedAvailableMargin(getState())
			const marketMargin =
				params.provider === PerpsProvider.PERENNIAL_V2_ARB
					? idleMarginByMarket[params.market.asset] ?? wei(0)
					: wei(isolatedPosition?.remainingMargin ?? 0)

			if (
				// Require both size and margin for a trade
				(params.action === 'trade' && (params.sizeDelta.eq(0) || params.marginDelta.eq(0))) ||
				// Require one or the other when editing a position
				(params.sizeDelta.eq(0) && params.marginDelta.eq(0))
			) {
				dispatch(
					setTradePreview({
						preview: undefined,
						provider: PerpsProvider.SNX_V2_OP,
					})
				)
				return
			}

			try {
				// If this is a trade with no existsing position size then we need to subtract
				// remaining idle market margin to get an accurate preview
				const marginDelta =
					wei(isolatedPosition?.position?.size ?? 0)
						.abs()
						.eq(0) &&
					marketMargin.gt(0) &&
					params.action === 'trade'
						? params.marginDelta.sub(marketMargin)
						: params.marginDelta

				dispatch(setQueryStatus({ key: queryKey, status: FetchStatus.Loading }))
				const perennialChain = selectPerennialV2Network(getState())
				const snxChain = selectSnxPerpsV2Network(getState())
				const leverageSide = params.sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT
				const sizeIncreasing =
					!isolatedPosition ||
					(params.sizeDelta.gt(0) && isolatedPosition.position?.side === PositionSide.LONG) ||
					(params.sizeDelta.lt(0) && isolatedPosition.position?.side === PositionSide.SHORT)

				const preview =
					params.provider === PerpsProvider.SNX_V2_OP
						? await sdk.snxPerpsV2.getSmartMarginTradePreview({
								account: (params.accountId || zeroAddress) as Address,
								market: params.market,
								chainId: snxChain,
								tradeParams: {
									...params,
									leverageSide,
									marginDelta,
									orderPrice: params.orderPrice ?? params.currentIndexPrice,
								},
						  })
						: await sdk.perennial.getTradePreview({
								walletAddress: params.accountId as Address,
								market: params.market.asset,
								chainId: perennialChain,
								tradeParams: {
									sizeDelta:
										sizeIncreasing && params.action !== 'close'
											? fromWei6(params.sizeDelta.abs())
											: fromWei6(params.sizeDelta.abs().neg()),
									positionSide: isolatedPosition?.position?.side ?? leverageSide,
									marginDelta: fromWei6(marginDelta),
									orderPrice: params.orderPrice ? fromWei6(params.orderPrice) : undefined,
								},
						  })

				if (params.marginDelta.gt(freeMargin) && preview.status === 0) {
					// Show insufficient margin message
					preview.status = PotentialTradeStatus.INSUFFICIENT_FREE_MARGIN
					preview.statusMessage = getTradeStatusMessage(
						PotentialTradeStatus.INSUFFICIENT_FREE_MARGIN,
						params.provider
					)
					preview.showStatus = true
				}

				const fees =
					params.market.provider === PerpsProvider.SNX_V2_OP
						? calculateSnxV2TradeFees(getState(), {
								...params,
								orderPrice: params.orderPrice ?? params.currentIndexPrice,
						  })
						: {
								tradeFee: preview.fee as Wei,
								keeperEthDeposit: wei(0),
						  }

				const serializedPreview = serializeIsolatedMarginTradePreview({
					...preview,
					...fees,
					action: params.action,
					provider: params.provider,
					marginType: FuturesMarginType.ISOLATED_MARGIN,
					leverage: preview.leverage.toNumber(),
					settlementFee: params.market.settlementFee,
					marketKey: params.market.marketKey,
					isConditional: !!params.isConditional,
				})

				dispatch(
					setTradePreview({
						preview: serializedPreview,
						provider: params.provider,
					})
				)
				dispatch(setQueryStatus({ key: queryKey, status: FetchStatus.Success }))
			} catch (err) {
				handleFetchError(dispatch, queryKey, err)
				dispatch(
					handlePreviewError({
						provider: params.provider,
						error: err.message,
						previewType: params.action,
					})
				)
				dispatch(
					setTradePreview({
						preview: undefined,
						provider: params.provider,
					})
				)
			}
		} else if (params.provider === PerpsProvider.SNX_V3_BASE) {
			const market = params.market
			const availableMargin = selectCrossMarginAvailableMargin(getState())
			const { accountId, network } = selectSnxV3AccountContext(getState())
			const reservedMargin = selectReservedMarginForOrders(getState())

			try {
				if (!market) throw new Error('No market selected')
				if (!market.settlementStrategies[0]) throw new Error('No settlement strategy found')
				dispatch(setQueryStatus({ key: queryKey, status: FetchStatus.Loading }))
				const preview = await sdk.snxPerpsV3.getTradePreview({
					accountId: accountId ? BigInt(accountId) : undefined,
					marketId: market.marketId,
					chainId: network,
					sizeDelta: params.sizeDelta,
					indexPrice: params.indexPrice,
				})

				const v3Position = position as PerpsV3Position<string>

				const size =
					(v3Position?.side === PositionSide.LONG
						? wei(v3Position?.size ?? 0)
						: wei(v3Position?.size ?? 0).neg()) ?? wei(0)

				// Price impact on limit orders are only a rough indication as
				// it it is only accurate for the current market price
				const priceImpact = preview.fillPrice.sub(params.marketPrice).div(params.marketPrice).abs()
				const notional = preview.fillPrice.mul(params.sizeDelta).abs()
				const tradeableMargin = availableMargin.sub(reservedMargin)
				const newSize = size.add(params.sizeDelta) ?? params.sizeDelta

				const fillPrice = params.isConditional
					? params.orderPrice ?? params.indexPrice
					: preview.fillPrice
				const populatedPreview: SnxPerpsV3TradePreview<string> = {
					action: params.action,
					provider: PerpsProvider.SNX_V3_BASE,
					marginType: FuturesMarginType.CROSS_MARGIN,
					settlementFee: preview.settlementFee.toString(),
					marketId: market.marketId,
					fee: preview.fee.toString(),
					requiredMargin: preview.requiredMargin.toString(),
					fillPrice: fillPrice.toString(),
					priceImpact: priceImpact.toString(),
					newSize: newSize.toString(),
					liqPrice: '0',
					exceedsPriceProtection: priceImpact
						.mul(100)
						.gt(getDefaultPriceImpact(OrderTypeEnum.MARKET)),
					sizeDelta: params.sizeDelta.toString(),
					leverage: notional.gt(0) ? availableMargin.div(notional).toNumber() : 0,
					notionalValue: notional.toString(),
					side: params.sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
					marketKey: market.marketKey,
					isConditional: !!params.isConditional,
					status: preview.requiredMargin.gt(tradeableMargin)
						? tradeableMargin.lt(MIN_MARGIN_AMOUNT_V3)
							? PotentialTradeStatus.INSUFFICIENT_FREE_MARGIN
							: PotentialTradeStatus.MAX_POSITION_SIZE_EXCEEDED
						: PotentialTradeStatus.OK,
				}
				dispatch(
					setTradePreview({
						preview: populatedPreview,
						provider: PerpsProvider.SNX_V3_BASE,
					})
				)
				dispatch(setQueryStatus({ key: queryKey, status: FetchStatus.Success }))
			} catch (err) {
				handleFetchError(dispatch, queryKey, err)
			}
		}
	}
)

export const clearTradeInputs = createAsyncThunk<void, void, ThunkConfig>(
	'futures/clearTradeInputs',
	async (_, { dispatch }) => {
		dispatch(clearSmartMarginTradeInputs())
		dispatch(clearCrossMarginTradeInputs())
	}
)

export const setTradeStopLossAction = createAsyncThunk<void, string, ThunkConfig>(
	'futures/setTradeStopLoss',
	async (price, { dispatch }) => {
		dispatch(setTradeStopLoss(price))
	}
)

export const editTradePanelOrderPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const type = selectPerpsProvider(getState())
		if (providerIsCrossMargin(type)) {
			dispatch(editCrossMarginTradeOrderPrice(price))
		} else {
			dispatch(editSmartMarginTradeOrderPrice(price))
		}
	}

export const editTradeSizeInput =
	(size: string, currencyType: 'usd' | 'native'): AppThunk =>
	(dispatch, getState) => {
		const type = selectPerpsProvider(getState())
		if (providerIsCrossMargin(type)) {
			dispatch(editCrossMarginTradeSize(size, currencyType))
		} else {
			dispatch(editIsolatedMarginTradeSize(size, currencyType))
		}
	}

export const fetchFuturesPositionHistory = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchFuturesPositionHistory',
	async (providers, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			const { wallet, network, accountId } = contexts[provider]
			if (!wallet || !accountId) continue

			try {
				if (provider === PerpsProvider.SNX_V3_BASE) {
					const history = await sdk.snxPerpsV3.getPositionHistory(
						accountId.toString(),
						network as SnxV3NetworkIds
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								network: network,
								provider: PerpsProvider.SNX_V3_BASE,
								account: accountId.toString(),
								positionHistory: serializePerpsV3PositionHistory(history),
							},
						})
					)
				} else if (provider === PerpsProvider.SNX_V2_OP) {
					const history = await sdk.snxPerpsV2.getPositionHistory(
						accountId,
						'account',
						network as SnxV2NetworkIds
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.SNX_V2_OP,
								positionHistory: serializeIsolatedPositionHistory(history),
								network,
								account: accountId,
							},
						})
					)
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					const history = await sdk.perennial.getPositionHistory({
						walletAddress: wallet,
						chainId: network as PerennialArbNetworkIds,
					})
					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.PERENNIAL_V2_ARB,
								positionHistory: serializeIsolatedPositionHistory(history),
								network,
								account: accountId,
							},
						})
					)
				}
				dispatch(
					setQueryStatus({
						key: 'get_trader_position_history',
						status: FetchStatus.Success,
						provider,
					})
				)
			} catch (err) {
				handleFetchError(dispatch, 'get_trader_position_history', err, { provider })
			}
		}
	}
)

export const fetchPositionHistoryForTrader = createAsyncThunk<void, string, ThunkConfig>(
	'futures/fetchPositionHistoryForTrader',
	async (traderAddress, { getState, dispatch, extra: { sdk } }) => {
		const perpsProvider = selectPerpsProvider(getState())
		const chainId = selectProviderNetworks(getState())[perpsProvider]
		try {
			dispatch(setQueryStatus({ key: 'get_trader_position_history', status: FetchStatus.Loading }))
			if (perpsProvider === PerpsProvider.SNX_V3_BASE) {
				const history = await sdk.snxPerpsV3.getPositionHistory(
					traderAddress,
					chainId as SnxV3NetworkIds,
					'eoa'
				)
				dispatch(
					setTraderHistory({
						provider: PerpsProvider.SNX_V3_BASE,
						positions: serializePerpsV3PositionHistory(history),
						traderAddress,
					})
				)
			}
			if (perpsProvider === PerpsProvider.SNX_V2_OP) {
				const history = await sdk.snxPerpsV2.getPositionHistory(
					traderAddress,
					'eoa',
					chainId as SnxV2NetworkIds
				)
				dispatch(
					setTraderHistory({
						provider: PerpsProvider.SNX_V2_OP,
						positions: serializeIsolatedPositionHistory(history),
						traderAddress,
					})
				)
			}
			dispatch(setQueryStatus({ key: 'get_trader_position_history', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_trader_position_history', err)
		}
	}
)

export const fetchTradesForPosition = createAsyncThunk<
	void,
	{ positionId: string; provider: PerpsProvider; asset: string }[],
	ThunkConfig
>('futures/fetchTradesForPosition', async (positions, { dispatch, getState, extra: { sdk } }) => {
	const contexts = selectAccountContexts(getState())
	const accountData = selectAccountData(getState())
	const perennialPositionHistory = selectPerennialPositionHistory(getState())
	const perennialAllPositions = selectPerennialActivePositions(getState())

	for (const { positionId, provider, asset } of positions) {
		const { wallet, network, accountId } = contexts[provider]
		if (!wallet || !accountId) continue

		dispatch(setQueryStatus({ key: 'get_trades_for_position', status: FetchStatus.Loading }))

		try {
			let trades: FuturesTrade[] = []
			if (provider === PerpsProvider.SNX_V2_OP) {
				trades = await sdk.snxPerpsV2.getTradeHistory({
					positionId,
					chainId: network as SnxV2NetworkIds,
				})
			} else if (provider === PerpsProvider.SNX_V3_BASE) {
				trades = await sdk.snxPerpsV3.getTradesForPosition(positionId, network as SnxV3NetworkIds)
			} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const historyPosition = perennialPositionHistory.find(
					(p) => p.id === positionId && p.asset === asset
				)
				if (historyPosition) {
					trades = await sdk.perennial.getTradesForPosition({
						positionId: BigInt(historyPosition.id),
						walletAddress: wallet,
						market: historyPosition.asset,
						first: 100,
					})
				} else {
					const pos = perennialAllPositions.find((p) => p.market.asset === positionId)
					if (pos) {
						trades = await sdk.perennial.getTradesForActivePosition({
							walletAddress: wallet,
							positionId: BigInt(pos.id),
							market: (pos.market as PerennialFuturesMarket).asset,
						})
					}
				}
			}
			const updatedTradesByPosition = cloneDeep(accountData?.tradesByPosition ?? {})
			updatedTradesByPosition[positionId] = serializeTrades(trades)

			dispatch(
				updateAccountData({
					wallet,
					data: {
						provider,
						tradesByPosition: updatedTradesByPosition,
						network,
						account: accountId,
					},
				})
			)
			dispatch(setQueryStatus({ key: 'get_trades_for_position', status: FetchStatus.Success }))
		} catch (err) {
			handleFetchError(dispatch, 'get_trades_for_position', err, { provider })
		}
	}
})

export const fetchDelegatesForAccount = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchDelegatesForAccount',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			const addressBook = selectAddressBook(getState())
			const { wallet, network, accountId } = contexts[provider]
			if (!wallet || !accountId) continue

			let delegates: DelegateChangeInfo[] = []
			try {
				dispatch(setQueryStatus({ key: 'get_delegates', provider, status: FetchStatus.Loading }))

				if (provider === PerpsProvider.SNX_V2_OP) {
					delegates = await sdk.snxPerpsV2.getDelegatesForAccount(
						wallet,
						network as SnxV2NetworkIds
					)
				} else if (provider === PerpsProvider.SNX_V3_BASE) {
					delegates = await sdk.snxPerpsV3.getDelegatesForAccount(
						wallet,
						network as SnxV3NetworkIds
					)
				}

				const addressMap = Object.fromEntries(
					addressBook.map((d) => [getAddress(d.address), d.nickname])
				)
				const delegatesWithNicknames = delegates.map(({ delegate }) => {
					const address = getAddress(delegate)
					const nickname = addressMap[address] || 'New Delegate'
					return { address, nickname }
				}) as DelegationAccountInfo[]

				dispatch(
					updateAccountData({
						wallet,
						data: {
							account: accountId,
							provider: provider,
							network,
							delegates: delegatesWithNicknames,
						},
					})
				)
				dispatch(setQueryStatus({ key: 'get_delegates', provider, status: FetchStatus.Success }))
			} catch (err) {
				handleFetchError(dispatch, 'get_delegates', err, { provider })
			}
		}
	}
)

export const fetchSubAccountsForAccount = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchSubAccountsForAccount',
	async (providers, { dispatch, getState, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())

		for (const provider of providers) {
			const addressBook = selectAddressBook(getState())
			const { wallet, network } = contexts[provider]

			if (!wallet) continue

			let subAccounts: DelegateChangeInfo[] = []

			try {
				dispatch(setQueryStatus({ key: 'get_sub_accounts', provider, status: FetchStatus.Loading }))

				if (provider === PerpsProvider.SNX_V2_OP) {
					subAccounts = await sdk.snxPerpsV2.getSubAccountsForAccount(
						wallet,
						network as SnxV2NetworkIds
					)
				} else if (provider === PerpsProvider.SNX_V3_BASE) {
					subAccounts = await sdk.snxPerpsV3.getSubAccountsForAccount(
						wallet,
						network as SnxV3NetworkIds
					)
				}

				const addressMap = Object.fromEntries(
					addressBook.map((d) => [getAddress(d.address), d.nickname])
				)
				const subAccountsWithNicknames = subAccounts.map(({ caller }) => {
					const address = getAddress(caller)
					const nickname = addressMap[address] || 'New Sub-Account'
					return { address, nickname }
				}) as DelegationAccountInfo[]
				dispatch(
					setSubAccountsForWallet({
						delegateeWallet: wallet,
						subAccounts: subAccountsWithNicknames,
					})
				)
				dispatch(setQueryStatus({ key: 'get_sub_accounts', status: FetchStatus.Success }))
			} catch (err) {
				handleFetchError(dispatch, 'get_sub_accounts', err)
			}
		}
	}
)

export const editTradeMarginDelta =
	(marginDelta: string): AppThunk =>
	(dispatch, getState) => {
		const { accountId } = selectAccountContext(getState())
		const marketInfo = selectMarketInfo(getState())
		const isConditional = selectIsConditionalOrder(getState())
		const orderPriceInput = selectTradePanelOrderPriceInput(getState())
		const orderPrice = selectTradePanelTradePrice(getState())
		const { stopLossPriceWei, takeProfitPriceWei } = selectTradePanelSlTpInputs(getState())

		const { susdSize, nativeSizeDelta, orderType } = selectTradePanelInputs(getState())

		if (!marketInfo) throw new Error('No market selected')
		if (marketInfo.marginType === FuturesMarginType.CROSS_MARGIN)
			throw new Error('Invalid market type')

		if (!marginDelta || Number(marginDelta) === 0) {
			dispatch(
				setTradeInputs({
					...ZERO_STATE_TRADE_INPUTS,
					orderType,
					orderPrice: { price: orderPriceInput },
				})
			)
			dispatch(setLeverageInput(''))
			dispatch(setMarginDelta(marginDelta))
			dispatch(clearTradePreview())
			return
		}

		const marginDelatWei = wei(marginDelta)
		const leverage = wei(susdSize).div(marginDelatWei.abs())

		dispatch(setMarginDelta(marginDelta))
		if (!leverage.eq(0)) {
			dispatch(setLeverageInput(leverage.toString(2)))
		}

		const tradeParams: IsolatedTradePreviewParams = {
			provider: marketInfo.provider,
			accountId,
			market: marketInfo,
			orderPrice: orderPrice,
			currentIndexPrice: selectMarketIndexPrice(getState()),
			marginDelta: wei(marginDelta || 0),
			sizeDelta: nativeSizeDelta,
			action: 'trade',
			isConditional,
			hasStopLoss: !!stopLossPriceWei,
			hasTakeProfit: !!takeProfitPriceWei,
		}

		dispatch(stageTradePreview(tradeParams))
	}

export const editIsolatedMarginTradeSize =
	(size: string, currencyType: 'usd' | 'native'): AppThunk =>
	(dispatch, getState) => {
		const { accountId } = selectAccountContext(getState())
		const indexPrice = selectMarketIndexPrice(getState())
		const marginDelta = selectIsolatedMarginMarginDelta(getState())
		const orderPrice = selectTradePanelOrderPriceInput(getState())
		const isConditional = selectIsConditionalOrder(getState())
		const orderType = selectTradeOrderType(getState())
		const tradeSide = selectLeverageSide(getState())
		const marketInfo = selectMarketInfo(getState())
		const price = selectTradePanelTradePrice(getState())
		const { stopLossPriceWei, takeProfitPriceWei } = selectTradePanelSlTpInputs(getState())

		if (!marketInfo) throw new Error('No market selected')
		if (marketInfo.marginType !== FuturesMarginType.ISOLATED_MARGIN)
			throw new Error('Invalid market type')

		if (size === '' || !price || price.eq(0)) {
			dispatch(
				setTradeInputs({ ...ZERO_STATE_TRADE_INPUTS, orderType, orderPrice: { price: orderPrice } })
			)
			dispatch(clearTradePreview())
			dispatch(setLeverageInput(''))
			return
		}

		const nativeSize =
			currencyType === 'native' ? size : String(floorNumber(wei(size).div(price), 4))
		const usdSize = currencyType === 'native' ? String(floorNumber(price.mul(size), 4)) : size
		const leverage = marginDelta?.gt(0) ? wei(usdSize).div(marginDelta.abs()) : '0'
		const sizeDeltaWei =
			tradeSide === PositionSide.LONG ? wei(nativeSize || 0) : wei(nativeSize || 0).neg()

		dispatch(
			setTradeInputs({
				orderType,
				susdSize: usdSize,
				nativeSize: nativeSize,
				orderPrice: {
					price: orderPrice,
					invalidLabel: orderPriceInvalidLabel(orderPrice, tradeSide, indexPrice, orderType),
				},
			})
		)
		dispatch(setLeverageInput(leverage.toString(2)))
		dispatch(
			stageTradePreview({
				provider: marketInfo.provider,
				accountId,
				market: marketInfo,
				orderPrice: price,
				currentIndexPrice: selectMarketIndexPrice(getState()),
				marginDelta: wei(marginDelta),
				sizeDelta: sizeDeltaWei,
				action: 'trade',
				isConditional,
				hasStopLoss: !!stopLossPriceWei,
				hasTakeProfit: !!takeProfitPriceWei,
			})
		)
	}
// Contract Mutations

export const createIsolatedMarginAccount = createAsyncThunk<
	void,
	{ provider: PerpsProvider },
	ThunkConfig
>(
	'futures/createIsolatedMarginAccount',
	async ({ provider }, { getState, dispatch, extra: { sdk }, rejectWithValue }) => {
		const wallet = selectWallet(getState())
		const existingAccounts = getState().futures.accounts[provider]
		try {
			if (!wallet) throw new Error('No wallet connected')

			// Already have an account fetched and persisted for this address
			if (existingAccounts?.[wallet]?.account) {
				notifyError('There is already an account associated with this wallet')
				rejectWithValue('Account already created')
			}

			// There are no concept of accounts in Perennial currently but
			// this maps to the pattern used by other providers

			if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const networkId = selectPerennialV2Network(getState())
				const approved = await sdk.perennial.checkMarketFactoryApproval(wallet)
				if (!approved) {
					const request = await sdk.perennial.approveMarketFactory(networkId)

					dispatch(
						setTransaction({
							status: TransactionStatus.AwaitingExecution,
							type: 'create_isolated_margin_account',
							hash: null,
						})
					)

					const txHash = await sdk.transactions.sendTransaction(request, networkId)
					await monitorAndAwaitTransaction(getState(), dispatch, txHash)
					dispatch(
						setAccount({
							account: wallet,
							wallet: wallet,
							provider: PerpsProvider.PERENNIAL_V2_ARB,
							networkId,
						})
					)
					dispatch(fetchAccountMarginInfo([provider]))
				}
			} else {
				const supportedNetwork = selectSignerSupportsSnxV2Op(getState())
				const networkId = selectSnxPerpsV2Network(getState())
				if (!supportedNetwork) throw new Error('Unsupported network')

				const accounts = await sdk.snxPerpsV2.getSmartMarginAccounts(wallet, networkId)
				// Check for existing account on the contract as only one account per user
				if (accounts[0]) {
					dispatch(
						setAccount({
							account: accounts[0],
							wallet: wallet,
							provider: PerpsProvider.SNX_V2_OP,
							networkId,
						})
					)
					return
				}

				dispatch(
					setTransaction({
						status: TransactionStatus.AwaitingExecution,
						type: 'create_isolated_margin_account',
						hash: null,
					})
				)
				const tx = await sdk.snxPerpsV2.createSmartMarginAccount(networkId)
				await monitorAndAwaitTransaction(getState(), dispatch, tx)
				await dispatch(fetchPerpsAccounts({ providers: [provider] }))
				dispatch(
					setOpenModalWithParams({
						type: 'futures_deposit_withdraw_isolated_margin',
						params: { tab: ManageModalType.Deposit },
					})
				)
			}
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const submitIsolatedMarginOrder = createAsyncThunk<
	void,
	{ overridePriceProtection: boolean; provider: PerpsProvider },
	ThunkConfig
>(
	'futures/submitIsolatedMarginOrder',
	async ({ overridePriceProtection, provider }, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const marketInfo = selectMarketInfo(state)
		const { wallet, accountId, network } = selectAccountContexts(state)[provider]
		const tradeInputs = selectTradePanelInputs(state)
		const orderType = selectTradeOrderType(state)
		const orderPrice = selectTradePanelOrderPriceInput(state)
		const marginDelta = selectIsolatedMarginMarginDelta(state)
		const preview = selectTradePreview(state)
		const keeperEthDeposit = selectTradePreviewKeeperDeposit(state)
		const position = selectPosition(state)
		const openDelayedOrders = selectIsolatedMarginDelayedOrders(state)
		const { stopLossPrice, takeProfitPrice } = selectTradePanelSlTpInputs(state)
		const isOneClickTrade = selectOneClickTradingSelected(state)

		try {
			if (!marketInfo) throw new Error('Market info not found')
			if (!accountId) throw new Error('No account found')
			if (!wallet) throw new Error('No wallet connected')
			if (!preview) throw new Error('Missing trade preview')
			if (!overridePriceProtection && preview.exceedsPriceProtection) {
				throw new Error('Price impact exceeds price protection')
			}

			const order = serializeTransactionOrder({
				marketAsset: marketInfo.asset,
				newSize: preview.newSize.abs(),
				sizeDelta: preview.sizeDelta.abs(),
				type: orderType,
				side: preview.side,
				price: preview.fillPrice,
			})

			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'submit_isolated_order',
					hash: null,
					order,
				})
			)

			const orderInputs: SmartMarginOrderInputs = {
				sizeDelta: tradeInputs.nativeSizeDelta,
				marginDelta: marginDelta,
				desiredFillPrice: preview.desiredFillPrice,
			}

			// To separate Stop Loss and Take Profit from other limit / stop orders
			// we set the size to max big num value.

			const maxSizeDelta = tradeInputs.nativeSizeDelta.gt(0) ? SL_TP_MAX_SIZE.neg() : SL_TP_MAX_SIZE

			if (Number(stopLossPrice) > 0) {
				const desiredSLFillPrice = calculateDesiredFillPrice(
					maxSizeDelta,
					wei(stopLossPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP_LOSS)
				)
				orderInputs.stopLoss = {
					price: wei(stopLossPrice),
					desiredFillPrice: desiredSLFillPrice,
					sizeDelta: tradeInputs.nativeSizeDelta.gt(0) ? SL_TP_MAX_SIZE.neg() : SL_TP_MAX_SIZE,
				}
			}

			if (Number(takeProfitPrice) > 0) {
				const desiredTPFillPrice = calculateDesiredFillPrice(
					maxSizeDelta,
					wei(takeProfitPrice || 0),
					wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.TAKE_PROFIT)
				)
				orderInputs.takeProfit = {
					price: wei(takeProfitPrice),
					desiredFillPrice: desiredTPFillPrice,
					sizeDelta: tradeInputs.nativeSizeDelta.gt(0) ? SL_TP_MAX_SIZE.neg() : SL_TP_MAX_SIZE,
				}
			}

			if (orderType !== OrderTypeEnum.MARKET) {
				orderInputs['conditionalOrderInputs'] = {
					orderType,
					price: wei(orderPrice || '0'),
					reduceOnly: false,
				}
			}

			if (
				orderType !== OrderTypeEnum.MARKET ||
				Number(takeProfitPrice) > 0 ||
				Number(stopLossPrice) > 0
			) {
				orderInputs.keeperEthDeposit = keeperEthDeposit
			}

			let existingSize = position?.details.size ?? wei(0)
			existingSize =
				position?.details.side === PositionSide.SHORT ? existingSize.neg() : existingSize
			const isClosing = existingSize.add(tradeInputs.nativeSizeDelta).eq(0)

			const staleOrder = openDelayedOrders.find(
				(o) => o.isStale && o.market.marketKey === marketInfo.marketKey
			)

			if (marketInfo.provider === PerpsProvider.SNX_V2_OP) {
				const { request } = await sdk.snxPerpsV2.submitSmartMarginOrder({
					market: { marketAddress: marketInfo.marketAddress, marketKey: marketInfo.marketKey },
					walletAddress: wallet,
					smAddress: accountId as Address,
					order: orderInputs,
					isOwner: false,
					chainId: network as SnxV2NetworkIds,
					options: {
						cancelPendingReduceOrders: {
							limit: !!orderInputs.stopLoss || (isClosing && orderType === OrderTypeEnum.MARKET),
							stop: !!orderInputs.takeProfit || (isClosing && orderType === OrderTypeEnum.MARKET),
						},
						cancelExpiredDelayedOrders: !!staleOrder,
					},
				})

				const callback = () => {
					dispatch(clearSmartMarginTradeInputs())
					dispatch(setOpenModal(null))
				}

				await dispatch(
					submitFuturesTransaction({
						request,
						onSuccess: callback,
						withKeeperCheck: true,
					})
				)
			} else if (marketInfo.provider === PerpsProvider.PERENNIAL_V2_ARB) {
				// Subtract what is already in the market if no existing position

				let perennialRequests: PerennialTxData[] = []

				// If opening a new position delete any old reduce only orders
				if (!position?.details) {
					const existingOrders = selectAllIsolatedConditionalOrders(getState()).perennial_v2_arb
					const removeOrders = existingOrders.filter((o) => {
						return o.marketKey === marketInfo.marketKey && o.size.lte(0)
					})
					const cancelOrderRequests = await Promise.all(
						removeOrders.map((o) => {
							return sdk.perennial.cancelConditionalOrder({
								walletAddress: wallet,
								orderId: String(o.id),
								marketAddress: marketInfo.marketAddress,
							})
						})
					)
					perennialRequests = cancelOrderRequests
				}

				if (orderInputs.stopLoss) {
					const stopLossRequest = await sdk.perennial.submitConditionalOrder({
						walletAddress: wallet,
						marketAddress: marketInfo.marketAddress,
						order: {
							orderType: OrderTypeEnum.STOP_LOSS,
							price: fromWei6(orderInputs.stopLoss.price),
							sizeDelta: fromWei6(SL_TP_SIZE_PERENNIAL),
							marginDelta: BigInt(0),
							direction: tradeInputs.nativeSizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
						},
					})
					if (stopLossRequest) perennialRequests.push(stopLossRequest)
				}

				if (orderInputs.takeProfit) {
					const takeProfitRequest = await sdk.perennial.submitConditionalOrder({
						walletAddress: wallet,
						marketAddress: marketInfo.marketAddress,
						order: {
							orderType: OrderTypeEnum.TAKE_PROFIT,
							price: fromWei6(orderInputs.takeProfit.price),
							sizeDelta: fromWei6(SL_TP_SIZE_PERENNIAL),
							marginDelta: BigInt(0),
							direction: tradeInputs.nativeSizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
						},
					})
					if (takeProfitRequest) perennialRequests.push(takeProfitRequest)
				}

				if (orderInputs.conditionalOrderInputs && orderType !== OrderTypeEnum.MARKET) {
					const orderRequest = await sdk.perennial.submitConditionalOrder({
						walletAddress: wallet,
						marketAddress: marketInfo.marketAddress,
						order: {
							orderType: orderType,
							price: fromWei6(wei(orderPrice)),
							sizeDelta: fromWei6(tradeInputs.nativeSizeDelta.abs()),
							marginDelta: fromWei6(marginDelta),
							direction: tradeInputs.nativeSizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
						},
						isOneClickTrade,
					})
					if (orderRequest) perennialRequests.push(orderRequest)
				} else {
					const tradeRequest = await sdk.perennial.modifyPosition({
						marketAddress: marketInfo.marketAddress,
						walletAddress: wallet,
						chainId: network as PerennialArbNetworkIds,
						order: {
							sizeDelta: fromWei6(tradeInputs.nativeSizeDelta.abs()),
							marginDelta: fromWei6(marginDelta),
							direction: tradeInputs.nativeSizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
						},
						isOneClickTrade,
					})
					if (tradeRequest) perennialRequests.push(tradeRequest)
				}

				if (perennialRequests.length) {
					const request = sdk.perennial.batchTransactions(perennialRequests)

					const callback = () => {
						dispatch(clearSmartMarginTradeInputs())
						dispatch(setOpenModal(null))
					}

					await dispatch(
						submitFuturesTransaction({
							request,
							onSuccess: callback,
							withKeeperCheck: true,
						})
					)
				}
			}

			dispatch(fetchAllOpenOrders([marketInfo.provider]))
			dispatch(setOpenModal(null))
			dispatch(fetchBalances())
			dispatch(fetchAccountMarginInfo([marketInfo.provider]))
			dispatch(clearSmartMarginTradeInputs())
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export const cancelDelayedOrder = createAsyncThunk<void, string, ThunkConfig>(
	'futures/cancelDelayedOrder',
	async (marketAddress, { getState, dispatch, extra: { sdk } }) => {
		const account = selectSnxV2Account(getState())
		if (!account) throw new Error('No wallet connected')
		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'cancel_delayed_isolated',
					hash: null,
				})
			)

			const tx = await sdk.snxPerpsV2.cancelDelayedOrder({
				marketAddress: marketAddress as Address,
				account,
				isOffchain: true,
			})
			await monitorAndAwaitTransaction(getState(), dispatch, tx)
			dispatch(fetchPendingMarketOrders([PerpsProvider.SNX_V2_OP]))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			throw err
		}
	}
)

export type CancelCondOrdersSnxV3Params = {
	provider: PerpsProvider.SNX_V3_BASE
	orderIds: number[]
}
export type CancelCondOrdersIsolatedParams = {
	provider: PerpsProvider.SNX_V2_OP | PerpsProvider.PERENNIAL_V2_ARB
	orderId: number
	marketAsset: FuturesMarketAsset
}

export const cancelConditionalOrder = createAsyncThunk<
	void,
	CancelCondOrdersSnxV3Params | CancelCondOrdersIsolatedParams,
	ThunkConfig
>('futures/cancelConditionalOrder', async (params, { getState, dispatch, extra: { sdk } }) => {
	const state = getState()
	const { accountId, network, wallet } = selectAccountContext(state)

	try {
		if (!accountId || !wallet) throw new Error('No account data')

		if (params.provider === PerpsProvider.SNX_V3_BASE) {
			await sdk.snxPerpsV3.cancelConditionalOrders(params.orderIds)
			dispatch(fetchOpenConditionalOrders([PerpsProvider.SNX_V3_BASE]))
		} else {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'cancel_cross_margin_order',
					hash: null,
				})
			)

			let request
			if (params.provider === PerpsProvider.SNX_V2_OP) {
				const tx = await sdk.snxPerpsV2.cancelConditionalOrder({
					account: accountId as Address,
					orderId: Number(params.orderId),
					chainId: network as SnxV2NetworkIds,
				})
				request = simulatedRequestToTxRequest(tx.request)
			} else if (params.provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const market = selectPerennialMarkets(state).find((m) => m.asset === params.marketAsset)
				if (!market) throw new Error('Market not found')
				request = await sdk.perennial.cancelConditionalOrder({
					walletAddress: wallet,
					marketAddress: market.marketAddress,
					chainId: network as PerennialArbNetworkIds,
					orderId: params.orderId.toString(),
				})

				const requests = [request]

				const orders = selectPerennialAccountData(state)?.conditionalOrders ?? []
				const order = orders.find((o) => o.id === params.orderId)

				// Withdraw any linked margin delta once the limit order has been cancelled

				if (order?.marginDelta && Number(order.marginDelta) > 0) {
					const { totalMarginByMarket } = selectIsolatedBalanceInfo(getState())
					const total = totalMarginByMarket[market.asset]
					const amount = total?.gte(wei(order.marginDelta).abs()) ? wei(order.marginDelta) : wei(0)

					if (amount?.abs().gt(0)) {
						const withdrawMarginReq = await sdk.perennial.modifyPosition({
							walletAddress: wallet,
							chainId: network as PerennialArbNetworkIds,
							marketAddress: market.marketAddress,
							order: {
								sizeDelta: BigInt(0),
								marginDelta: fromWei6(amount.neg()),
								direction: PositionSide.LONG,
							},
						})
						requests.splice(0, 0, withdrawMarginReq)
					}
				}

				request = sdk.perennial.batchTransactions(requests)
			}

			if (!request) return

			dispatch(setCancellingConditionalOrder(params.orderId))
			await dispatch(submitFuturesTransaction({ request }))

			dispatch(setCancellingConditionalOrder(undefined))
			dispatch(setOpenModal(null))
			dispatch(fetchAllOpenOrders([params.provider]))
		}
	} catch (err) {
		logError(err)
		dispatch(setCancellingConditionalOrder(undefined))
		dispatch(handleTransactionError({ message: err.message }))
		throw err
	}
})

// One-Click Trading
export const delegateToOneClickTrading = createAsyncThunk<void, void, ThunkConfig>(
	'futures/delegateToOneClickTrading',
	async (_, { getState, dispatch, extra: { sdk, accountAbstractionFactory } }) => {
		const smAccount = selectSnxV2Account(getState())
		const accountId = selectSnxV3Account(getState())
		const { network, provider, wallet } = selectAccountContext(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const address = accountAbstraction?.accountAddress

		try {
			if (!wallet) throw new Error('No wallet connected')
			if (!address) throw new Error('No abstraction address connected')
			if (!smAccount && provider === PerpsProvider.SNX_V2_OP)
				throw new Error('No smart margin account connected')
			if (!accountId && provider === PerpsProvider.SNX_V3_BASE)
				throw new Error('No cross margin account connected')
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'delegate_to_smart_margin',
					hash: null,
				})
			)

			let txHash: Hash
			if (provider === PerpsProvider.SNX_V2_OP) {
				const { request } = await sdk.snxPerpsV2.delegateSmartMarginAccount({
					account: smAccount!,
					delegate: address,
					chainId: network as SnxV2NetworkIds,
				})
				txHash = await sdk.transactions.writeContract(request as WriteContractParameters, network)
			} else if (provider === PerpsProvider.SNX_V3_BASE) {
				const { request } = await sdk.snxPerpsV3.grantDelegatePermission(
					accountId!,
					address,
					network as SnxV3NetworkIds
				)
				txHash = await sdk.transactions.writeContract(request as WriteContractParameters, network)
			} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
				const addOperatorTx = await sdk.perennial.addOperator(
					wallet,
					accountAbstraction.accountAddress!,
					network as PerennialArbNetworkIds
				)

				txHash = await sdk.transactions.sendTransaction(addOperatorTx, network)
			} else {
				throw new Error('Unsupported provider')
			}
			await monitorAndAwaitTransaction(getState(), dispatch, txHash)
			dispatch(setDelegated(true))
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
			dispatch(fetchAbstractionDelegateStatus())
		}
	}
)

export const startOneClickSession = createAsyncThunk<void, DateInterval | number, ThunkConfig>(
	'futures/delegateToOneClickTrading',
	async (interval, { getState, dispatch, extra: { sdk, accountAbstractionFactory } }) => {
		const smAccount = selectSnxV2Account(getState())
		const { provider, network } = selectAccountContext(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const address = accountAbstraction?.accountAddress
		const accountId = selectSnxV3Account(getState())
		const { allowance } = selectIsolatedBalanceInfo(getState())
		// TODO: Change to pass network
		const engineAddress = sdk.context.contractConfigs[network]?.snxV3.MarginEngine?.address

		if (!address) throw new Error('No abstraction address connected')
		if (!smAccount && provider === PerpsProvider.SNX_V2_OP)
			throw new Error('No smart margin account connected')
		if ((!accountId || !engineAddress) && provider === PerpsProvider.SNX_V3_BASE)
			throw new Error('No cross margin account connected')

		const sessionAddress =
			provider === PerpsProvider.SNX_V3_BASE
				? engineAddress
				: provider === PerpsProvider.SNX_V2_OP
				? smAccount
				: undefined

		try {
			if (provider === PerpsProvider.PERENNIAL_V2_ARB && allowance.lt(maxUint128)) {
				await dispatch(approveIsolatedMargin(wei(maxUint256)))
			}

			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'create_session',
					hash: null,
				})
			)

			const session = await accountAbstraction.createSession(interval, sessionAddress!)

			dispatch(setSession(session))
			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const closeOneClickSession = createAsyncThunk<void, void, ThunkConfig>(
	'futures/delegateToOneClickTrading',
	async (_, { getState, dispatch, extra: { accountAbstractionFactory } }) => {
		const { provider } = selectAccountContext(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const address = accountAbstraction?.accountAddress

		if (!address) throw new Error('No abstraction address connected')

		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'close_session',
					hash: null,
				})
			)

			const session = await accountAbstraction.closeAllSessions()

			dispatch(setSession(session))
			dispatch(updateTransactionStatus(TransactionStatus.Confirmed))
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const depositFundsToOneClick = createAsyncThunk<
	void,
	{ amount: string; token: AbstractionToken },
	ThunkConfig
>(
	'futures/depositFundsToOneClick',
	async ({ amount, token }, { getState, dispatch, extra: { sdk } }) => {
		const state = getState()
		const network = selectSignerNetwork(state)
		const abstractionAddress = selectAbstractionAddress(state)

		const walletClient = sdk.context.walletClient

		if (!walletClient || !abstractionAddress || !network) throw new Error('No account connected')

		try {
			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'deposit_one_click',
					hash: null,
				})
			)

			if (token === AbstractionToken.ETH) {
				const tx = await walletClient.sendTransaction({
					to: abstractionAddress,
					value: parseEther(amount),
				})

				await monitorAndAwaitTransaction(getState(), dispatch, tx)
			} else if (token === AbstractionToken.USDC) {
				const tokenAddr = COMMON_ADDRESSES[AbstractionToken.USDC][network]
				if (!tokenAddr) throw Error('Unsupported Signer Network')
				const request = await sdk.tokens.transferToken({
					address: tokenAddr,
					to: abstractionAddress,
					amount: parseUnits(amount, usdcDecimals(network)),
					chainId: network,
				})

				const tx = await walletClient.writeContract(request)

				await monitorAndAwaitTransaction(getState(), dispatch, tx)
			}
			dispatch(fetchAbstractionBalance())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const withdrawFundsFromOneClick = createAsyncThunk<
	void,
	{ amount: string; token: AbstractionToken },
	ThunkConfig
>(
	'futures/withdrawFundsFromOneClick',
	async ({ amount, token }, { getState, dispatch, extra: { sdk, accountAbstractionFactory } }) => {
		const { provider } = selectAccountContext(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const accountAddress = selectWallet(getState())
		const abstractionAddress = selectAbstractionAddress(getState())
		const network = selectSignerNetwork(getState())
		const walletClient = sdk.context.walletClient

		try {
			if (
				!walletClient ||
				!accountAbstraction ||
				!abstractionAddress ||
				!accountAddress ||
				!network
			)
				throw new Error('No account connected')

			dispatch(
				setTransaction({
					status: TransactionStatus.AwaitingExecution,
					type: 'withdraw_one_click',
					hash: null,
				})
			)

			if (token === AbstractionToken.ETH) {
				const tx = {
					to: accountAddress,
					value: parseEther(amount),
				}

				const res = await accountAbstraction.sendTransactions({
					readyTxs: [tx],
					options: {},
				})

				if (typeof res === 'string' && isHash(res)) {
					await monitorAndAwaitTransaction(getState(), dispatch, res)
				} else {
					await monitorAndAwaitUserOp(dispatch, res)
				}
				dispatch(fetchWalletEthBalance())
			} else if (token === AbstractionToken.USDC) {
				const tokenAddr = COMMON_ADDRESSES[AbstractionToken.USDC][network]
				if (!tokenAddr) throw Error('Unsupported Signer Network')
				const request = await sdk.tokens.transferToken({
					address: tokenAddr,
					to: accountAddress,
					amount: parseUnits(amount, usdcDecimals(network)),
					signer: abstractionAddress,
					chainId: network,
				})

				const tx = await accountAbstraction.sendTransactions({
					simulateTxs: [request],
					options: {},
				})

				if (typeof tx === 'string' && isHash(tx)) {
					await monitorAndAwaitTransaction(getState(), dispatch, tx)
				} else {
					await monitorAndAwaitUserOp(dispatch, tx)
				}
			}

			dispatch(fetchAbstractionBalance())
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const initAccountAbstraction = createAsyncThunk<void, WalletClient | undefined, ThunkConfig>(
	'futures/initAccountAbstraction',
	async (client, { dispatch, getState, extra: { sdk, accountAbstractionFactory } }) => {
		const { provider, network } = selectAccountContext(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		const publicClient = sdk.context.clients[network]

		if (!accountAbstraction || !publicClient) return
		if (client) {
			const isPaymasterEnabled = provider === PerpsProvider.SNX_V3_BASE
			await accountAbstraction.init(client, publicClient, isPaymasterEnabled)

			const { accountAddress } = accountAbstraction

			if (!accountAddress) return

			const session = await accountAbstraction.getSessionInfo()

			dispatch(setSession(session))
			dispatch(setAbstractionAddress(accountAddress))
		} else {
			accountAbstraction.disconnect()
		}
	}
)

export const fetchAbstractionDelegateStatus = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchAbstractionDelegateStatus',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const abstractionAddress = selectAbstractionAddress(getState())
		const smAddress = selectSnxV2Account(getState())
		const accountId = selectSnxV3Account(getState())

		const { wallet, network, provider } = selectAccountContext(getState())

		if (!wallet) throw new Error('No wallet connected')
		if (!abstractionAddress) throw new Error('No abstraction address connected')
		if (!smAddress && provider === PerpsProvider.SNX_V2_OP)
			throw new Error('No smart margin account connected')
		if (!accountId && provider === PerpsProvider.SNX_V3_BASE)
			throw new Error('No cross margin account connected')

		let isDelegate = false

		if (provider === PerpsProvider.SNX_V2_OP) {
			isDelegate = await sdk.snxPerpsV2.checkDelegateSmartMarginAccount({
				account: smAddress!,
				delegate: abstractionAddress,
				chainId: network as SnxV2NetworkIds,
			})
		} else if (provider === PerpsProvider.SNX_V3_BASE) {
			isDelegate = await sdk.snxPerpsV3.checkDelegatePermission(
				accountId!,
				abstractionAddress,
				network as SnxV3NetworkIds
			)
		} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
			isDelegate = await sdk.perennial.checkOperatorApproval(
				wallet,
				abstractionAddress,
				network as PerennialArbNetworkIds
			)
		}

		dispatch(setDelegated(isDelegate))
	}
)

export const handleOneClickOperation = createAsyncThunk<void, VoidFunction, ThunkConfig>(
	'futures/handleOneClickOperation',
	async (callback, { getState, dispatch }) => {
		const tradingMode = selectTradingMode(getState())
		const isSessionActive = selectSessionExpiry(getState()) > Math.floor(Date.now() / 1000)
		const isDelegated = selectAbstractionDelegated(getState())

		const isNeedActivate = !isSessionActive && tradingMode === TradingModes.ONE_CLICK

		try {
			if (IS_ONE_CLICK_TRADING_ENABLED) {
				if (isNeedActivate) {
					if (isDelegated) {
						await dispatch(startOneClickSession('1D'))
					} else {
						dispatch(setOpenModal('one_click_trading_onboard'))
						return
					}
				}
			}
			return callback()
		} catch (err) {
			dispatch(handleTransactionError({ message: err.message }))
		}
	}
)

export const fetchAbstractionBalance = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchAbstractionBalance',
	async (_, { dispatch, getState, extra: { accountAbstractionFactory } }) => {
		const { provider, network } = selectAccountContext(getState())
		const usedToken = selectAbstractionUsedToken(getState())
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		if (!accountAbstraction) return
		const isUSDC = usedToken === AbstractionToken.USDC

		if (isUSDC) {
			const balance = await accountAbstraction.getUsdcBalance()

			const decimals = usdcDecimals(network)

			dispatch(setAbstractionUsdcBalance(formatUnits(balance, decimals)))
		} else {
			const balance = await accountAbstraction.getBalance()

			dispatch(setAbstractionEthBalance(formatEther(balance)))
		}
	}
)

export const editPositionSize =
	(nativeSizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		const type = selectPerpsProvider(getState())
		dispatch(
			setEditPositionInputs({
				marginDelta: '',
				nativeSizeDelta: nativeSizeDelta,
			})
		)
		if (providerIsCrossMargin(type)) {
			dispatch(editCrossMarginPositionSize(nativeSizeDelta))
		} else {
			dispatch(editIsolatedMarginPositionSize(nativeSizeDelta))
		}
	}

export const editClosePositionPrice =
	(price: string): AppThunk =>
	(dispatch, getState) => {
		const type = selectPerpsProvider(getState())
		if (providerIsCrossMargin(type)) {
			dispatch(editCloseCMPositionPrice(price))
		} else {
			dispatch(editCloseIsolatedPositionPrice(price))
		}
	}

export const editClosePositionSizeDelta =
	(sizeDelta: string): AppThunk =>
	(dispatch, getState) => {
		const type = selectPerpsProvider(getState())
		if (providerIsCrossMargin(type)) {
			dispatch(editCrossMarginCloseAmount(sizeDelta))
		} else {
			dispatch(editCloseIsolatedPositionSizeDelta(sizeDelta))
		}
	}

export const changeLeverageSide =
	(side: PositionSide): AppThunk =>
	(dispatch) => {
		dispatch(setLeverageSide(side))
	}

export const editConditionalOrder =
	(order: ConditionOrderTableItem): AppThunk =>
	(dispatch, getState) => {
		const type = selectPerpsProvider(getState())
		dispatch(
			setEditConditonalOrderInputs({
				orderId: order.id,
				orderPrice: {
					price: order.targetPrice.toString(),
					invalidLabel: undefined,
				},
				size: order.size.toString(),
				margin: order.marginDelta.toString(),
			})
		)
		if (providerIsCrossMargin(type)) {
			dispatch(
				editCrossMarginConditionalOrder(
					order,
					order.size.abs().toString(),
					order.targetPrice.toString()
				)
			)
		} else {
			dispatch(
				editIsolatedMarginConditionalOrder(
					order,
					order.size.abs().toString(),
					order.targetPrice.toString(),
					order.marginDelta.toString()
				)
			)
		}
	}

export const fetchAvgOneClickTxCost = createAsyncThunk<void, void, ThunkConfig>(
	'futures/fetchAvgOneClickTxConst',
	async (_, { dispatch, getState, extra: { sdk, accountAbstractionFactory } }) => {
		const { provider } = selectAccountContext(getState())
		const usedToken = selectAbstractionUsedToken(getState())

		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)
		if (!accountAbstraction) return

		const avgGas = await accountAbstraction.estimateAvgTxCost()

		if (usedToken === AbstractionToken.USDC) {
			try {
				const ethPrice = sdk.prices.getOffchainPrice(FuturesMarketAsset.sETH)
				const txCost = ethPrice.mul(avgGas).toNumber()
				dispatch(setAbstractionTxCost(txCost.toFixed(3)))
			} catch (e) {
				logError(e)
				dispatch(setAbstractionTxCost(EST_TRADE_TX_COST_USDC.toString()))
			}
		} else {
			dispatch(setAbstractionTxCost(wei(avgGas).toString()))
		}
	}
)
