import { UserOpResponse } from '@biconomy/account'
import {
	PerennialArbNetworkIds,
	PerpsProvider,
	SimulatedRequest,
	SnxV2NetworkIds,
	SnxV3NetworkIds,
} from '@kwenta/sdk/types'
import { isSimulatedRequest, simulatedRequestToTxRequest, toWei6 } from '@kwenta/sdk/utils'
import { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Transaction } from 'types/accountAbstraction'
import { Address, Hash, isHash } from 'viem'

import { monitorAndAwaitTransaction, monitorAndAwaitUserOp } from 'state/app/helpers'
import { handleTransactionError } from 'state/app/reducer'
import { handleFetchError } from 'state/helpers'
import { FetchStatus, ThunkConfig } from 'state/types'
import { serializeIsolatedMarginBalanceInfo } from 'utils/futures'
import logError from 'utils/logError'

import { setQueryStatus, updateAccountData } from '../reducer'
import {
	selectAccountContext,
	selectAccountContexts,
	selectKeeperDepositExceedsAbstractionBal,
} from '../selectors'

import { selectOneClickTradingSelected } from './selectors'

export const fetchAccountMarginInfo = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchAccountMarginInfo',
	async (providers, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())
		for (const provider of providers) {
			try {
				const { network: networkId, accountId, wallet } = contexts[provider]
				if (!accountId || !networkId || !wallet) continue

				dispatch(setQueryStatus({ key: 'get_balance_info', status: FetchStatus.Loading, provider }))
				if (provider === PerpsProvider.SNX_V3_BASE) {
					const {
						availableMargin,
						withdrawableMargin,
						requiredInitialMargin,
						requiredMaintenanceMargin,
						maxLiquidationReward,
					} = await sdk.snxPerpsV3.getAccountMarginInfo(
						BigInt(accountId),
						networkId as SnxV3NetworkIds
					)
					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.SNX_V3_BASE,
								account: accountId,
								network: networkId,
								marginInfo: {
									availableMargin: availableMargin.toString(),
									withdrawableMargin: withdrawableMargin.toString(),
									requiredInitialMargin: requiredInitialMargin.toString(),
									requiredMaintenanceMargin: requiredMaintenanceMargin.toString(),
									maxLiquidationReward: maxLiquidationReward.toString(),
								},
							},
						})
					)
				} else if (provider === PerpsProvider.SNX_V2_OP) {
					const balanceInfo = await sdk.snxPerpsV2.getSmartMarginBalanceInfo(
						wallet,
						accountId as Address,
						networkId as SnxV2NetworkIds
					)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.SNX_V2_OP,
								balanceInfo: serializeIsolatedMarginBalanceInfo({
									...balanceInfo,
									idleMarginByMarket: {},
									totalMarginByMarket: {},
								}),
								network: networkId,
								account: accountId,
							},
						})
					)
				} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
					const balanceInfo = await sdk.perennial.getBalanceInfo(
						wallet,
						networkId as PerennialArbNetworkIds
					)

					dispatch(
						updateAccountData({
							wallet,
							data: {
								provider: PerpsProvider.PERENNIAL_V2_ARB,
								balanceInfo: serializeIsolatedMarginBalanceInfo({
									freeMargin: toWei6(balanceInfo.usdcBalance),
									keeperEthBal: wei(0),
									walletEthBal: wei(0),
									factoryApproved: balanceInfo.factoryApproved,
									allowance: toWei6(balanceInfo.allowance),
									balances: { SUSD: wei(0), USDC: toWei6(balanceInfo.usdcBalance), DAI: wei(0) },
									allowances: { SUSD: wei(0), USDC: toWei6(balanceInfo.allowance), DAI: wei(0) },
									idleMarginByMarket: balanceInfo.idleMarginByMarket,
									totalMarginByMarket: balanceInfo.totalMarginByMarket,
								}),
								network: networkId,
								account: accountId,
							},
						})
					)
				}

				dispatch(setQueryStatus({ key: 'get_balance_info', status: FetchStatus.Success, provider }))
			} catch (err) {
				handleFetchError(dispatch, 'get_balance_info', err, { provider })
			}
		}
	}
)

export const submitFuturesTransaction = createAsyncThunk<
	void,
	{
		request: Transaction | SimulatedRequest
		onSuccess?: VoidFunction
		onError?: (err: Error) => void
		withKeeperCheck?: boolean
	},
	ThunkConfig
>(
	'futures/submitFuturesTransaction',
	async (
		{ request, onSuccess, onError, withKeeperCheck = false },
		{ dispatch, getState, extra: { sdk, accountAbstractionFactory } }
	) => {
		const state = getState()
		const { provider, network } = selectAccountContext(state)
		const isOneClickReady = selectOneClickTradingSelected(state)
		const isKeeperDepositExceedsAbstractionBal = selectKeeperDepositExceedsAbstractionBal(state)
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)

		const isUsingOneClick = Boolean(
			accountAbstraction &&
				isOneClickReady &&
				(!withKeeperCheck || !isKeeperDepositExceedsAbstractionBal)
		)

		const isSimulatedAction = isSimulatedRequest(request)

		let res: Hash | UserOpResponse

		try {
			if (isUsingOneClick) {
				res = await accountAbstraction!.sendTransactions({
					simulateTxs: isSimulatedAction ? [request] : [],
					readyTxs: isSimulatedAction ? [] : [request],
				})
			} else {
				res = await sdk.transactions.sendTransaction(
					isSimulatedAction ? simulatedRequestToTxRequest(request) : request,
					network
				)
			}

			if (typeof res === 'string' && isHash(res)) {
				await monitorAndAwaitTransaction(state, dispatch, res)
			} else {
				await monitorAndAwaitUserOp(dispatch, res)
			}
			onSuccess?.()
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			onError?.(err)
		}
	}
)
