import { wei } from '@kwenta/wei';
import orderBy from 'lodash/orderBy';
import * as sdkErrors from '../constants/errors';
import { ZERO_WEI } from '../constants/number';
import { notNill } from '../utils/general';
import { hexToString } from 'viem';
export default class SynthsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    /**
     * @desc Get synth balances for a given wallet address
     * @param walletAddress Wallet address
     * @returns Synth balances for the given wallet address
     */
    async getSynthBalances(walletAddress, chainId) {
        const SynthUtil = this.sdk.context.contractConfigs[chainId]?.common.SynthUtil;
        if (!SynthUtil)
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        const balancesMap = {};
        const [currencyKeys, synthsBalances, synthsUSDBalances] = await this.sdk.context
            .publicClient(chainId)
            .readContract({
            ...SynthUtil,
            functionName: 'synthsBalances',
            args: [walletAddress],
        });
        let totalUSDBalance = wei(0);
        currencyKeys.forEach((currencyKeyBytes32, i) => {
            const balance = wei(synthsBalances[i]);
            if (balance.gt(0)) {
                const synthName = hexToString(currencyKeyBytes32, { size: 32 });
                const usdBalance = wei(synthsUSDBalances[i]);
                balancesMap[synthName] = { currencyKey: synthName, balance, usdBalance };
                totalUSDBalance = totalUSDBalance.add(usdBalance);
            }
        });
        const balances = {
            balancesMap,
            balances: orderBy(Object.values(balancesMap).filter(notNill), (balance) => balance.usdBalance.toNumber(), 'desc'),
            totalUSDBalance,
            susdWalletBalance: balancesMap?.['sUSD']?.balance ?? ZERO_WEI,
        };
        return balances;
    }
    async getSynthV3Balances(walletAddress, chainId) {
        const SNX_USD = this.sdk.context.contractConfigs[chainId]?.snxV3.SNX_USD;
        if (!SNX_USD)
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        const balance = await this.sdk.context.publicClient(chainId).readContract({
            ...SNX_USD,
            functionName: 'balanceOf',
            args: [walletAddress],
        });
        return { SNX_USD: wei(balance) };
    }
    async getSynthV3BalancesAndAllowances(walletAddress, spenders, chainId) {
        const { SNX_USD, SNX_USDC } = this.sdk.context.contractConfigs[chainId]?.snxV3 ?? {};
        if (!SNX_USD || !SNX_USDC)
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        const [susdBal, usdcBal, ...allowances] = (await this.sdk.context
            .publicClient(chainId)
            .multicall({
            allowFailure: false,
            contracts: [
                {
                    ...SNX_USD,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...SNX_USDC,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                ...spenders.map((s) => ({
                    ...SNX_USD,
                    functionName: 'allowance',
                    args: [walletAddress, s],
                })),
                ...spenders.map((s) => ({
                    ...SNX_USDC,
                    functionName: 'allowance',
                    args: [walletAddress, s],
                })),
            ],
        }));
        const usdAllowances = allowances.slice(0, spenders.length);
        const usdcAllowance = allowances.slice(spenders.length, spenders.length * 2);
        return {
            SNX_USD: {
                balance: wei(susdBal),
                allowances: usdAllowances.reduce((acc, a, i) => {
                    acc[spenders[i]] = wei(a);
                    return acc;
                }, {}),
            },
            SNX_USDC: {
                balance: wei(usdcBal),
                allowances: usdcAllowance.reduce((acc, a, i) => {
                    acc[spenders[i]] = wei(a);
                    return acc;
                }, {}),
            },
        };
    }
}
