import { wei } from '@kwenta/wei';
import { KWENTA_TRACKING_CODE, ZERO_WEI, v3PerpsMarketIdToAssetKey } from '../constants';
import { OrderTypeEnum, PerpsProvider, PositionSide, RawCondition, SnxV3NetworkIds, } from '../types';
import { encodeFunctionData, maxInt128 } from 'viem';
import MarginEngineAbi from '../contracts/abis/MarginEngine.js';
import { formatOrderDisplayType, getDisplayAsset } from './futures';
import { weiFromWei } from './number';
export const serializeConditionalOrder = (order) => {
    return {
        orderDetails: {
            marketId: Number(order.orderDetails.marketId.toString()),
            networkId: Number(order.orderDetails.networkId.toString()),
            accountId: order.orderDetails.accountId.toString(),
            sizeDelta: order.orderDetails.sizeDelta.toString(),
            settlementStrategyId: Number(order.orderDetails.settlementStrategyId.toString()),
            acceptablePrice: order.orderDetails.acceptablePrice.toString(),
            isReduceOnly: order.orderDetails.isReduceOnly,
            trackingCode: order.orderDetails.trackingCode,
            referrer: order.orderDetails.referrer,
        },
        signer: order.signer,
        nonce: Number(order.nonce.toString()),
        requireVerified: order.requireVerified,
        trustedExecutor: order.trustedExecutor,
        maxExecutorFee: order.maxExecutorFee.toString(),
        conditions: order.conditions,
    };
};
export const parseConditionArgs = (orderDetails, condition, value) => {
    switch (condition) {
        case RawCondition.IsTimestampAfter:
        case RawCondition.IsTimestampBefore:
            return [value];
        case RawCondition.IsPriceAbove:
        case RawCondition.IsPriceBelow:
            return [KWENTA_TRACKING_CODE, value, BigInt(0)]; // Update this
        case RawCondition.IsPositionSizeAbove:
        case RawCondition.IsPositionSizeBelow:
            return [orderDetails.accountId, orderDetails.marketId, orderDetails.sizeDelta];
        case RawCondition.IsOrderFeeBelow:
            return [orderDetails.marketId, orderDetails.sizeDelta, BigInt(0)];
        case RawCondition.IsMarketOpen:
            return [];
        default:
            throw new Error(`Invalid condition: ${condition}`);
    }
};
export const orderConditionsToCallData = (orderDetails, rawConditions) => {
    return Object.entries(rawConditions).map(([condition, value]) => {
        return encodeFunctionData({
            abi: MarginEngineAbi,
            functionName: condition,
            args: parseConditionArgs(orderDetails, condition, value),
        });
    });
};
export const calculatePerpsV3Volumes = (futuresHourlyStats) => {
    const volumes = futuresHourlyStats.reduce((acc, { marketSymbol, volume, trades }) => {
        return {
            ...acc,
            [marketSymbol]: {
                volume: weiFromWei(volume).add(acc[marketSymbol]?.volume ?? 0),
                trades: weiFromWei(trades).add(acc[marketSymbol]?.trades ?? 0),
            },
        };
    }, {});
    return volumes;
};
export const orderTypeFromCondition = (positionSide, order) => {
    if (!positionSide)
        return OrderTypeEnum.LIMIT;
    if (positionSide === PositionSide.LONG) {
        if (Number(order.orderDetails.sizeDelta) > 0 && order.decodedConditions.isPriceBelow) {
            return OrderTypeEnum.LIMIT;
        }
        else if (Number(order.orderDetails.sizeDelta) < 0 && order.decodedConditions.isPriceAbove) {
            return OrderTypeEnum.TAKE_PROFIT;
        }
        else if (Number(order.orderDetails.sizeDelta) < 0 && order.decodedConditions.isPriceBelow) {
            return OrderTypeEnum.STOP_LOSS;
        }
        else {
            return OrderTypeEnum.STOP;
        }
    }
    else {
        if (Number(order.orderDetails.sizeDelta) < 0 && order.decodedConditions.isPriceAbove) {
            return OrderTypeEnum.LIMIT;
        }
        else if (Number(order.orderDetails.sizeDelta) > 0 && order.decodedConditions.isPriceBelow) {
            return OrderTypeEnum.TAKE_PROFIT;
        }
        else if (Number(order.orderDetails.sizeDelta) > 0 && order.decodedConditions.isPriceAbove) {
            return OrderTypeEnum.STOP_LOSS;
        }
        else {
            return OrderTypeEnum.STOP;
        }
    }
};
export const reconcileOrders = (ordersSettled, conditionalOrders) => {
    const filledOrders = ordersSettled.map((orderSettled) => {
        const asset = v3PerpsMarketIdToAssetKey(orderSettled.marketId);
        const sizeDelta = weiFromWei(orderSettled.sizeDelta);
        const side = sizeDelta.gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT;
        const executed = weiFromWei(orderSettled.fillPrice).toString();
        const timestamp = wei(orderSettled.timestamp).mul(1000).toNumber();
        const isFullPosition = sizeDelta.abs().gte(wei(maxInt128));
        const conditionalOrder = conditionalOrders.find((co) => co.txnHash === orderSettled.orderCommitted.txnHash);
        let triggerConditions = null;
        let orderType = 'Market';
        let txnHash = orderSettled.txnHash ?? null;
        if (conditionalOrder) {
            const isPriceBelow = conditionalOrder.decodedConditions.isPriceBelow;
            const isPriceAbove = conditionalOrder.decodedConditions.isPriceAbove;
            triggerConditions = wei(isPriceBelow ?? isPriceAbove)
                .mul(isPriceBelow ? -1 : 1)
                .toString();
            orderType = formatOrderDisplayType(orderTypeFromCondition(side, conditionalOrder));
        }
        else {
            triggerConditions = null;
        }
        return {
            id: orderSettled.id,
            asset: asset,
            sizeDelta: sizeDelta.toString(),
            isFullPosition,
            displayAsset: getDisplayAsset(asset),
            txnHash,
            orderType,
            status: 'Filled',
            side,
            triggerConditions,
            executed,
            isLiquidation: false,
            timestamp,
        };
    });
    const cancelledOrFailedOrders = conditionalOrders
        .filter((co) => co.status !== 'Executed' && co.status !== 'Pending')
        .map((conditionalOrder) => {
        const asset = v3PerpsMarketIdToAssetKey(conditionalOrder.orderDetails.marketId);
        const sizeDelta = wei(conditionalOrder.orderDetails.sizeDelta);
        const side = sizeDelta.gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT;
        const timestamp = new Date(conditionalOrder.updatedAt).getTime();
        const isFullPosition = sizeDelta.abs().gte(wei(maxInt128));
        const orderType = formatOrderDisplayType(orderTypeFromCondition(side, conditionalOrder));
        const isPriceBelow = conditionalOrder.decodedConditions.isPriceBelow;
        const isPriceAbove = conditionalOrder.decodedConditions.isPriceAbove;
        const triggerConditions = wei(isPriceBelow ?? isPriceAbove)
            .mul(isPriceBelow ? -1 : 1)
            .toString();
        return {
            id: String(conditionalOrder.id),
            asset: asset,
            sizeDelta: sizeDelta.toString(),
            isFullPosition,
            displayAsset: getDisplayAsset(asset),
            txnHash: null,
            orderType,
            status: conditionalOrder.status,
            side,
            triggerConditions,
            executed: null,
            isLiquidation: false,
            timestamp,
        };
    });
    return [...filledOrders, ...cancelledOrFailedOrders].sort((a, b) => b.timestamp - a.timestamp);
};
export const orderSettledToTrade = (orderSettleds) => {
    return orderSettleds.map((o) => {
        const pnl = weiFromWei(o.pnl);
        const pnlWithFeesPaid = pnl
            .sub(weiFromWei(o.totalFees))
            .add(weiFromWei(o.accruedFunding))
            .add(weiFromWei(o.interestCharged ?? 0));
        return {
            ...o,
            account: o.account.owner,
            asset: v3PerpsMarketIdToAssetKey(o.marketId),
            timestamp: Number(o.timestamp),
            fillPrice: weiFromWei(o.fillPrice),
            sizeDelta: weiFromWei(o.sizeDelta),
            interestCharged: o.interestCharged ? weiFromWei(o.interestCharged) : undefined,
            totalFees: weiFromWei(o.totalFees),
            fundingAccrued: weiFromWei(o.accruedFunding),
            side: Number(o.sizeDelta) > 0 ? PositionSide.LONG : PositionSide.SHORT,
            pnl: weiFromWei(o.pnl),
            pnlWithFeesPaid: pnlWithFeesPaid,
            ownerAddress: o.account.owner,
            settlementTxHash: o.txnHash,
            commitmentTxHash: o.orderCommitted.txnHash,
            orderType: 'Market',
            positionId: o.position?.id,
        };
    });
};
export const chainToV3Provider = (chainId) => {
    switch (chainId) {
        case SnxV3NetworkIds.BASE_SEPOLIA:
        case SnxV3NetworkIds.BASE_MAINNET:
            return PerpsProvider.SNX_V3_BASE;
        // TODO: Add mainnet
        case SnxV3NetworkIds.ARB_SEPOLIA:
            return PerpsProvider.SNX_V3_ARB;
        default:
            throw new Error(`Invalid chainId: ${chainId}`);
    }
};
export const chainIsMultiCollateral = (chainId) => {
    return chainId === SnxV3NetworkIds.ARB_SEPOLIA;
};
