import { V3SynthMarketKey } from '@kwenta/sdk/constants'
import { SynthBalance, SynthV3BalancesAndAllowances, TokenBalances } from '@kwenta/sdk/types'
import { notNill } from '@kwenta/sdk/utils'
import Wei, { wei } from '@kwenta/wei'
import orderBy from 'lodash/orderBy'

import { BalancesActionReturn, TokenAllowances } from 'state/balances/types'

export const sortWei = (a: Wei, b: Wei, order: 'descending' | 'ascending') => {
	const diff = order === 'ascending' ? a.sub(b) : b.sub(a)

	if (diff.gt(0)) {
		return 1
	} else if (diff.lt(0)) {
		return -1
	} else {
		return 0
	}
}

export const serializeV3Balances = (
	v3Balances: SynthV3BalancesAndAllowances
): Partial<SynthV3BalancesAndAllowances<string>> => {
	return Object.keys(v3Balances).reduce<Partial<SynthV3BalancesAndAllowances<string>>>(
		(acc, asset) => {
			const key = asset as V3SynthMarketKey
			acc[key] = {
				balance: v3Balances[key]?.balance.toString() ?? '0',
				allowances: Object.keys(v3Balances[key]?.allowances ?? {}).reduce<Record<string, string>>(
					(acc, spender) => {
						acc[spender as V3SynthMarketKey] =
							v3Balances[key]?.allowances[spender].toString() ?? '0'
						return acc
					},
					{}
				),
			}
			return acc
		},
		{}
	)
}

export const unserializeV3Balances = (
	v3Balances: Partial<SynthV3BalancesAndAllowances<string>>
): Partial<SynthV3BalancesAndAllowances> => {
	return Object.keys(v3Balances).reduce<Partial<SynthV3BalancesAndAllowances>>((acc, asset) => {
		const key = asset as V3SynthMarketKey
		acc[key] = {
			balance: wei(v3Balances[key]?.balance ?? 0),
			allowances: Object.keys(v3Balances[key]?.allowances ?? {}).reduce<Record<string, Wei>>(
				(acc, spender) => {
					acc[spender as V3SynthMarketKey] = wei(v3Balances[key]?.allowances[spender] ?? 0)
					return acc
				},
				{}
			),
		}
		return acc
	}, {})
}

export const serializeTokenBalances = (tokenBalances: TokenBalances): TokenBalances<string> => {
	return Object.entries(tokenBalances).reduce((acc, [key, value]) => {
		if (value) {
			acc[key] = value.toString()
		}
		return acc
	}, {} as TokenBalances<string>)
}

export const unserializeTokenBalances = (tokenBalances: TokenBalances<string>): TokenBalances => {
	return Object.entries(tokenBalances).reduce((acc, [key, value]) => {
		if (value) {
			acc[key] = wei(value)
		}
		return acc
	}, {} as TokenBalances)
}

export const serializeTokenAllowances = (
	allowances: Record<string, TokenAllowances>
): Record<string, TokenAllowances<string>> => {
	return Object.entries(allowances).reduce<Record<string, TokenAllowances<string>>>(
		(acc, [asset, spenderAllowances]) => {
			const serialized = Object.entries(spenderAllowances).reduce<TokenAllowances<string>>(
				(acc, [spender, value]) => {
					if (value) acc[spender] = value.toString()
					return acc
				},
				{}
			)
			acc[asset] = serialized
			return acc
		},
		{}
	)
}

export const unserializeTokenAllowances = (
	allowances: Record<string, TokenAllowances<string>>
): Record<string, TokenAllowances> => {
	return Object.entries(allowances).reduce<Record<string, TokenAllowances>>(
		(acc, [asset, spenderAllowances]) => {
			const serialized = Object.entries(spenderAllowances).reduce<TokenAllowances>(
				(acc, [spender, value]) => {
					if (value) acc[spender] = wei(value)
					return acc
				},
				{}
			)
			acc[asset] = serialized
			return acc
		},
		{}
	)
}

export const serializeBalances = (
	synthBalancesMap: Record<string, SynthBalance>,
	totalUSDBalance: Wei,
	susdWalletBalance: Wei
): BalancesActionReturn<string> => {
	const balancesMapSerialized = Object.entries(synthBalancesMap).reduce<
		Record<string, SynthBalance<string>>
	>((acc, [key, value]) => {
		if (value) {
			acc[key] = {
				...value,
				balance: value.balance.toString(),
				usdBalance: value.usdBalance.toString(),
			}
		}

		return acc
	}, {})

	const balancesList = orderBy(
		Object.values(balancesMapSerialized).filter(notNill),
		(balance) => Number(balance.usdBalance),
		'desc'
	)
	return {
		synthBalances: balancesList,
		synthBalancesMap: balancesMapSerialized,
		totalUSDBalance: totalUSDBalance.toString(),
		susdWalletBalance: susdWalletBalance.toString(),
	}
}

export const unserializeBalances = (
	synthBalancesMap: Record<string, SynthBalance<string>>,
	totalUSDBalance: string,
	susdWalletBalance: string
): BalancesActionReturn => {
	const balancesMapSerialized = Object.entries(synthBalancesMap).reduce<
		Record<string, SynthBalance>
	>((acc, [key, value]) => {
		if (value) {
			acc[key] = {
				...value,
				balance: wei(value.balance),
				usdBalance: wei(value.usdBalance),
			}
		}

		return acc
	}, {})

	const balancesList = orderBy(
		Object.values(balancesMapSerialized).filter(notNill),
		(balance) => balance.usdBalance.toNumber(),
		'desc'
	)
	return {
		synthBalances: balancesList,
		synthBalancesMap: balancesMapSerialized,
		totalUSDBalance: wei(totalUSDBalance),
		susdWalletBalance: wei(susdWalletBalance),
	}
}
