import { Prices } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'state/store'
import { deserializePricesInfo } from 'utils/prices'

export const selectPrices = createSelector(
	(state: RootState) => state.prices,
	({ onChainPrices, offChainPrices }) => {
		const merged: Prices = {}
		Object.entries(onChainPrices).forEach(([key, { price }]) => {
			merged[key] = {
				onChain: wei(price),
			}
		})
		Object.entries(offChainPrices).forEach(([key, { price }]) => {
			if (merged[key]) {
				merged[key].offChain = wei(price)
			} else {
				merged[key] = {
					offChain: wei(price),
				}
			}
		})
		return merged
	}
)

export const selectOffchainPricesInfo = createSelector(
	(state: RootState) => state.prices.offChainPrices,
	(offChainPrices) => deserializePricesInfo(offChainPrices)
)

export const selectPreviousDayPrices = (state: RootState) => state.prices.previousDayPrices

export const selectPricesConnectionError = (state: RootState) => state.prices.connectionError
