import type KwentaSDK from '@kwenta/sdk'
import { PerpsProvider } from '@kwenta/sdk/types'

import { AccountAbstractionFactory } from './accountAbstraction'
import type { AppDispatch, RootState } from './store'

export enum FetchStatus {
	Idle,
	Loading,
	Success,
	Error,
}

export type QueryStatus = {
	status: FetchStatus
	provider?: PerpsProvider
	error?: string | null
}

export type ThunkConfig = {
	dispatch: AppDispatch
	state: RootState
	extra: { sdk: KwentaSDK; accountAbstractionFactory: AccountAbstractionFactory }
}
