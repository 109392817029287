import { V3SynthMarketKey } from '@kwenta/sdk/constants'
import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from 'state/types'

import {
	fetchBalances,
	fetchTokenAllowances,
	fetchTokenBalances,
	fetchV3BalancesAndAllowances,
	fetchWalletEthBalance,
} from './actions'
import { BalancesState } from './types'

export const ZERO_BALANCES = {
	synthBalances: [],
	synthBalancesMap: {},
	totalUSDBalance: '0',
	susdWalletBalance: '0',
	tokenBalances: {},
	tokenAllowances: {},
	synthV3Balances: {},
	ethWalletBalance: '0',
}

export const BALANCES_INITIAL_STATE: BalancesState = {
	status: FetchStatus.Idle,
	error: undefined,
	...ZERO_BALANCES,
}

const balancesSlice = createSlice({
	name: 'balances',
	initialState: BALANCES_INITIAL_STATE,
	reducers: {
		clearBalances: (state) => {
			state.synthBalances = []
			state.synthBalancesMap = {}
			state.totalUSDBalance = undefined
			state.susdWalletBalance = undefined
			state.ethWalletBalance = undefined
			state.error = undefined
		},
		setEthWalletBalance: (state, action) => {
			state.ethWalletBalance = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchBalances.pending, (state) => {
			state.status = FetchStatus.Loading
		})
		builder.addCase(fetchBalances.fulfilled, (state, action) => {
			state.status = FetchStatus.Success
			state.synthBalances = action.payload.synthBalances
			state.totalUSDBalance = action.payload.totalUSDBalance
			state.synthBalancesMap = action.payload.synthBalancesMap
			state.susdWalletBalance = action.payload.susdWalletBalance
		})

		builder.addCase(fetchTokenBalances.fulfilled, (state, action) => {
			state.status = FetchStatus.Success
			state.tokenBalances = action.payload
		})

		builder.addCase(fetchTokenAllowances.fulfilled, (state, action) => {
			state.status = FetchStatus.Success
			if (action.payload) {
				state.tokenAllowances = {
					...state.tokenAllowances,
					...action.payload,
				}
			}
		})

		builder.addCase(fetchV3BalancesAndAllowances.fulfilled, (state, action) => {
			if (action.payload) {
				Object.keys(action.payload).forEach((asset) => {
					const assetKey = asset as V3SynthMarketKey
					const assetBal = state.synthV3Balances[assetKey]
					if (assetBal) {
						assetBal.balance = action.payload![assetKey]!.balance
						assetBal.allowances = action.payload![assetKey]!.allowances
					} else {
						state.synthV3Balances[assetKey] = {
							balance: action.payload![assetKey]!.balance,
							allowances: action.payload![assetKey]!.allowances,
						}
					}
				})
			}
		})

		builder.addCase(fetchWalletEthBalance.fulfilled, (state, action) => {
			state.ethWalletBalance = action.payload
		})
	},
})

export default balancesSlice.reducer

export const { setEthWalletBalance } = balancesSlice.actions
