import { SynthV3BalancesAndAllowances, TokenBalances } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Address } from 'viem/accounts'

import { selectProviderNetwork } from 'state/futures/common/selectors'
import { selectSnxV3AccountContext } from 'state/futures/snxPerpsV3/selectors'
import type { ThunkConfig } from 'state/types'
import { selectSignerNetwork, selectWallet } from 'state/wallet/selectors'
import {
	serializeBalances,
	serializeTokenAllowances,
	serializeTokenBalances,
	serializeV3Balances,
} from 'utils/balances'
import logError from 'utils/logError'

import { ZERO_BALANCES } from './reducer'
import { BalancesActionReturn, TokenAllowances } from './types'

export const fetchTokenBalances = createAsyncThunk<TokenBalances<string>, void, ThunkConfig>(
	'balances/fetchTokenBalances',
	async (_, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		const network = selectProviderNetwork(getState())
		if (!wallet) return {}
		const tokenBalance = await sdk.tokens.getBalanceOf('USDC', wallet, network)
		return serializeTokenBalances({
			USDC: tokenBalance,
		})
	}
)

export const fetchTokenAllowances = createAsyncThunk<
	Record<string, TokenAllowances<string>> | undefined,
	{ tokenSymbols: Array<'USDC'>; spenders: Address[] },
	ThunkConfig
>(
	'balances/fetchTokenAllowances',
	async ({ tokenSymbols, spenders }, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		const network = selectProviderNetwork(getState())
		if (!wallet) return
		const tokenAllowances = await sdk.tokens.checkAllowancesForAssets(
			tokenSymbols,
			spenders,
			network
		)
		return serializeTokenAllowances(tokenAllowances)
	}
)

export const fetchBalances = createAsyncThunk<BalancesActionReturn<string>, void, ThunkConfig>(
	'balances/fetchBalances',
	async (_, { getState, dispatch, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		const chainId = selectProviderNetwork(getState())
		if (!wallet) return ZERO_BALANCES
		dispatch(fetchTokenBalances())
		dispatch(fetchWalletEthBalance())
		const { balancesMap, totalUSDBalance, susdWalletBalance } = await sdk.synths.getSynthBalances(
			wallet,
			chainId
		)

		return serializeBalances(balancesMap, totalUSDBalance, susdWalletBalance)
	}
)

export const fetchV3BalancesAndAllowances = createAsyncThunk<
	Partial<SynthV3BalancesAndAllowances<string>> | undefined,
	string[],
	ThunkConfig
>('balances/fetchV3BalancesAndAllowances', async (spenders, { getState, extra: { sdk } }) => {
	const wallet = selectWallet(getState())
	const { network } = selectSnxV3AccountContext(getState())
	try {
		if (!wallet) return
		const res = await sdk.synths.getSynthV3BalancesAndAllowances(
			wallet,
			spenders as Address[],
			network
		)
		return serializeV3Balances(res)
	} catch (err) {
		logError(err)
		throw err
	}
})

export const fetchWalletEthBalance = createAsyncThunk<string, void, ThunkConfig>(
	'balances/fetchWalletEthBalance',
	async (_, { getState, extra: { sdk } }) => {
		const wallet = selectWallet(getState())
		if (!wallet) return '0'

		const walletNetworkId = selectSignerNetwork(getState())
		if (!walletNetworkId) return '0'
		const client = sdk.context.clients[walletNetworkId] ?? sdk.context.signerPublicClient
		const res = await client.getBalance({ address: wallet })
		return wei(res).toString()
	}
)
