export var TenderlyNetwork;
(function (TenderlyNetwork) {
    TenderlyNetwork["ETHEREUM_MAINNET"] = "mainnet";
    TenderlyNetwork["OPTIMISM_MAINNET"] = "optimism";
    TenderlyNetwork["OPTIMISM_SEPOLIA"] = "optimism-sepolia";
    TenderlyNetwork["BASE_SEPOLIA"] = "base-sepolia";
    TenderlyNetwork["BASE_MAINNET"] = "base";
    TenderlyNetwork["ARBITRUM_MAINNET"] = "arbitrum";
    TenderlyNetwork["ARBITRUM_SEPOLIA"] = "arbitrum-sepolia";
})(TenderlyNetwork || (TenderlyNetwork = {}));
export const TENDERLY_NETWORK_LOOKUP = {
    1: TenderlyNetwork.ETHEREUM_MAINNET,
    10: TenderlyNetwork.OPTIMISM_MAINNET,
    11155420: TenderlyNetwork.OPTIMISM_SEPOLIA,
    84532: TenderlyNetwork.BASE_SEPOLIA,
    8453: TenderlyNetwork.BASE_MAINNET,
    42161: TenderlyNetwork.ARBITRUM_MAINNET,
    421614: TenderlyNetwork.ARBITRUM_SEPOLIA,
};
export var InfuraNetwork;
(function (InfuraNetwork) {
    InfuraNetwork["ETHEREUM_MAINNET"] = "mainnet";
    InfuraNetwork["OPTIMISM_MAINNET"] = "optimism-mainnet";
    InfuraNetwork["OPTIMISM_SEPOLIA"] = "optimism-sepolia";
    InfuraNetwork["BASE_SEPOLIA"] = "base-sepolia";
    InfuraNetwork["BASE_MAINNET"] = "base-mainnet";
    InfuraNetwork["ARBITRUM_MAINNET"] = "arbitrum-mainnet";
    InfuraNetwork["ARBITRUM_SEPOLIA"] = "arbitrum-sepolia";
})(InfuraNetwork || (InfuraNetwork = {}));
export const INFURA_NETWORK_LOOKUP = {
    1: InfuraNetwork.ETHEREUM_MAINNET,
    10: InfuraNetwork.OPTIMISM_MAINNET,
    11155420: InfuraNetwork.OPTIMISM_SEPOLIA,
    84532: InfuraNetwork.BASE_SEPOLIA,
    8453: InfuraNetwork.BASE_MAINNET,
    42161: InfuraNetwork.ARBITRUM_MAINNET,
    421614: InfuraNetwork.ARBITRUM_SEPOLIA,
};
export var AlchemyNetwork;
(function (AlchemyNetwork) {
    AlchemyNetwork["ETHEREUM_MAINNET"] = "eth-mainnet";
    AlchemyNetwork["OPTIMISM_MAINNET"] = "opt-mainnet";
    AlchemyNetwork["OPTIMISM_SEPOLIA"] = "opt-sepolia";
    AlchemyNetwork["BASE_SEPOLIA"] = "base-sepolia";
    AlchemyNetwork["BASE_MAINNET"] = "base-mainnet";
    AlchemyNetwork["ARBITRUM_MAINNET"] = "arb-mainnet";
    AlchemyNetwork["ARBITRUM_SEPOLIA"] = "arb-sepolia";
})(AlchemyNetwork || (AlchemyNetwork = {}));
export const ALCHEMY_NETWORK_LOOKUP = {
    1: AlchemyNetwork.ETHEREUM_MAINNET,
    10: AlchemyNetwork.OPTIMISM_MAINNET,
    11155420: AlchemyNetwork.OPTIMISM_SEPOLIA,
    84532: AlchemyNetwork.BASE_SEPOLIA,
    8453: AlchemyNetwork.BASE_MAINNET,
    42161: AlchemyNetwork.ARBITRUM_MAINNET,
    421614: AlchemyNetwork.ARBITRUM_SEPOLIA,
};
