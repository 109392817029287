import { ClaimParams } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'

export const serializeClaimParams = (params: ClaimParams[]) =>
	params.map((p) => ({
		...p,
		amount: p.amount.toString(),
	}))

export const unserializeClaimParams = (params: ClaimParams<string>[]) =>
	params.map((p) => ({
		...p,
		amount: wei(p.amount),
	}))
