import request, { gql } from 'graphql-request';
import { PositionSide, } from '../types';
import { getDisplayAsset, getPerennialSubgraphUrl } from '../utils';
import { assetToFuturesMarketAsset, getOrderTypeFromOpenOrder, orderCreatedToTrade, toWei6, } from '../utils/perennial';
import { formatEther, getAddress } from 'viem';
import { addressToAsset } from '@perennial/sdk';
import { DEFAULT_PYTH_MARKET_HOUR_ENDPOINT, ZERO_WEI } from '../constants';
import { wei } from '@kwenta/wei';
import axios from 'axios';
export const queryPerennialMarketOrders = async (params) => {
    const { chainId, marketAddress, minTimestamp, maxTimestamp, pageSize } = params;
    const response = await request(getPerennialSubgraphUrl(chainId), gql `
			query GetAllTrades(
				$marketAddress: String
				$minTimestamp: BigInt
				$maxTimestamp: BigInt
				$pageSize: Int
			) {
				orders(
					first: $pageSize
					where: {
						and: [
							{ or: [{ long_not: 0 }, { short_not: 0 }] }
							{ market: $marketAddress }
							{ executionPrice_gt: 0 }
							{ timestamp_gt: $minTimestamp, timestamp_lt: $maxTimestamp }
						]
					}
					orderBy: timestamp
					orderDirection: desc
				) {
					id
					account
					market {
						id
					}
					maker
					long
					short
					executionPrice
					timestamp
					transactionHashes
				}
			}
		`, {
        marketAddress,
        minTimestamp,
        maxTimestamp,
        pageSize,
    });
    return orderCreatedToTrade(response.orders, chainId);
};
export const queryPnlUpdates = async (params) => {
    const { chainId, walletAddress } = params;
    const response = await request(getPerennialSubgraphUrl(chainId), gql `
			query PnlUpdates($walletAddress: String) {
				orders(
					where: { account: $walletAddress }
					orderBy: timestamp
					orderDirection: desc
					first: 5000
				) {
					id
					accumulation {
						collateral_accumulation
					}
					timestamp
				}
			}
		`, {
        walletAddress,
    });
    return response.orders.map((pnlUpdate) => ({
        id: pnlUpdate.id,
        accountId: walletAddress,
        pnl: toWei6(BigInt(pnlUpdate.accumulation.collateral_accumulation)),
        timestamp: pnlUpdate.timestamp,
    }));
};
export const queryOpenOrders = async (params) => {
    const { chainId, walletAddress, minTimestamp, maxTimestamp, pageSize } = params;
    const response = await request(getPerennialSubgraphUrl(chainId), gql `
			query OpenOrders(
				$walletAddress: String
				$minTimestamp: BigInt
				$maxTimestamp: BigInt
				$pageSize: Int
			) {
				multiInvokerTriggerOrders(
					where: {
						account: $walletAddress
						blockTimestamp_gt: $minTimestamp
						blockTimestamp_lt: $maxTimestamp
					}
					orderBy: blockTimestamp
					orderDirection: desc
					first: $pageSize
				) {
					id
					account
					market
					nonce
					triggerOrderSide
					triggerOrderDelta
					triggerOrderPrice
					triggerOrderComparison
					blockTimestamp
					executed
					cancelled
					transactionHash
				}
			}
		`, {
        walletAddress,
        minTimestamp,
        maxTimestamp,
        pageSize,
    });
    return response.multiInvokerTriggerOrders.map((order) => {
        const market = addressToAsset(chainId, getAddress(order.market));
        const asset = assetToFuturesMarketAsset(market);
        return {
            id: order.id,
            asset: asset,
            account: walletAddress,
            market: order.market,
            nonce: order.nonce,
            side: order.triggerOrderSide === 1 ? PositionSide.LONG : PositionSide.SHORT,
            orderComparison: order.triggerOrderComparison,
            status: order.executed ? 'Filled' : order.cancelled ? 'Cancelled' : 'Open',
            cancelled: order.cancelled,
            triggerConditions: order.triggerOrderPrice && Number(order.triggerOrderPrice) !== 0
                ? toWei6(BigInt(order.triggerOrderPrice)).toString()
                : undefined,
            sizeDelta: toWei6(BigInt(order.triggerOrderDelta ?? 0)).toString(),
            timestamp: Number(order.blockTimestamp) * 1000,
            isFullPosition: Number(order.triggerOrderDelta) === 0,
            displayAsset: getDisplayAsset(asset),
            orderType: getOrderTypeFromOpenOrder({
                triggerOrderDelta: order.triggerOrderDelta,
                triggerOrderSide: order.triggerOrderSide,
                triggerOrderComparison: order.triggerOrderComparison,
            }),
            txnHash: order.transactionHash,
            executed: order.executed && order.triggerOrderPrice && order.triggerOrderDelta
                ? toWei6(BigInt(order.triggerOrderPrice))
                    .mul(toWei6(BigInt(order.triggerOrderDelta)))
                    .toString()
                : '0',
            isLiquidation: false, // TODO
        };
    });
};
export const queryPerennialFuturesFee = async (params) => {
    const { chainId, minTimestamp, maxTimestamp, wallet } = params;
    let queryResponseCount = 0;
    let lastTimestamp = minTimestamp;
    let futuresTrades = [];
    do {
        const response = await request(getPerennialSubgraphUrl(chainId), gql `
				query futuresTrades($minTimestamp: BigInt, $maxTimestamp: BigInt, $wallet: String) {
					orders(
						where: {
							timestamp_gt: $minTimestamp
							timestamp_lte: $maxTimestamp
							accumulation_: {
								fee_accumulation_gt: "0"
							}
							${wallet ? 'account_: { id: $wallet }' : ''}
						}
						orderBy: timestamp
						orderDirection: asc
						first: 1000
					) {
						timestamp
						accumulation {
							fee_subAccumulation_trade
							collateral_subAccumulation_offset
						}
					}
				}
			`, {
            minTimestamp: lastTimestamp,
            maxTimestamp,
            wallet,
        });
        queryResponseCount = response.orders.length;
        if (queryResponseCount > 0) {
            lastTimestamp = response.orders[queryResponseCount - 1].timestamp;
        }
        futuresTrades.push(...response.orders.map((trade) => {
            const { fee_subAccumulation_trade: fee, collateral_subAccumulation_offset } = trade.accumulation;
            const offset = collateral_subAccumulation_offset < 0 ? -collateral_subAccumulation_offset : 0;
            return {
                feesKwenta: ((BigInt(fee) + BigInt(offset)) * BigInt(1e12)).toString(),
            };
        }));
    } while (queryResponseCount === 1000);
    return futuresTrades
        .map(({ feesKwenta }) => formatEther(BigInt(feesKwenta)))
        .reduce((acc, curr) => acc.add(wei(curr)), ZERO_WEI)
        .toString();
};
export const checkMarketStatusPyth = async (pythId) => {
    return await axios
        .get(`${DEFAULT_PYTH_MARKET_HOUR_ENDPOINT}/${pythId}`)
        .then(({ data }) => ({
        ...data.market_hours,
    }))
        .catch(() => {
        return {
            is_open: true,
            next_open: null,
            next_close: null,
        };
    });
};
