import KwentaSDK from '@kwenta/sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ThunkConfig } from 'state/types'
import { selectWallet } from 'state/wallet/selectors'
import logError from 'utils/logError'

import { selectValidSearchTerm } from './selectors'

export const fetchV2Leaderboard = createAsyncThunk<
	Awaited<ReturnType<KwentaSDK['stats']['getV2Leaderboard']>>,
	void,
	ThunkConfig
>('stats/fetchV2Leaderboard', async (_, { getState, extra: { sdk } }) => {
	const searchTerm = selectValidSearchTerm(getState())
	const wallet = selectWallet(getState())
	try {
		return await sdk.stats.getV2Leaderboard(searchTerm || '', wallet || '')
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchV3Leaderboard = createAsyncThunk<
	Awaited<ReturnType<KwentaSDK['stats']['getV3Leaderboard']> | undefined>,
	void,
	ThunkConfig
>('stats/fetchV3Leaderboard', async (_, { getState, extra: { sdk } }) => {
	const searchTerm = selectValidSearchTerm(getState())
	const wallet = selectWallet(getState())
	try {
		return await sdk.stats.getV3Leaderboard(searchTerm || '', wallet || '')
	} catch (error) {
		logError(error)
		throw error
	}
})
