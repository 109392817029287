import { NetworkId, NetworkIdByName } from '@kwenta/sdk/types'

export const getBaseUrl = (networkId: NetworkId) => {
	switch (networkId) {
		case NetworkIdByName['base-sepolia']:
			return 'https://sepolia.basescan.org'
		case NetworkIdByName['sepolia-ovm']:
			return 'https://sepolia-optimistic.etherscan.io/'
		case NetworkIdByName.mainnet:
			return 'https://etherscan.io'
		case NetworkIdByName['base-mainnet']:
			return 'https://basescan.org'
		case NetworkIdByName.arbitrum:
			return 'https://arbiscan.io/'
		case NetworkIdByName['mainnet-ovm']:
		default:
			return 'https://optimistic.etherscan.io'
	}
}

export const generateExplorerFunctions = (baseUrl: string) => ({
	baseLink: baseUrl,
	txLink: (txId: string) => `${baseUrl}/tx/${txId}`,
	addressLink: (address: string) => `${baseUrl}/address/${address}`,
	tokenLink: (address: string) => `${baseUrl}/token/${address}`,
	blockLink: (blockNumber: string) => `${baseUrl}/block/${blockNumber}`,
})
