import {
	MIN_ACCOUNT_KEEPER_USDC_BAL,
	SL_TP_MAX_SIZE_CROSS_MARGIN,
	V3SynthMarketKey,
	V3_SYNTH_MARKET_KEYS,
	ZERO_WEI,
	v3SynthKeyToV2Asset,
} from '@kwenta/sdk/constants'
import {
	AssetKey,
	FuturesTrade,
	OrderTypeEnum,
	PerpsMarketV3,
	PerpsProvider,
	PerpsV3Liquidation,
	PerpsV3Position,
	PositionSide,
	PotentialTradeStatus,
	SnxV3NetworkIds,
	SupportedNetworkIds,
} from '@kwenta/sdk/types'
import {
	MarketKeyByAsset,
	calculateDesiredFillPrice,
	floorNumber,
	formatCurrency,
	formatOrderDisplayType,
	getDefaultPriceImpact,
	getDisplayAsset,
	isZero,
	orderTypeFromCondition,
	toWei,
} from '@kwenta/sdk/utils'
import Wei, { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import {
	ConditionOrderTableItemCross,
	FuturesPositionTablePosition,
	FuturesPositionTablePositionWithDetails,
} from 'types/futures'

import { ERROR_MESSAGES } from 'components/ErrorNotifier'
import {
	DEFAULT_DELAYED_CANCEL_BUFFER,
	MARGIN_ENGINE_ENABLED,
	V3_CONDITIONAL_ORDERS_ENABLED,
} from 'constants/defaults'
import { REQUIRED_MARGIN_RATIO } from 'constants/perpsV3'
import {
	selectBalances,
	selectSynthV3BalancesAndAllowances,
	selectTokenAllowances,
} from 'state/balances/selectors'
import { KEEPER_USD_GAS_FEE, ZERO_STATE_MARGIN_INFO } from 'state/constants'
import { selectPrices } from 'state/prices/selectors'
import { RootState } from 'state/store'
import { selectWallet } from 'state/wallet/selectors'
import {
	calculateKeeperFeesForOrders,
	calculateV3LiqPrice,
	previewStatusToI18nMsg,
	providerIsCrossMargin,
	unserializePerpsV3PositionHistory,
	unserializeTrades,
	unserializeV3Liquidations,
	unserializeV3Positions,
} from 'utils/futures'

import { unserializeCrossMarginTradePreview } from '../../../utils/futures'
import {
	selectAllMarkPriceInfos,
	selectClosePositionOrderInputs,
	selectLeverageSide,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketKey,
	selectPerpsProvider,
	selectProviderNetwork,
	selectSelectedPortfolioTimeframe,
	selectSlTpTradeInputs,
	selectSnxV3BaseNetwork,
	selectSnxV3Network,
	selectTradeOrderType,
	selectTradePanelInputs,
	selectUserInfoShowAllMarkets,
	selectV3Markets,
} from '../common/selectors'
import { CrossMarginRiskStatus, MarkPrices } from '../common/types'

import { SnxV3AccountData } from './types'

export const selectV3MarketKey = createSelector(
	(state: RootState) => state.futures.selectedMarketAsset.snx_v3_base,
	(marketAsset) => MarketKeyByAsset[marketAsset]
)

export const selectMargineEngineEnabled = createSelector(selectProviderNetwork, (network) => {
	return (
		(network === SnxV3NetworkIds.BASE_SEPOLIA || SnxV3NetworkIds.BASE_MAINNET) &&
		MARGIN_ENGINE_ENABLED
	)
})

export const selectCondOrdersEnabled = createSelector(
	selectPerpsProvider,
	selectMargineEngineEnabled,
	(futuresType, margineEngineEnabled) => {
		return (
			futuresType === PerpsProvider.SNX_V2_OP ||
			futuresType === PerpsProvider.PERENNIAL_V2_ARB ||
			(V3_CONDITIONAL_ORDERS_ENABLED && margineEngineEnabled)
		)
	}
)

export const selectMarkPricesV3 = createSelector(
	selectV3Markets,
	selectPrices,
	(markets, prices) => {
		const markPrices: MarkPrices = {}
		return markets.reduce((acc, market) => {
			const price = prices[market.asset]?.offChain ?? wei(0)
			return {
				...acc,
				[market.marketKey]: wei(price).mul(
					wei(market.marketSkew).div(market.settings.skewScale).add(1)
				),
			}
		}, markPrices)
	}
)

export const selectV3SelectedMarketInfo = createSelector(
	selectV3Markets,
	selectV3MarketKey,
	(markets, selectedMarket) => {
		return markets.find((market) => market.marketKey === selectedMarket)
	}
)

export const selectV3SelectedMarketId = createSelector(
	selectV3SelectedMarketInfo,
	(marketInfo) => marketInfo?.marketId
)

export const selectSignerSupportsSnxV3Base = (state: RootState) =>
	state.wallet.walletNetworkId === SupportedNetworkIds.BASE_SEPOLIA ||
	state.wallet.walletNetworkId === SupportedNetworkIds.BASE_MAINNET

export const selectSignerSupportsSnxV3Arb = (state: RootState) =>
	state.wallet.walletNetworkId === SupportedNetworkIds.ARB_SEPOLIA ||
	state.wallet.walletNetworkId === SupportedNetworkIds.ARB_MAINNET

export const selectSnxV3Account = createSelector(
	selectPerpsProvider,
	selectWallet,
	selectSnxV3Network,
	(state: RootState) => state.futures.accounts,
	(provider, wallet, network, accounts) => {
		if (!providerIsCrossMargin(provider)) return
		const accountData = wallet ? accounts[provider]?.[wallet] : undefined

		if (accountData?.network === network) {
			return BigInt(accountData.account)
		}
		return
	}
)

export const selectSnxV3AccountContext = createSelector(
	selectPerpsProvider,
	selectWallet,
	selectSnxV3Network,
	selectSnxV3Account,
	(provider, wallet, network, accountId) => {
		return {
			wallet,
			network,
			accountId: accountId?.toString(),
			provider:
				provider === PerpsProvider.SNX_V3_BASE
					? PerpsProvider.SNX_V3_BASE
					: PerpsProvider.SNX_V3_ARB,
			isIsolatedMargin: false,
		}
	}
)

export const selectCrossMarginAccountsData = createSelector(
	selectWallet,
	(state: RootState) => state.futures.accounts,
	(wallet, accounts) => {
		return {
			[PerpsProvider.SNX_V3_BASE]: wallet
				? accounts[PerpsProvider.SNX_V3_BASE]?.[wallet]
				: undefined,
			[PerpsProvider.SNX_V3_ARB]: wallet ? accounts[PerpsProvider.SNX_V3_ARB]?.[wallet] : undefined,
		}
	}
)

export const selectSelectedCrossMarginAccountData = createSelector(
	selectWallet,
	selectPerpsProvider,
	selectSnxV3BaseNetwork,
	(state: RootState) => state.futures.accounts,
	(wallet, provider, network, accounts) => {
		if (provider !== PerpsProvider.SNX_V3_BASE && provider !== PerpsProvider.SNX_V3_ARB) return
		const accountData = wallet ? accounts[provider]?.[wallet] : undefined
		return accountData?.network === network ? accountData : undefined
	}
)

export const selectMarginEnginePermitted = createSelector(
	selectSelectedCrossMarginAccountData,
	selectMargineEngineEnabled,
	(account, engineEnabled) => {
		return engineEnabled && !!account?.marginEnginePermitted
	}
)

export const selectCrossMarginMarginInfo = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		const {
			availableMargin,
			withdrawableMargin,
			requiredInitialMargin,
			requiredMaintenanceMargin,
			maxLiquidationReward,
		} = account ? account.marginInfo : ZERO_STATE_MARGIN_INFO
		return {
			availableMargin: wei(availableMargin).gt(0) ? wei(availableMargin) : wei(0),
			withdrawableMargin: wei(withdrawableMargin),
			requiredInitialMargin: wei(requiredInitialMargin),
			maxLiquidationReward: wei(maxLiquidationReward),
			requiredMaintenanceMargin: wei(requiredMaintenanceMargin).gt(1)
				? wei(requiredMaintenanceMargin)
				: wei(0),
		}
	}
)

export const selectCrossMarginPendingOrders = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		const orders = account?.conditionalOrders ?? []
		return orders.filter((o) => o.status === 'Pending')
	}
)

export const selectReservedMarginForOrders = createSelector(
	selectCrossMarginPendingOrders,
	selectV3Markets,
	(pendingOrders, markets) => {
		const ordersRequiringMargin = pendingOrders.filter((o) => !o.orderDetails.isReduceOnly)

		const totalReserved = ordersRequiringMargin.reduce<Wei>((acc, order) => {
			const market = markets.find((m) => m.marketId === order.orderDetails.marketId)
			if (!market) return acc

			const orderPrice =
				order.decodedConditions.isPriceAbove ?? order.decodedConditions.isPriceBelow
			if (!orderPrice) return acc

			const notionalSize = wei(order.orderDetails.sizeDelta).abs().mul(wei(orderPrice))

			return acc.add(notionalSize.mul(REQUIRED_MARGIN_RATIO))
		}, wei(0))
		return totalReserved
	}
)

export const selectCrossMarginAvailableMargin = createSelector(
	selectCrossMarginMarginInfo,
	(marginInfo) => {
		return marginInfo.availableMargin
	}
)

export const selectMaintenanceMarginRatio = createSelector(
	selectCrossMarginMarginInfo,
	({ availableMargin, requiredMaintenanceMargin }) => {
		// Using > 1 as requiredMaintenanceMargin is always at least 1

		const ratio = requiredMaintenanceMargin.gt(1)
			? availableMargin.gt(0)
				? requiredMaintenanceMargin.div(availableMargin)
				: wei(1)
			: wei(0)
		const riskStatus = ratio.gt(0.75)
			? CrossMarginRiskStatus.High
			: ratio.gt(0.5)
			? CrossMarginRiskStatus.Moderate
			: CrossMarginRiskStatus.Low
		return { ratio, riskStatus }
	}
)

export const selectV3Spenders = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.snxV3.spenders
)

type DepositAllowances = { marketProxy: Wei; marginEngine: Wei; spotProxy: Wei }

export const selectDepositAllowances = createSelector(
	selectV3Spenders,
	selectSynthV3BalancesAndAllowances,
	selectTokenAllowances,
	(spenders, balancesAndAllowances, tokenAllowances) => {
		if (!spenders) return {}
		const synthAllowances = Object.keys(balancesAndAllowances).reduce<
			Partial<Record<V3SynthMarketKey, DepositAllowances>>
		>((acc, asset) => {
			const key = asset as V3SynthMarketKey
			acc[key] = {
				marketProxy: spenders.marketProxy
					? balancesAndAllowances[key]?.allowances[spenders.marketProxy] ?? wei(0)
					: wei(0),
				marginEngine: spenders.marginEngine
					? balancesAndAllowances[key]?.allowances[spenders.marginEngine] ?? wei(0)
					: wei(0),
				spotProxy: spenders.spotProxy
					? balancesAndAllowances[key]?.allowances[spenders.spotProxy] ?? wei(0)
					: wei(0),
			}
			return acc
		}, {})

		const tokenAllowancesFormatted = Object.keys(tokenAllowances).reduce<
			Partial<Record<string, DepositAllowances>>
		>((acc, asset) => {
			acc[asset] = {
				marketProxy: spenders.marketProxy
					? tokenAllowances[asset][spenders.marketProxy] ?? wei(0)
					: wei(0),
				marginEngine: spenders.marginEngine
					? tokenAllowances[asset][spenders.marginEngine] ?? wei(0)
					: wei(0),
				spotProxy: spenders.spotProxy
					? tokenAllowances[asset][spenders.spotProxy] ?? wei(0)
					: wei(0),
			}
			return acc
		}, {})

		return {
			...tokenAllowancesFormatted,
			...synthAllowances,
		} as Record<string, DepositAllowances>
	}
)

export const selectCrossMarginPositionHistory = createSelector(
	selectSelectedCrossMarginAccountData,
	(accountData) => {
		return unserializePerpsV3PositionHistory(accountData?.positionHistory ?? [])
	}
)

type CrossMarginTrade = FuturesTrade & {
	market: PerpsMarketV3
	txnHash?: string
}

type CrossMarginLiquidation = PerpsV3Liquidation & {
	market: PerpsMarketV3
	txnHash?: string
}

export const selectV3SynthPrices = createSelector(
	selectV3Markets,
	selectPrices,
	(markets, prices) => {
		return markets.reduce<Partial<Record<AssetKey, Wei>>>((acc, market) => {
			const price = prices[market.asset]?.offChain ?? wei(0)
			return {
				...acc,
				[market.asset]: price,
			}
		}, {})
	}
)

export const selectCrossMarginConditionalOrders = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		return account?.conditionalOrders ?? []
	}
)

export const selectAllCrossMarginOrderTableItems = createSelector(
	selectV3Markets,
	selectAllMarkPriceInfos,
	selectCrossMarginAccountsData,
	(markets, priceInfos, accounts) => {
		const formatOrders = (accountData: SnxV3AccountData | undefined) => {
			return accountData
				? accountData.conditionalOrders
						.filter((o) => o.status === 'Pending')
						.reduce<ConditionOrderTableItemCross[]>((acc, order) => {
							const targetPrice =
								order.decodedConditions.isPriceAbove ?? order.decodedConditions.isPriceBelow
							const market = markets.find((m) => m.marketId === order.orderDetails.marketId)
							if (!market || !targetPrice) return acc

							const priceInfo = priceInfos[PerpsProvider.SNX_V3_BASE][market.marketKey]
							if (!priceInfo) return acc

							const position = accountData?.positions?.find((p) => p.marketId === market?.marketId)
							const sizeDelta = wei(order.orderDetails.sizeDelta)
							const notionalSize = wei(order.orderDetails.sizeDelta).abs().mul(wei(targetPrice))
							const reservedMargin = order.orderDetails.isReduceOnly
								? wei(0)
								: notionalSize.mul(REQUIRED_MARGIN_RATIO)

							const orderType = orderTypeFromCondition(position?.side, order)
							const formattedOrder: ConditionOrderTableItemCross = {
								id: Number(order.id),
								provider: PerpsProvider.SNX_V3_BASE,
								createdAt: new Date(order.createdAt),
								status: order.status,
								nonce: Number(order.nonce),
								updatedAt: order.updatedAt ? new Date(order.updatedAt) : undefined,
								account: order.orderDetails.accountId,
								marketName: market.marketName,
								asset: market.asset,
								marketKey: market.marketKey,
								size: sizeDelta,
								maxExecutorFee: wei(order.maxExecutorFee),
								targetPrice: wei(targetPrice),
								desiredFillPrice: wei(order.orderDetails.acceptablePrice),
								sizeTxt: sizeDelta.abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN)
									? 'Close'
									: formatCurrency(market.asset, sizeDelta, {
											currencyKey: getDisplayAsset(market.asset) ?? '',
											suggestDecimals: true,
									  }),
								marginDelta: reservedMargin,
								orderType: orderType,
								orderTypeDisplay: formatOrderDisplayType(orderType),
								reduceOnly: order.orderDetails.isReduceOnly,
								side: sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
								isSlTp: sizeDelta.abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN),
								currentPrice: priceInfo,
								tradeDirection: sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
							}
							acc.push(formattedOrder)
							return acc
						}, [])
				: []
		}

		return {
			[PerpsProvider.SNX_V3_BASE]: formatOrders(accounts[PerpsProvider.SNX_V3_BASE]),
			[PerpsProvider.SNX_V3_ARB]: formatOrders(accounts[PerpsProvider.SNX_V3_ARB]),
		}
	}
)

export const selectCrossMarginOrderTableItemsForProvider = createSelector(
	selectAllCrossMarginOrderTableItems,
	selectPerpsProvider,
	(orders, provider) => {
		if (provider !== PerpsProvider.SNX_V3_BASE && provider !== PerpsProvider.SNX_V3_ARB) return []
		return providerIsCrossMargin(provider) ? orders[provider] : []
	}
)

export const selectAllSnxV3SLTPOrders = createSelector(
	selectCrossMarginOrderTableItemsForProvider,
	(orders) => {
		return orders.filter((o) => o.reduceOnly && wei(o.size).abs().eq(SL_TP_MAX_SIZE_CROSS_MARGIN))
	}
)

export const selectRequiredUsdForPendingOrders = createSelector(
	selectCrossMarginOrderTableItemsForProvider,
	(orders) => {
		return calculateKeeperFeesForOrders(
			orders.map((o) => ({ ...o, maxExecutorFee: wei(KEEPER_USD_GAS_FEE) }))
		)
	}
)

export const selectKeeperUSDBalance = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		return wei(account?.usdcBalance ?? 0)
	}
)

export const selectRequiredKeeperUsdDeposit = createSelector(
	selectRequiredUsdForPendingOrders,
	selectKeeperUSDBalance,
	(requiredUsd, usdBal) => {
		return wei(requiredUsd).gt(usdBal) ? wei(requiredUsd).sub(usdBal) : ZERO_WEI
	}
)

export const selectCrossMarginPositions = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	selectMarkPricesV3,
	selectCrossMarginMarginInfo,
	selectCrossMarginPositionHistory,
	selectAllSnxV3SLTPOrders,
	(account, markets, markPrices, marginInfo, positionsHistory, sltpOrders) => {
		const { availableMargin, requiredMaintenanceMargin } = marginInfo
		const positions = unserializeV3Positions(account?.positions ?? [])

		return positions.reduce<FuturesPositionTablePosition[]>((acc, position) => {
			const market = markets.find((market) => market.marketId === position.marketId)

			const history = positionsHistory.find((ph) => {
				return ph.isOpen && ph.marketId === position.marketId
			})

			if (market) {
				const markPrice = markPrices[market?.marketKey!] ?? wei(0)

				const stopLoss = sltpOrders.find((o) => {
					return o.marketKey === market.marketKey && o.orderType === OrderTypeEnum.STOP_LOSS
				})
				const takeProfit = sltpOrders.find(
					(o) => o.marketKey === market.marketKey && o.orderType === OrderTypeEnum.TAKE_PROFIT
				)

				const upnl = history
					? position.size.mul(
							history.avgEntryPrice.sub(markPrice).mul(position.side === PositionSide.LONG ? -1 : 1)
					  )
					: position.pnl

				const liqPrice = requiredMaintenanceMargin
					.sub(availableMargin)
					.div(position.side === PositionSide.LONG ? position.size : position.size.neg())
					.add(markPrice)

				const startNotional = position.size.abs().mul(history?.avgEntryPrice ?? 0)
				const currentNotional = position.size.abs().mul(markPrice)

				const pnlPct = startNotional.gt(0)
					? position.side === PositionSide.LONG
						? currentNotional.sub(startNotional).div(startNotional)
						: currentNotional.sub(startNotional).div(startNotional.neg())
					: wei(0)

				const accruedFunding = wei(history?.netFunding ?? ZERO_WEI).add(position.accruedFunding)
				const netRpnl = wei(history?.pnlWithFeesPaid ?? ZERO_WEI)
				const leverage = marginInfo.availableMargin.gt(0)
					? currentNotional.div(marginInfo.availableMargin)
					: wei(0)

				acc.push({
					id: history?.id ?? '',
					market: market,
					stopLoss: stopLoss?.targetPrice,
					takeProfit: takeProfit?.targetPrice,
					details: {
						...position,
						id: history?.id ?? '',
						feesPaid: history?.feesPaid ?? wei(0),
						accruedFunding,
						rPnl: {
							pnl: history?.pnl ?? wei(0),
							netPnl: netRpnl,
							netPnlPct: wei(0),
						},
						uPnl: {
							pnl: upnl,
							pnlPct: pnlPct,
						},
						canLiquidatePosition: false,
						liquidationPrice: liqPrice.lt(0) ? wei(0) : liqPrice,
						notionalValue: position.size.mul(markPrice),
						lastPrice: markPrice,
						initialMargin: wei(0),
						leverage: leverage,
						marginRatio: wei(0),
						initialLeverage: wei(0),
						openTimestamp: history?.openTimestamp,
						avgEntryPrice: history?.avgEntryPrice,
					},
				})
			}
			return acc
		}, [])
	}
)

export const selectCrossMarginActivePositions = createSelector(
	selectCrossMarginPositions,
	(positions) => {
		const active = positions.filter((p) => !!p.details) as FuturesPositionTablePositionWithDetails[]
		return active.map((p) => ({
			...p,
			details: {
				...p.details,
				avgEntryPrice: p.details.avgEntryPrice ?? p.details.lastPrice,
				accruedFunding: p.details.accruedFunding.add(p.details.owedInterest),
			},
		}))
	}
)

export const selectSelectedSnxV3Position = createSelector(
	selectCrossMarginActivePositions,
	selectV3SelectedMarketInfo,
	(positions, market) => {
		const position = positions.find((p) => p.market.marketKey === market?.marketKey)
		return position
	}
)

export const selectAsyncCrossMarginOrder = createSelector(
	selectV3Markets,
	selectSelectedCrossMarginAccountData,
	(markets, account) => {
		const order = account?.pendingAsyncOrder
		if (!order) return

		const market = markets.find((m) => m.marketId === order.marketId)

		const strategy = market?.settlementStrategies.find(
			(s) => s.strategyId === order.settlementStrategyId
		)
		if (account && market && strategy) {
			const startTime = Number(order.settlementTime)
			const expirationTime = startTime + Number(strategy.settlementWindowDuration)
			const timePastExecution = Math.floor(Date.now() / 1000 - startTime)
			const executable = timePastExecution <= expirationTime
			const isStale =
				timePastExecution >
				DEFAULT_DELAYED_CANCEL_BUFFER + strategy.settlementWindowDuration.toNumber()

			const orderDetails = {
				market,
				account: Number(account.account),
				size: wei(order.sizeDelta),
				executableStartTime: startTime,
				expirationTime: expirationTime,
				marginDelta: wei(0),
				desiredFillPrice: wei(order.acceptablePrice),
				settlementWindowDuration: strategy.settlementWindowDuration.toNumber(),
				side: order.side,
				isStale: isStale,
				isExecutable: executable,
				settlementFee: strategy.settlementReward,
			}
			return orderDetails
		}
		return
	}
)

export const selectShowCrossMarginOnboard = (state: RootState) =>
	state.app.showModal?.type === 'futures_cross_margin_onboard'

export const selectWithdrawableCrossMargin = createSelector(
	selectCrossMarginActivePositions,
	selectCrossMarginMarginInfo,
	(positions, marginInfo) => {
		if (positions.length === 0) return marginInfo.availableMargin
		return marginInfo.withdrawableMargin.gt(0) ? marginInfo.withdrawableMargin : wei(0)
	}
)

export const selectSnxV3Preview = (state: RootState) =>
	state.futures.tradePreviews[PerpsProvider.SNX_V3_BASE]

export const selectCrossMarginTradePreview = createSelector(
	selectTradePanelInputs,
	selectTradeOrderType,
	selectCrossMarginMarginInfo,
	selectV3SelectedMarketInfo,
	selectSnxV3Preview,
	({ nativeSizeDelta, orderPrice }, orderType, marginInfo, market, preview) => {
		const priceImpact = getDefaultPriceImpact(orderType)

		const desiredFillPrice = calculateDesiredFillPrice(
			nativeSizeDelta,
			wei((orderType === OrderTypeEnum.MARKET ? preview?.fillPrice : orderPrice.price) ?? 0),
			priceImpact
		)

		const unserializedPreview = preview ? unserializeCrossMarginTradePreview(preview) : undefined
		if (!unserializedPreview || !market) return

		const liqPrice = unserializedPreview.fillPrice
			? calculateV3LiqPrice(marginInfo, unserializedPreview, market)
			: wei(0)

		return unserializedPreview
			? {
					...unserializedPreview,
					desiredFillPrice,
					orderType,
					liqPrice,
			  }
			: undefined
	}
)

export const selectCrossMarginTradeableMargin = createSelector(
	selectCrossMarginAvailableMargin,
	selectReservedMarginForOrders,
	selectCrossMarginMarginInfo,
	(availableMargin, reserved, { requiredInitialMargin }) => {
		const tradeble = availableMargin.sub(reserved).sub(requiredInitialMargin)
		return tradeble.gte(0) ? tradeble : wei(0)
	}
)

// TODO: Improve max usd value for perps v3 (waiting for price param on requiredMarginForOrder)
export const selectMaxUsdSizeInputCrossMargin = createSelector(
	selectCrossMarginTradeableMargin,
	selectV3SelectedMarketInfo,
	(tradeableMargin, marketInfo) => {
		if (!marketInfo) return wei(0)
		return tradeableMargin.div(REQUIRED_MARGIN_RATIO)
	}
)

export const selectSnxV3MaxLeverage = createSelector(
	selectMaxUsdSizeInputCrossMargin,
	selectCrossMarginTradeableMargin,
	(maxUsd, tradeableMargin) => {
		if (tradeableMargin.eq(0)) return wei(0)
		const leverage = wei(floorNumber(maxUsd.div(tradeableMargin), 1))
		return leverage.gt(0) ? leverage : wei(0)
	}
)
export const selectPendingAsyncOrdersCount = createSelector(
	selectAsyncCrossMarginOrder,
	(order) => {
		return order && !order.isStale ? 1 : 0
	}
)

export const selectActiveCrossMarginPositionsCount = createSelector(
	selectCrossMarginAccountsData,
	selectUserInfoShowAllMarkets,
	selectMarketKey,
	(accounts, userInfoShowAllMarkets, currentMarket) => {
		const base = accounts[PerpsProvider.SNX_V3_BASE]?.positions ?? []
		const arb = accounts[PerpsProvider.SNX_V3_BASE]?.positions ?? []

		const filter = (p: PerpsV3Position<string>) =>
			userInfoShowAllMarkets ?? p.marketKey === currentMarket
		return {
			[PerpsProvider.SNX_V3_BASE]: base.filter(filter).length,
			[PerpsProvider.SNX_V3_ARB]: arb.filter(filter).length,
		}
	}
)

export const selectV3SkewAdjustedPrice = createSelector(
	selectMarketIndexPrice,
	selectV3SelectedMarketInfo,
	(price, marketInfo) => {
		if (!marketInfo?.marketSkew || !marketInfo?.settings.skewScale) return price
		return price
			? wei(price).mul(wei(marketInfo.marketSkew).div(marketInfo.settings.skewScale).add(1))
			: ZERO_WEI
	}
)
export const selectCollateralBalances = createSelector(
	selectSelectedCrossMarginAccountData,
	(account) => {
		return account?.collateralBalances ?? {}
	}
)

export const selectCrossMarginAccountTrades = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	(account, markets) => {
		const trades = unserializeTrades(account?.trades ?? [])
		return trades.reduce<CrossMarginTrade[]>((acc, t) => {
			const market = markets.find((m) => m.marketId === t.marketId)
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [])
	}
)

export const selectCrossMarginAccountLiquidations = createSelector(
	selectSelectedCrossMarginAccountData,
	selectV3Markets,
	(account, markets) => {
		const liquidations = unserializeV3Liquidations(account?.liquidations ?? [])
		return liquidations.reduce<CrossMarginLiquidation[]>((acc, t) => {
			const market = markets.find((m) => m.marketId === t.marketId)
			if (market) {
				acc.push({
					...t,
					market: market,
				})
			}
			return acc
		}, [])
	}
)

export const selectSnxV3GlobalTradesForMarket = createSelector(
	selectMarketAsset,
	(state: RootState) =>
		state.futures.providerData.globalTradeHistory[PerpsProvider.SNX_V3_BASE] ?? {},
	(marketAsset, tradesHistory) => {
		if (!marketAsset) return []
		return unserializeTrades(tradesHistory[marketAsset] ?? [])
	}
)

export const selectGlobalLiquidationsForMarket = createSelector(
	selectV3SelectedMarketId,
	(state: RootState) =>
		state.futures.providerData.globalLiquidationHistory[PerpsProvider.SNX_V3_BASE] ?? {},
	(marketId, liquidationsHistory) => {
		if (!marketId) return []
		return unserializeV3Liquidations(liquidationsHistory[marketId] ?? [])
	}
)

export const selectIsV3MarketCapReached = createSelector(
	selectLeverageSide,
	selectV3SelectedMarketInfo,
	selectMarketIndexPrice,
	(leverageSide, marketInfo, marketAssetRate) => {
		const side = leverageSide === PositionSide.LONG ? 'long' : 'short'
		const maxMarketValueUSD = marketInfo?.marketLimitUsd[side] ?? wei(0)
		const marketSize = marketInfo?.openInterest[leverageSide] ?? wei(0)

		return leverageSide === PositionSide.LONG
			? marketSize.abs().mul(marketAssetRate).gte(maxMarketValueUSD)
			: marketSize.abs().mul(marketAssetRate).gte(maxMarketValueUSD)
	}
)

export const selectAvailableMarginQueryStatuses = (state: RootState) =>
	state.futures.queryStatuses['get_balance_info']?.status

const calculateKeeperDeposit = (
	tradeType: OrderTypeEnum,
	includesSL: boolean,
	includesTP: boolean,
	existingOrdersCost: Wei,
	keeperBalance: Wei
) => {
	let newOrders = tradeType !== OrderTypeEnum.MARKET ? 1 : 0
	newOrders += includesSL ? 1 : 0
	newOrders += includesTP ? 1 : 0

	const extraGasFee = newOrders * KEEPER_USD_GAS_FEE
	const combined = existingOrdersCost.add(extraGasFee)

	const requiredUSDC = wei(Math.max(combined.toNumber(), MIN_ACCOUNT_KEEPER_USDC_BAL.toNumber()))

	// Calculate required USDC based on pending orders
	const requiredDeposit =
		combined.gt(0) && keeperBalance.lt(requiredUSDC) ? requiredUSDC.sub(keeperBalance) : wei(0)

	// For amounts smaller than USDC decimals we need to round up
	return requiredDeposit.eq(0) || requiredDeposit.gt(KEEPER_USD_GAS_FEE)
		? requiredDeposit
		: wei(KEEPER_USD_GAS_FEE)
}

export const selectTradePanelKeeperDepositV3 = createSelector(
	selectKeeperUSDBalance,
	selectRequiredUsdForPendingOrders,
	selectCrossMarginTradePreview,
	selectSlTpTradeInputs,
	(usdBalance, reservedUsd, preview, { stopLossPriceWei, takeProfitPriceWei }) => {
		if (!preview) return wei(0)
		return calculateKeeperDeposit(
			preview.orderType,
			!!stopLossPriceWei,
			!!takeProfitPriceWei,
			reservedUsd,
			usdBalance
		)
	}
)

export const selectCloseModalKeeperDepositV3 = createSelector(
	selectKeeperUSDBalance,
	selectRequiredUsdForPendingOrders,
	selectClosePositionOrderInputs,
	(usdBalance, reservedUsd, { orderType }) => {
		return calculateKeeperDeposit(orderType, false, false, reservedUsd, usdBalance)
	}
)

export const selectAssetsV3 = createSelector(
	selectBalances,
	selectCollateralBalances,
	selectPrices,
	(walletBalances, marginBalances, prices) => {
		const unFilteredAssets = V3_SYNTH_MARKET_KEYS.map((marketKey) => {
			const assetV2 = v3SynthKeyToV2Asset(marketKey)
			const price =
				assetV2 === 'USDC' || assetV2 === 'sUSD' ? wei(1) : prices[assetV2]?.offChain ?? ZERO_WEI

			return {
				marketKey,
				assetName: assetV2,
				walletBalance: {
					size: walletBalances.tokenBalances[assetV2] || ZERO_WEI,
					value: price.mul(walletBalances.tokenBalances[assetV2] || ZERO_WEI),
				},
				marginBalance: {
					size: wei(marginBalances[marketKey] ?? 0),
					value: price.mul(wei(marginBalances[marketKey] ?? 0)),
				},
			}
		})

		const SUSD = unFilteredAssets.find((asset) => asset.assetName === 'sUSD')
		const USDC = unFilteredAssets.find((asset) => asset.assetName === 'USDC')
		if (USDC) {
			USDC.marginBalance.size = SUSD?.marginBalance.size ?? wei(0)
			USDC.marginBalance.value = SUSD?.marginBalance.value ?? wei(0)
		}

		return unFilteredAssets
			.filter((asset) => asset.assetName !== 'sUSD')
			.filter((asset) => asset.marginBalance.value.gt(0) || asset.walletBalance.value.gt(0))
	}
)
export const selectV3MarginChartData = createSelector(
	selectSelectedCrossMarginAccountData,
	selectCrossMarginAvailableMargin,
	selectSelectedPortfolioTimeframe,
	(accountData, currentMargin, period) => {
		const snapshots = accountData?.marginSnapshots[period]
		if (!snapshots) return []
		const data = snapshots.map((s) => ({
			...s,
			total: wei(s.margin).toNumber(),
			timestamp: s.timestamp * 1000,
		}))
		return [
			...data,
			{
				timestamp: Date.now(),
				total: currentMargin.toNumber(),
			},
		]
	}
)

export const selectCMPlaceOrderDisabledReason = createSelector(
	selectCrossMarginTradePreview,
	selectV3SelectedMarketInfo,
	selectIsV3MarketCapReached,
	selectSelectedSnxV3Position,
	selectTradePanelInputs,
	selectMaxUsdSizeInputCrossMargin,
	selectPendingAsyncOrdersCount,
	selectLeverageSide,
	(
		previewTrade,
		marketInfo,
		isMarketCapReached,
		position,
		{ susdSize },
		maxUsdInputAmount,
		pendingOrderCount,
		leverageSide
	) => {
		const MessageType = {
			error: 'error',
			warn: 'warn',
		} as const
		if (previewTrade?.statusMessage && previewTrade.statusMessage !== 'Success') {
			return { message: previewTrade?.statusMessage, show: MessageType.error }
		}

		if (previewTrade && previewTrade.status !== PotentialTradeStatus.OK) {
			const i18nMessage = previewStatusToI18nMsg(previewTrade.status)
			return { message: i18nMessage, show: MessageType.error }
		}

		const increasingPosition = !position?.details.side || position?.details.side === leverageSide

		// TODO: Validate liquidation from preview
		const canLiquidate = false
		if (canLiquidate) {
			return {
				show: MessageType.warn,
				message: `Position can be liquidated`,
			}
		}

		if (marketInfo?.isSuspended)
			return {
				show: MessageType.warn,
				message: `Market suspended`,
			}
		if (isMarketCapReached && increasingPosition)
			return {
				show: MessageType.warn,
				message: `Open interest limit exceeded`,
			}

		if (susdSize.gt(maxUsdInputAmount))
			return {
				show: MessageType.warn,
				message: 'Max trade size exceeded',
			}

		if (isZero(susdSize)) {
			return { message: 'Trade size required' }
		}
		if (pendingOrderCount) {
			return {
				show: MessageType.warn,
				message: ERROR_MESSAGES.ORDER_PENDING,
			}
		}

		return null
	}
)

export const selectCMPlaceOrderTranslationKey = createSelector(
	selectSelectedSnxV3Position,
	(position) => {
		return position?.details
			? 'futures.market.trade.button.modify-position'
			: 'futures.market.trade.button.open-position'
	}
)

export const selectUsdcMaxDepositAmount = createSelector(
	(state: RootState) => state.futures.snxV3.usdcV3MaxDepositAmount,
	toWei
)
