import { DEFAULT_LEADERBOARD_DATA } from '@kwenta/sdk/constants'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StatsTimeframe } from 'hooks/useStatsData'
import { DEFAULT_QUERY_STATUS, LOADING_STATUS, SUCCESS_STATUS } from 'state/constants'
import { FetchStatus } from 'state/types'

import { fetchV2Leaderboard, fetchV3Leaderboard } from './actions'
import { StatsState } from './types'

export const STATS_INITIAL_STATE: StatsState = {
	queryStatuses: {
		leaderboardV2: DEFAULT_QUERY_STATUS,
		leaderboardV3: DEFAULT_QUERY_STATUS,
	},
	selectedTimeframe: '1M',
	leaderboard: {
		v2: DEFAULT_LEADERBOARD_DATA,
		v3: DEFAULT_LEADERBOARD_DATA,
	},
	leaderboardSearchTerm: '',
}

const statsSlice = createSlice({
	name: 'stats',
	initialState: STATS_INITIAL_STATE,
	reducers: {
		setSelectedTimeframe: (state, action: PayloadAction<StatsTimeframe>) => {
			state.selectedTimeframe = action.payload
		},
		setLeaderboardSearchTerm: (state, action: PayloadAction<string>) => {
			state.leaderboardSearchTerm = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchV2Leaderboard.pending, (state) => {
			state.queryStatuses.leaderboardV2 = LOADING_STATUS
		})
		builder.addCase(fetchV2Leaderboard.rejected, (state) => {
			state.queryStatuses.leaderboardV2 = {
				status: FetchStatus.Error,
				error: 'Failed to fetch leaderboard',
			}
		})
		builder.addCase(fetchV2Leaderboard.fulfilled, (state, action) => {
			state.queryStatuses.leaderboardV2 = SUCCESS_STATUS
			state.leaderboard.v2 = action.payload
		})

		builder.addCase(fetchV3Leaderboard.pending, (state) => {
			state.queryStatuses.leaderboardV3 = LOADING_STATUS
		})
		builder.addCase(fetchV3Leaderboard.rejected, (state) => {
			state.queryStatuses.leaderboardV3 = {
				status: FetchStatus.Error,
				error: 'Failed to fetch leaderboard',
			}
		})
		builder.addCase(fetchV3Leaderboard.fulfilled, (state, action) => {
			state.queryStatuses.leaderboardV3 = SUCCESS_STATUS
			if (action.payload) {
				state.leaderboard.v3 = action.payload
			}
		})
	},
})

export const { setSelectedTimeframe, setLeaderboardSearchTerm } = statsSlice.actions

export default statsSlice.reducer
