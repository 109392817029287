import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/browser'
import { init } from '@socialgouv/matomo-next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { FC, memo, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { WagmiProvider } from 'wagmi'

import '@rainbow-me/rainbowkit/styles.css'
import '@reach/dialog/styles.css'
import { DEFAULT_FONT } from 'constants/ui'
import Connector from 'containers/Connector'
import { wagmiConfig, wagmiConfigWithTestnet } from 'containers/Connector/config'
import useMonitorTransactions from 'hooks/useMonitorTransactions'
import AcknowledgementModal from 'sections/app/AcknowledgementModal'
import MaintenanceModal from 'sections/app/MaintenanceModal'
import Layout from 'sections/shared/Layout'
import SystemStatus from 'sections/shared/SystemStatus'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { useAppData } from 'state/app/hooks'
import { selectShowTestnets } from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'
import store from 'state/store'
import 'styles/main.css'
import { themes } from 'styles/theme'
import { IGNORE_ERRORS } from 'utils/logError'
import { getDesignTokens } from 'utils/theme'
import '../i18n'

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

Sentry.init({
	dsn: 'https://d48644bc80d04977a26132b346417210@o4504363236851712.ingest.sentry.io/4504363261362177',
	maxBreadcrumbs: 50,
	debug: process.env.NODE_ENV !== 'production',
	enabled: process.env.NODE_ENV === 'production',
	release: 'kwenta@' + process.env.GIT_HASH_ID!.toString(),
	autoSessionTracking: true,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.3,
	ignoreErrors: IGNORE_ERRORS,
})

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID

const queryClient = new QueryClient()

const InnerApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
	useEffect(() => {
		if (MATOMO_URL && MATOMO_SITE_ID) {
			init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID })
		}
	}, [])
	const [isReady, setReady] = useState(false)
	const { providerReady } = Connector.useContainer()

	useAppData(providerReady)
	useMonitorTransactions()

	const theme = themes.dark

	// @ts-ignore palette options
	const muiTheme = useMemo(() => createTheme(getDesignTokens('dark')), [])
	useEffect(() => {
		setReady(true)
	}, [])

	const content = useMemo(() => {
		const getLayout = Component.getLayout || ((page) => page)

		return process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ? (
			<MaintenanceModal />
		) : (
			<>
				<AcknowledgementModal />
				<SystemStatus>{getLayout(<Component {...pageProps} />)}</SystemStatus>
			</>
		)
	}, [pageProps, Component])

	return isReady ? (
		<RainbowKitProvider theme={darkTheme()}>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={muiTheme}>
					<div id="root">
						<style jsx global>{`
							* {
								font-family: ${DEFAULT_FONT.style.fontFamily};
							}
						`}</style>
						<Layout>{content}</Layout>
					</div>
				</MuiThemeProvider>
			</ThemeProvider>
		</RainbowKitProvider>
	) : null
}

const WagmiConfigWrapper: FC<AppProps> = memo((props) => {
	const showTestnet = useAppSelector(selectShowTestnets)

	return (
		<WagmiProvider config={showTestnet ? wagmiConfigWithTestnet : wagmiConfig}>
			<Connector.Provider>
				<InnerApp {...props} />
			</Connector.Provider>
		</WagmiProvider>
	)
})

const App: FC<AppProps> = (props) => {
	const { t } = useTranslation()
	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
				/>
				<meta name="title" content={t('meta.og.title')} />
				<meta name="description" content={t('meta.description')} />
				{/* open graph */}
				<meta property="og:title" content={t('meta.og.title')} />
				<meta property="og:description" content={t('meta.description')} />
				<meta property="og:url" content="https://kwenta.eth.limo/" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="/images/kwenta-facebook.jpg" />
				<meta property="og:image:alt" content={t('meta.og.title')} />
				<meta property="og:site_name" content={t('meta.og.site-name')} />
				{/* twitter */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@kwenta_io" />
				<meta name="twitter:creator" content="@kwenta_io" />
				<meta name="twitter:image" content="https://kwenta.eth.limo/images/kwenta-twitter.jpg" />
				<meta name="twitter:url" content="https://kwenta.eth.limo" />
				<link rel="icon" type="image/svg" href="/images/favicon.svg" sizes="32x32" />
				<link rel="icon" type="image/png" href="/images/favicon.png" sizes="32x32" />
				<link rel="apple-touch-icon" href="/images/apple-icon.png" sizes="180x180" />
				<link rel="mask-icon" href="/images/favicon.svg" color="#ffcc00" />
			</Head>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<WagmiConfigWrapper {...props} />
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</Provider>
		</>
	)
}

export default App
