import { CRYPTO_CURRENCY_MAP, V3SynthMarketKey } from '@kwenta/sdk/constants'
import { FuturesMarketKey } from '@kwenta/sdk/types'
import type { StaticImageData } from 'next/image'

import { SynthsName } from 'constants/currency'

import KWENTAIcon from '../../public/images/png/currencies/KWENTA.png'
import ONEINCHIcon from '../../public/images/png/currencies/s1INCH.png'
import AAVEIcon from '../../public/images/png/currencies/sAAVE.png'
import ADAIcon from '../../public/images/png/currencies/sADA.png'
import ALGOIcon from '../../public/images/png/currencies/sALGO.png'
import ANKRIcon from '../../public/images/png/currencies/sANKR.png'
import APEIcon from '../../public/images/png/currencies/sAPECOIN.png'
import APTIcon from '../../public/images/png/currencies/sAPT.png'
import ARBIcon from '../../public/images/png/currencies/sARB.png'
import ARKMIcon from '../../public/images/png/currencies/sARKM.png'
import ATOMIcon from '../../public/images/png/currencies/sATOM.png'
import AUDIcon from '../../public/images/png/currencies/sAUD.png'
import AVAXIcon from '../../public/images/png/currencies/sAVAX.png'
import AXLIcon from '../../public/images/png/currencies/sAXL.png'
import AXSIcon from '../../public/images/png/currencies/sAXS.png'
import BALIcon from '../../public/images/png/currencies/sBAL.png'
import BCHIcon from '../../public/images/png/currencies/sBCH.png'
import BLURIcon from '../../public/images/png/currencies/sBLUR.png'
import BNBIcon from '../../public/images/png/currencies/sBNB.png'
import BOMEIcon from '../../public/images/png/currencies/sBOME.png'
import BONKIcon from '../../public/images/png/currencies/sBONK.png'
import BTCIcon from '../../public/images/png/currencies/sBTC.png'
import BTC2Icon from '../../public/images/png/currencies/sBTC2.png'
import CELOIcon from '../../public/images/png/currencies/sCELO.png'
import CHFIcon from '../../public/images/png/currencies/sCHF.png'
import COMPIcon from '../../public/images/png/currencies/sCOMP.png'
import CRVIcon from '../../public/images/png/currencies/sCRV.png'
import CVXIcon from '../../public/images/png/currencies/sCVX.png'
import DOGEIcon from '../../public/images/png/currencies/sDOGE.png'
import DOTIcon from '../../public/images/png/currencies/sDOT.png'
import DYDXIcon from '../../public/images/png/currencies/sDYDX.png'
import ENAIcon from '../../public/images/png/currencies/sENA.png'
import ENJIcon from '../../public/images/png/currencies/sENJ.png'
import EOSIcon from '../../public/images/png/currencies/sEOS.png'
import ETCIcon from '../../public/images/png/currencies/sETC.png'
import ETHIcon from '../../public/images/png/currencies/sETH.png'
import ETH2Icon from '../../public/images/png/currencies/sETH2.png'
import ETHBTCIcon from '../../public/images/png/currencies/sETHBTC.png'
import ETHFIIcon from '../../public/images/png/currencies/sETHFI.png'
import EURIcon from '../../public/images/png/currencies/sEUR.png'
import FETIcon from '../../public/images/png/currencies/sFETCH.png'
import FILIcon from '../../public/images/png/currencies/sFIL.png'
import FLOKIIcon from '../../public/images/png/currencies/sFLOKI.png'
import FLOWIcon from '../../public/images/png/currencies/sFLOW.png'
import FTMIcon from '../../public/images/png/currencies/sFTM.png'
import FXSIcon from '../../public/images/png/currencies/sFXS.png'
import GalaIcon from '../../public/images/png/currencies/sGALA.png'
import GBPIcon from '../../public/images/png/currencies/sGBP.png'
import GMXIcon from '../../public/images/png/currencies/sGMX.png'
import GRTIcon from '../../public/images/png/currencies/sGRAPH.png'
import ICPIcon from '../../public/images/png/currencies/sICP.png'
import IMXIcon from '../../public/images/png/currencies/sIMX.png'
import INJIcon from '../../public/images/png/currencies/sINJ.png'
import INRIcon from '../../public/images/png/currencies/sINR.png'
import JPYIcon from '../../public/images/png/currencies/sJPY.png'
import JTOIcon from '../../public/images/png/currencies/sJTO.png'
import JUPIcon from '../../public/images/png/currencies/sJUP.png'
import KNCIcon from '../../public/images/png/currencies/sKNC.png'
import KRWIcon from '../../public/images/png/currencies/sKRW.png'
import LDOIcon from '../../public/images/png/currencies/sLDO.png'
import LINKIcon from '../../public/images/png/currencies/sLINK.png'
import LTCIcon from '../../public/images/png/currencies/sLTC.png'
import MATICIcon from '../../public/images/png/currencies/sMATIC.png'
import MAVIcon from '../../public/images/png/currencies/sMAV.png'
import MEMEIcon from '../../public/images/png/currencies/sMEMECOIN.png'
import MKRIcon from '../../public/images/png/currencies/sMKR.png'
import MOGIcon from '../../public/images/png/currencies/sMOG.png'
import NEARIcon from '../../public/images/png/currencies/sNEAR.png'
import SNXIcon from '../../public/images/png/currencies/SNX.png'
import SNXBTC from '../../public/images/png/currencies/snxbtc.png'
import SNXETH from '../../public/images/png/currencies/snxeth.png'
import SNXLINK from '../../public/images/png/currencies/snxlink.png'
import SNXOP from '../../public/images/png/currencies/snxop.png'
import SNXUSD from '../../public/images/png/currencies/snxusd.png'
import OILIcon from '../../public/images/png/currencies/sOIL.png'
import ONEIcon from '../../public/images/png/currencies/sONE.png'
import OPIcon from '../../public/images/png/currencies/sOP.png'
import ORDIIcon from '../../public/images/png/currencies/sORDI.png'
import PENDLEIcon from '../../public/images/png/currencies/sPENDLE.png'
import PEPEIcon from '../../public/images/png/currencies/sPEPE.png'
import PERPIcon from '../../public/images/png/currencies/sPERP.png'
import PYTHIcon from '../../public/images/png/currencies/sPYTH.png'
import RBLIcon from '../../public/images/png/currencies/sRLB.png'
import RNDRIcon from '../../public/images/png/currencies/sRNDR.png'
import RPLIcon from '../../public/images/png/currencies/sRPL.png'
import RUNEIcon from '../../public/images/png/currencies/sRUNE.png'
import SEIIcon from '../../public/images/png/currencies/sSEI.png'
import SHIBIcon from '../../public/images/png/currencies/sSHIB.png'
import SOLIcon from '../../public/images/png/currencies/sSOL.png'
import STETHIcon from '../../public/images/png/currencies/sstETH.png'
import STETHETHIcon from '../../public/images/png/currencies/ssthETH.png'
import STRKIcon from '../../public/images/png/currencies/sSTRK.png'
import STXIcon from '../../public/images/png/currencies/sSTX.png'
import SUIIcon from '../../public/images/png/currencies/sSUI.png'
import SUSHIIcon from '../../public/images/png/currencies/sSUSHI.png'
import TAOIcon from '../../public/images/png/currencies/sTAO.png'
import TIAIcon from '../../public/images/png/currencies/sTIA.png'
import TONIcon from '../../public/images/png/currencies/sTON.png'
import TRBIcon from '../../public/images/png/currencies/sTRB.png'
import TRXIcon from '../../public/images/png/currencies/sTRX.png'
import UMAIcon from '../../public/images/png/currencies/sUMA.png'
import UNIIcon from '../../public/images/png/currencies/sUNI.png'
import USDIcon from '../../public/images/png/currencies/sUSD.png'
import USDJPYIcon from '../../public/images/png/currencies/sUSDJPY.png'
import USDTIcon from '../../public/images/png/currencies/sUSDT.png'
import WIcon from '../../public/images/png/currencies/sW.png'
import WIFIcon from '../../public/images/png/currencies/sWIF.png'
import WLDIcon from '../../public/images/png/currencies/sWLD.png'
import XAGIcon from '../../public/images/png/currencies/sXAG.png'
import XAUIcon from '../../public/images/png/currencies/sXAU.png'
import XLMIcon from '../../public/images/png/currencies/sXLM.png'
import XMRIcon from '../../public/images/png/currencies/sXMR.png'
import XRPIcon from '../../public/images/png/currencies/sXRP.png'
import XTZIcon from '../../public/images/png/currencies/sXTZ.png'
import YFIIcon from '../../public/images/png/currencies/sYFI.png'
import ZECIcon from '../../public/images/png/currencies/sZEC.png'
import ZILIcon from '../../public/images/png/currencies/sZIL.png'
import ZRXIcon from '../../public/images/png/currencies/sZRX.png'
import WBTCIcon from '../../public/images/png/currencies/WBTC.png'
import USDCBlueIcon from '../../public/images/png/tokens/USDC-Blue.png'
import USDCIcon from '../../public/images/png/tokens/USDC.png'

export const SYNTH_ICONS: Record<
	FuturesMarketKey | SynthsName | V3SynthMarketKey | string,
	StaticImageData
> = {
	sAAVEPERP: AAVEIcon,
	sADAPERP: ADAIcon,
	sALGOPERP: ALGOIcon,
	sANKRPERP: ANKRIcon,
	sAPEPERP: APEIcon,
	sAPTPERP: APTIcon,
	sARBPERP: ARBIcon,
	sARKMPERP: ARKMIcon,
	sATOMPERP: ATOMIcon,
	sAUDPERP: AUDIcon,
	sAVAXPERP: AVAXIcon,
	sAXLPERP: AXLIcon,
	sAXSPERP: AXSIcon,
	sBALPERP: BALIcon,
	sBCHPERP: BCHIcon,
	sBLURPERP: BLURIcon,
	sBNBPERP: BNBIcon,
	sBOMEPERP: BOMEIcon,
	sBONKPERP: BONKIcon,
	sBTCPERP: BTCIcon,
	sCELOPERP: CELOIcon,
	sCOMPPERP: COMPIcon,
	sCRVPERP: CRVIcon,
	sCVXPERP: CVXIcon,
	sDOGEPERP: DOGEIcon,
	sDOTPERP: DOTIcon,
	sDYDXPERP: DYDXIcon,
	sENJPERP: ENJIcon,
	sEOSPERP: EOSIcon,
	sETCPERP: ETCIcon,
	sETHBTCPERP: ETHBTCIcon,
	sETHPERP: ETHIcon,
	sETHFIPERP: ETHFIIcon,
	sEURPERP: EURIcon,
	sFETPERP: FETIcon,
	sFILPERP: FILIcon,
	sFLOKIPERP: FLOKIIcon,
	sFLOWPERP: FLOWIcon,
	sFTMPERP: FTMIcon,
	sFXSPERP: FXSIcon,
	sGALAPERP: GalaIcon,
	sGBPPERP: GBPIcon,
	sGMXPERP: GMXIcon,
	sGRTPERP: GRTIcon,
	sICPPERP: ICPIcon,
	sIMXPERP: IMXIcon,
	sINJPERP: INJIcon,
	sJTOPERP: JTOIcon,
	sJUPPERP: JUPIcon,
	sKNCPERP: KNCIcon,
	sLINKPERP: LINKIcon,
	sLDOPERP: LDOIcon,
	sLTCPERP: LTCIcon,
	sMATICPERP: MATICIcon,
	sMAVPERP: MAVIcon,
	sMEMEPERP: MEMEIcon,
	sMKRPERP: MKRIcon,
	sNEARPERP: NEARIcon,
	s1INCHPERP: ONEINCHIcon,
	sONEPERP: ONEIcon,
	sOPPERP: OPIcon,
	sORDIPERP: ORDIIcon,
	sPEPEPERP: PEPEIcon,
	sPENDLEPERP: PENDLEIcon,
	sPERPPERP: PERPIcon,
	sPYTHPERP: PYTHIcon,
	sRNDRPERP: RNDRIcon,
	sRPLPERP: RPLIcon,
	sRUNEPERP: RUNEIcon,
	sSHIBPERP: SHIBIcon,
	sSOLPERP: SOLIcon,
	sSTETHPERP: STETHIcon,
	sSTETHETHPERP: STETHETHIcon,
	sSTRKPERP: STRKIcon,
	sSTXPERP: STXIcon,
	sSUIPERP: SUIIcon,
	sSUSHIPERP: SUSHIIcon,
	sTAOPERP: TAOIcon,
	sTIAPERP: TIAIcon,
	sTRBPERP: TRBIcon,
	sTONPERP: TONIcon,
	sTRXPERP: TRXIcon,
	sUMAPERP: UMAIcon,
	sUNIPERP: UNIIcon,
	sUSDTPERP: USDTIcon,
	sWLDPERP: WLDIcon,
	sXAUPERP: XAUIcon,
	sXAGPERP: XAGIcon,
	sXLMPERP: XLMIcon,
	sXMRPERP: XMRIcon,
	sXRPPERP: XRPIcon,
	sXTZPERP: XTZIcon,
	sYFIPERP: YFIIcon,
	sZECPERP: ZECIcon,
	sZILPERP: ZILIcon,
	sZRXPERP: ZRXIcon,
	sSNXPERP: SNXIcon,
	sWIFPERP: WIFIcon,
	sWPERP: WIcon,
	sENAPERP: ENAIcon,
	sAAVE: AAVEIcon,
	sADA: ADAIcon,
	sAPE: APEIcon,
	sARB: ARBIcon,
	sAPT: APTIcon,
	sATOM: ATOMIcon,
	sAUD: AUDIcon,
	sAVAX: AVAXIcon,
	sAXS: AXSIcon,
	sBCH: BCHIcon,
	sBLUR: BLURIcon,
	sBNB: BNBIcon,
	sBTC: BTCIcon,
	sCHF: CHFIcon,
	sCRV: CRVIcon,
	sDOGE: DOGEIcon,
	sDOGE10PERP: DOGEIcon,
	sDOT: DOTIcon,
	sDYDX: DYDXIcon,
	sETH: ETHIcon,
	sETHBTC: ETHBTCIcon,
	sEUR: EURIcon,
	sFIL: FILIcon,
	sFLOKI: FLOKIIcon,
	sFLOW: FLOWIcon,
	sFTM: FTMIcon,
	sGBP: GBPIcon,
	sGMX: GMXIcon,
	sINJ: INJIcon,
	sINR: INRIcon,
	sJPY: JPYIcon,
	sKRW: KRWIcon,
	sLDO: LDOIcon,
	sLINK: LINKIcon,
	sLTC: LTCIcon,
	sMATIC: MATICIcon,
	sNEAR: NEARIcon,
	sOP: OPIcon,
	sPEPE: PEPEIcon,
	sSEIPERP: SEIIcon,
	sSHIB: SHIBIcon,
	sSOL: SOLIcon,
	sSTETH: STETHIcon,
	sSUI: SUIIcon,
	sTRX: TRXIcon,
	sUNI: UNIIcon,
	sUSD: USDIcon,
	sWTI: OILIcon,
	sXAU: XAUIcon,
	sXAG: XAGIcon,
	sXMR: XMRIcon,
	sXRP: XRPIcon,
	'sBTC²PERP': BTC2Icon,
	'sETH²PERP': ETH2Icon,
	sRLBPERP: RBLIcon,
	sJPYPERP: USDJPYIcon,
	sMOGPERP: MOGIcon,
	KWENTA: KWENTAIcon,
	[CRYPTO_CURRENCY_MAP.SNX]: SNXIcon,
	sSNX: SNXIcon,
	WBTC: WBTCIcon,
	SNXBTC: SNXBTC,
	SNXETH: SNXETH,
	SNXUSD: SNXUSD,
	SNXLINK: SNXLINK,
	SNXOP: SNXOP,
	SNX_USDC: USDCIcon,
	SNX_USD: USDCIcon,
	USDC: USDCBlueIcon,
}
