import { ZERO_WEI } from '@kwenta/sdk/constants'
import Wei, { wei } from '@kwenta/wei'

export type MarketFeesConfig = {
	marketSkew: Wei
	feeRates: {
		makerFee: Wei
		takerFee: Wei
	}
}

export const computeDelayedOrderFee = (
	market: MarketFeesConfig | undefined,
	susdSizeDelta: Wei
) => {
	if (
		!market?.marketSkew ||
		!market?.feeRates.takerFee ||
		!market?.feeRates.makerFee ||
		!susdSizeDelta
	) {
		return {
			commitDeposit: ZERO_WEI,
			delayedOrderFee: ZERO_WEI,
			makerFeeRate: ZERO_WEI,
			takerFeeRate: ZERO_WEI,
		}
	}

	const makerFeeRate = market.feeRates.makerFee
	const takerFeeRate = market.feeRates.takerFee
	const staticRate = sameSide(susdSizeDelta, market.marketSkew) ? takerFeeRate : makerFeeRate

	return {
		commitDeposit: susdSizeDelta.mul(staticRate).abs(),
		delayedOrderFee: susdSizeDelta.mul(staticRate).abs(),
		makerFeeRate,
		takerFeeRate,
	}
}

export const sameSide = (a: Wei, b: Wei) => {
	return a.gt(wei(0)) === b.gt(wei(0))
}
