import {
	EST_TRADE_TX_COST_USDC,
	MIN_ACCOUNT_KEEPER_ETH_BAL,
	MIN_ACCOUNT_KEEPER_USDC_BAL,
	PERIOD_IN_SECONDS,
	ZERO_WEI,
} from '@kwenta/sdk/constants'
import {
	ConditionalOrderV2,
	FuturesMarginType,
	IsolatedMarginPositionHistory,
	OrderTypeEnum,
	Period,
	PerpsProvider,
	PerpsV3PositionHistory,
	PositionSide,
	TransactionStatus,
} from '@kwenta/sdk/types'
import {
	calculateDesiredFillPrice,
	getDefaultPriceImpact,
	getDisplayAsset,
	notNill,
	stripZeros,
	toSnakeCase,
	truncateTimestamp,
} from '@kwenta/sdk/utils'
import Wei, { wei } from '@kwenta/wei'
import { createSelector } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { AbstractionToken } from 'types/accountAbstraction'
import { Address } from 'viem'

import { HistoryTab } from 'sections/dashboard/History/HistoryTabs'
import { Trade } from 'sections/dashboard/History/TradesTable'
import {
	selectShowConditionalOrderModal,
	selectShowModal,
	selectShowPositionModal,
} from 'state/app/selectors'
import { selectEthWalletBalance, selectUsdcBalance } from 'state/balances/selectors'
import { KEEPER_ETH_GAS_FEE, KEEPER_USD_GAS_FEE } from 'state/constants'
import {
	selectActiveCrossMarginPositionsCount,
	selectAllSnxV3SLTPOrders,
	selectCMPlaceOrderDisabledReason,
	selectCMPlaceOrderTranslationKey,
	selectCloseModalKeeperDepositV3,
	selectCrossMarginAccountLiquidations,
	selectCrossMarginAccountTrades,
	selectCrossMarginActivePositions,
	selectCrossMarginAvailableMargin,
	selectCrossMarginMarginInfo,
	selectAllCrossMarginOrderTableItems,
	selectCrossMarginPositions,
	selectGlobalLiquidationsForMarket,
	selectKeeperUSDBalance,
	selectMaxUsdSizeInputCrossMargin,
	selectRequiredKeeperUsdDeposit,
	selectRequiredUsdForPendingOrders,
	selectSelectedSnxV3Position,
	selectSignerSupportsSnxV3Base,
	selectSnxV3AccountContext,
	selectSelectedCrossMarginAccountData,
	selectSnxV3MaxLeverage,
	selectTradePanelKeeperDepositV3,
	selectV3MarginChartData,
	selectV3SelectedMarketInfo,
	selectWithdrawableCrossMargin,
	selectCrossMarginOrderTableItemsForProvider,
	selectSignerSupportsSnxV3Arb,
} from 'state/futures/snxPerpsV3/selectors'
import { selectPrices } from 'state/prices/selectors'
import { RootState } from 'state/store'
import { FetchStatus } from 'state/types'
import {
	selectIsSubAccountMode,
	selectIsWatcherMode,
	selectSignerWallet,
	selectWallet,
} from 'state/wallet/selectors'
import {
	calculateV3LiqPrice,
	formatPositionForTable,
	providerIsCrossMargin,
	shouldFilterHistoryTable,
	shouldFilterHistoryTableByDate,
	shouldFilterUserInfoTable,
	stopLossValidity,
	takeProfitValidity,
	unserializeConditionalOrdersV2,
	unserializeCrossMarginTradePreview,
	unserializeIsolatedMarginPositionHistory,
	unserializeIsolatedMarginTradePreview,
	unserializePerpsV3PositionHistory,
	unserializeTrades,
} from 'utils/futures'

import {
	selectAbstractionEthBalance,
	selectAbstractionUsdcBalance,
	selectAccountData,
	selectEditPositionModalMarket,
	selectIsolatedMaxLeverage,
	selectLeverageSide,
	selectMarketAsset,
	selectMarketIndexPrice,
	selectMarketInfo,
	selectMarketOnchainPrice,
	selectMarkets,
	selectOneClickTradingSelected,
	selectPerpsProvider,
	selectPortfolioChartType,
	selectProviderNetworks,
	selectRawTradePanelInputs,
	selectSelectedPortfolioTimeframe,
	selectSlTpModalInputs,
	selectSlTpTradeInputs,
	selectTradePanelInputs,
	selectTradingMode,
	selectUserInfoShowAllMarkets,
} from './common/selectors'
import {
	selectAccountMarginTransfers,
	selectAllIsolatedConditionalOrders,
	selectAllPerennialSLTPOrders,
	selectAllSmartMarginTrades,
	selectAllSnxV2SLTPOrders,
	selectIdleAccountMargin,
	selectIsolatedAvailableMargin,
	selectIsolatedBalanceInfo,
	selectIsolatedPositionsCount,
	selectIsolatedTradeDisabledReason,
	selectKeeperEthBalance,
	selectMarginDeltaInputValue,
	selectOrderFlowFee,
	selectPerennialActivePositions,
	selectPerennialCalculatedMaxLeverage,
	selectRequiredEthForPendingOrders,
	selectRequiredKeeperEthDeposit,
	selectSMPlaceOrderTranslationKey,
	selectSelectedPerennialPosition,
	selectSelectedSnxV2Position,
	selectSignerSupportsSnxV2Op,
	selectSmartMarginActivePositions,
	selectSnxV2AccountContext,
	selectSnxV2AccountData,
	selectSnxV2TradePreviewKeeperDeposit,
} from './isolatedMargin/selectors'
import { PerpsV2Portfolio } from './isolatedMargin/types'
import {
	selectPerennialAccountContext,
	selectPerennialAccountData,
	selectPerennialAccountTrades,
	selectSignerSupportsPerennialArb,
} from './perennial/selectors'
import { SnxPerpsV3TradePreview } from './snxPerpsV3/types'
import { PortfolioValues, TableFilteredFuturesTabs, TradingModes } from './types'

export const selectQueryStatuses = createSelector(
	(state: RootState) => state.futures,
	(futures) => futures.queryStatuses
)

export const selectTraderPositionHistoryQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_trader_position_history']
)

export const selectMarketsQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_markets']
)

export const selectPositionTradesQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_trades_for_position']
)

export const selectSelectedTrader = (state: RootState) => state.futures.leaderboard.selectedTrader

export const selectLeaderboardPageSize = (state: RootState) => state.futures.leaderboard.pageSize

export const selectShowHistory = (state: RootState) => !!state.futures.preferences.showHistory

export const selectShowFavorites = (state: RootState) => !!state.futures.preferences.showFavorites

export const selectShowOrderLines = (state: RootState) => !!state.futures.preferences.showOrderLines

export const selectShowPositionLines = (state: RootState) =>
	!!state.futures.preferences.showPositionLines

export const selectShowRealizedPnL = (state: RootState) =>
	!!state.futures.preferences.showRealizedPnL

export const selectShowTestnets = (state: RootState) => !!state.futures.preferences.showTestnets

export const selectShowFunding = (state: RootState) => !!state.futures.preferences.showFunding

export const selectPreviewCount = (state: RootState) => state.futures.previewDebounceCount

export const selectProviderAccount = createSelector(
	selectPerpsProvider,
	selectWallet,
	(state: RootState) => state.futures,
	(provider, wallet, futures) => {
		return wallet ? futures.accounts[provider]?.[wallet]?.account : undefined
	}
)

export const selectIsIsolatedMarginProvider = createSelector(
	selectPerpsProvider,
	(provider) => provider === PerpsProvider.SNX_V2_OP || provider === PerpsProvider.PERENNIAL_V2_ARB
)

export const selectAccountContexts = createSelector(
	selectSnxV3AccountContext,
	selectSnxV2AccountContext,
	selectPerennialAccountContext,
	(snxV3AccountContext, snxV2AccountContext, perennialContext) => {
		return {
			[PerpsProvider.SNX_V2_OP]: snxV2AccountContext,
			[PerpsProvider.SNX_V3_BASE]: snxV3AccountContext,
			[PerpsProvider.SNX_V3_ARB]: snxV3AccountContext,
			[PerpsProvider.PERENNIAL_V2_ARB]: perennialContext,
		}
	}
)

export const selectAccountContext = createSelector(
	selectPerpsProvider,
	selectAccountContexts,
	(provider, contexts) => ({
		...contexts[provider],
		provider,
	})
)

export const selectMarketVolumes = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures.providerData,
	(type, data) => {
		return data.dailyMarketVolumes[type] ?? {}
	}
)

export const selectBaseMarketVolumes = createSelector(
	(state: RootState) => state.futures.providerData,
	(data) => {
		return data.dailyMarketVolumes[PerpsProvider.SNX_V3_BASE] ?? {}
	}
)

export const selectOptimismMarketVolumes = createSelector(
	(state: RootState) => state.futures.providerData,
	(data) => {
		return data.dailyMarketVolumes[PerpsProvider.SNX_V2_OP] ?? {}
	}
)

export const selectPerennialMarketVolumes = createSelector(
	(state: RootState) => state.futures.providerData,
	(data) => {
		return data.dailyMarketVolumes[PerpsProvider.PERENNIAL_V2_ARB] ?? {}
	}
)

export const selectPosition = createSelector(
	selectSelectedSnxV2Position,
	selectSelectedSnxV3Position,
	selectSelectedPerennialPosition,
	selectPerpsProvider,
	(v2, v3, perennial, type) => {
		switch (type) {
			case PerpsProvider.SNX_V2_OP:
				return v2
			case PerpsProvider.SNX_V3_BASE:
				return v3
			case PerpsProvider.PERENNIAL_V2_ARB:
				return perennial
			default:
				return
		}
	}
)

export const selectTradeMarginDelta = createSelector(selectTradePanelInputs, ({ marginDelta }) => {
	return wei(marginDelta)
})

export const selectIsolatedMarginAllowanceValid = createSelector(
	selectPerpsProvider,
	selectIsolatedBalanceInfo,
	selectIsolatedAvailableMargin,
	selectTradeMarginDelta,
	(provider, { allowance }, availableMargin, marginDelta) => {
		// Perennial always needs to be pulled from the wallet even when there is idle margin
		// as idle margin gets withdrawn to the market first
		if (provider === PerpsProvider.PERENNIAL_V2_ARB) return wei(allowance || 0).gte(marginDelta)
		const marginDeposit = marginDelta.sub(availableMargin)
		return availableMargin.gte(marginDelta) || wei(allowance || 0).gte(marginDeposit)
	}
)

export const selectPositionHistory = createSelector(
	selectPerpsProvider,
	selectSnxV2AccountData,
	selectPerennialAccountData,
	selectSelectedCrossMarginAccountData,
	(provider, snxV2AccountData, perennialAccountData, snxV3AccountData) => {
		if (provider === PerpsProvider.SNX_V2_OP) {
			return unserializeIsolatedMarginPositionHistory(snxV2AccountData?.positionHistory ?? [])
		} else if (provider === PerpsProvider.PERENNIAL_V2_ARB) {
			return unserializeIsolatedMarginPositionHistory(perennialAccountData?.positionHistory ?? [])
		}
		return unserializePerpsV3PositionHistory(snxV3AccountData?.positionHistory ?? [])
	}
)

export const selectSelectedMarketPositionHistory = createSelector(
	selectMarketAsset,
	selectPositionHistory,
	(marketAsset, positionHistory) => {
		return positionHistory.find(({ asset, isOpen }) => isOpen && asset === marketAsset)
	}
)

export const selectPositionHistoryForSelectedTrader = createSelector(
	selectPerpsProvider,
	(state: RootState) => state.futures,
	(provider, futures) => {
		const { selectedTrader } = futures.leaderboard
		if (!selectedTrader?.trader) return []
		const history = futures.leaderboard.selectedTraderPositionHistory[provider] ?? {}
		switch (provider) {
			case PerpsProvider.PERENNIAL_V2_ARB:
			case PerpsProvider.SNX_V2_OP:
				return unserializeIsolatedMarginPositionHistory(
					(history[selectedTrader.trader] as IsolatedMarginPositionHistory<string>[]) ?? []
				)
			case PerpsProvider.SNX_V3_BASE:
				return unserializePerpsV3PositionHistory(
					(history[selectedTrader.trader] as PerpsV3PositionHistory<string>[]) ?? []
				)
			default:
				return []
		}
	}
)

export const selectActivePositions = createSelector(
	selectPerpsProvider,
	selectSmartMarginActivePositions,
	selectCrossMarginActivePositions,
	selectPerennialActivePositions,
	(selectedProvider, smartPositions, crossPositions, perennialPositions) => {
		switch (selectedProvider) {
			case PerpsProvider.SNX_V3_BASE:
				return crossPositions
			case PerpsProvider.SNX_V2_OP:
				return smartPositions
			case PerpsProvider.PERENNIAL_V2_ARB:
				return perennialPositions
			default:
				return []
		}
	}
)

export const selectUsersPositionHistory = createSelector(
	selectAccountData,
	selectPerpsProvider,
	(accountData, type) => {
		const history = accountData?.positionHistory ?? []

		switch (type) {
			case PerpsProvider.SNX_V2_OP:
				return unserializeIsolatedMarginPositionHistory(
					history as IsolatedMarginPositionHistory<string>[]
				)
			case PerpsProvider.SNX_V3_BASE:
				return unserializePerpsV3PositionHistory(history as PerpsV3PositionHistory<string>[])
			case PerpsProvider.PERENNIAL_V2_ARB:
				return unserializeIsolatedMarginPositionHistory(
					history as IsolatedMarginPositionHistory<string>[]
				)
			default:
				return []
		}
	}
)

export const selectTotalUnrealizedPnl = createSelector(selectActivePositions, (positions) => {
	return positions.reduce((acc, p) => {
		return acc.add(p?.details?.uPnl.pnl ?? ZERO_WEI)
	}, ZERO_WEI)
})

export const selectSubmittingFuturesTx = createSelector(
	(state: RootState) => state.app,
	(app) => {
		return (
			app.transaction?.status === TransactionStatus.AwaitingExecution ||
			app.transaction?.status === TransactionStatus.Executed
		)
	}
)

export const selectIsSubmittingCrossTransfer = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return (
			(app.transaction?.type === 'deposit_smart_margin' ||
				app.transaction?.type === 'withdraw_isolated_margin') &&
			submitting
		)
	}
)

export const selectIsApprovingCrossDeposit = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return app.transaction?.type === 'approve_cross_margin' && submitting
	}
)

export const selectIsSubmittingIsolatedTransfer = createSelector(
	selectSubmittingFuturesTx,
	(state: RootState) => state.app,
	(submitting, app) => {
		return (
			(app.transaction?.type === 'deposit_cross_margin' ||
				app.transaction?.type === 'withdraw_cross_margin' ||
				app.transaction?.type === 'approve_cross_margin') &&
			submitting
		)
	}
)

export const selectMaxLeverage = createSelector(
	selectSnxV3MaxLeverage,
	selectIsolatedMaxLeverage,
	selectPerennialCalculatedMaxLeverage,
	selectPerpsProvider,
	(cmMax, isolatedMax, perennialCalMaxLeverage, type) => {
		switch (type) {
			case PerpsProvider.SNX_V3_BASE:
				return cmMax
			case PerpsProvider.SNX_V2_OP:
				return wei(isolatedMax)
			case PerpsProvider.PERENNIAL_V2_ARB:
				return Wei.min(perennialCalMaxLeverage, isolatedMax)
			default:
				return wei(0)
		}
	}
)

export const selectSelectedInputDenomination = (state: RootState) =>
	state.futures.preferences.selectedInputDenomination

export const selectSelectedInputHours = (state: RootState) =>
	state.futures.preferences.selectedInputHours

export const selectNewTradeHasSlTp = createSelector(
	selectTradePanelInputs,
	(tradeInputs) => tradeInputs.stopLossPrice?.gt(0) || tradeInputs.takeProfitPrice?.gt(0)
)

export const selectTradePrice = createSelector(
	selectRawTradePanelInputs,
	selectMarketIndexPrice,
	(inputs, indexPrice) => {
		return wei(inputs.orderPrice.price || indexPrice)
	}
)

export const selectTradeInputPriceString = createSelector(selectRawTradePanelInputs, (inputs) => {
	return inputs.orderPrice.price ?? ''
})

export const selectTradeSizeInputsDisabled = createSelector(
	selectAccountData,
	selectMarginDeltaInputValue,
	selectPerpsProvider,
	selectCrossMarginAvailableMargin,
	selectTradePanelInputs,
	(accountData, marginDeltaInput, perpsProvider, availableMargin, { orderType, orderPrice }) => {
		if (
			orderType !== OrderTypeEnum.MARKET &&
			(!orderPrice?.price || orderPrice?.price?.eq(0) || !!orderPrice?.invalidLabel)
		)
			return true
		if (!accountData?.account) return false
		const remaining = providerIsCrossMargin(perpsProvider)
			? availableMargin || ZERO_WEI
			: wei(marginDeltaInput || 0)
		return remaining.lte(0)
	}
)

export const selectFuturesPortfolio = createSelector(
	selectSmartMarginActivePositions,
	selectCrossMarginPositions,
	selectIdleAccountMargin,
	(smartPositions, crossPositions, idleMargin) => {
		// TODO: Update this for cross margin
		const crossValue =
			crossPositions.reduce((sum, { remainingMargin }) => sum.add(remainingMargin ?? 0), wei(0)) ??
			wei(0)
		const smartValue =
			smartPositions.reduce((sum, { remainingMargin }) => sum.add(remainingMargin), wei(0)) ??
			wei(0)
		const totalValue = smartValue.add(crossValue).add(idleMargin)

		return {
			total: totalValue,
			smartMargin: smartValue.add(idleMargin),
			crossMargin: crossValue,
		}
	}
)

export const selectSmartMarginTransfers = createSelector(selectSnxV2AccountData, (account) => {
	return account?.marketMarginTransfers ?? []
})

export const selectMarginTransfers = createSelector(
	selectAccountData,
	(accountData) => accountData?.marketMarginTransfers ?? []
)

export const selectMarketMarginTransfers = createSelector(
	selectMarginTransfers,
	selectMarketAsset,
	(state: RootState) => state.futures,
	(transfers, asset) => {
		return transfers.filter((o) => o.asset === asset)
	}
)

export const selectSmartMarginPortfolioValues = createSelector(
	selectAllSmartMarginTrades,
	selectSmartMarginTransfers,
	selectAccountMarginTransfers,
	selectFuturesPortfolio,
	(trades, transfers, accountTransfers, portfolioTotal) => {
		const tradeActions = trades.map(({ account, timestamp, asset, margin }) => ({
			account,
			timestamp,
			asset,
			margin: margin?.toNumber() ?? 0,
			size: 0,
			type: 'trade',
		}))

		const transferActions = transfers.map(({ account, timestamp, asset, size }) => ({
			account,
			timestamp,
			asset,
			size,
			margin: 0,
			type: 'market_transfer',
		}))

		const accountTransferActions = accountTransfers.map(({ account, timestamp, asset, size }) => ({
			account,
			timestamp,
			asset,
			size,
			margin: 0,
			type: 'account_transfer',
		}))

		const actions = [...tradeActions, ...transferActions, ...accountTransferActions]
			.filter((action) => !!action)
			.sort((a, b) => {
				if (a.timestamp === b.timestamp) return a.type === 'account_transfer' ? -1 : 1
				return a.timestamp - b.timestamp
			})

		const accountHistory = actions.reduce((acc, action) => {
			if (acc.length === 0) {
				const newTotal = action.size !== 0 ? action.size : action.margin
				const lastAction =
					action.type === 'account_transfer' || !action.asset
						? {
								account: action.account,
								timestamp: action.timestamp,
								assets: {},
								idle: newTotal,
								total: newTotal,
						  }
						: {
								account: action.account,
								timestamp: action.timestamp,
								assets: {
									[action.asset]: newTotal,
								},
								idle: 0,
								total: newTotal,
						  }

				return [lastAction]
			} else {
				const lastAction = acc[acc.length - 1]
				const newAssets = !action.asset
					? lastAction.assets
					: {
							...lastAction.assets,
							[action.asset]:
								action.size !== 0
									? (lastAction.assets[action.asset] ?? 0) + action.size
									: action.margin,
					  }
				const newIdle = !action.asset
					? lastAction.idle + action.size
					: lastAction.idle + action.size * -1

				const newTotal =
					Object.entries(newAssets).reduce((acc, asset) => acc + asset[1], 0) + lastAction.idle

				const newAction = {
					...lastAction,
					timestamp: action.timestamp,
					assets: newAssets,
					idle: newIdle,
					total: newTotal,
				}
				const replacePrevious = newAction.timestamp === lastAction.timestamp

				return [...acc.slice(0, acc.length - (replacePrevious ? 1 : 0)), newAction]
			}
		}, [] as PerpsV2Portfolio[])
		return [
			...accountHistory.map(({ timestamp, total }) => ({ timestamp: timestamp * 1000, total })),
			{
				timestamp: Date.now(),
				total: portfolioTotal.smartMargin.toNumber(),
			},
		]
	}
)

export const selectPnlChartData = createSelector(selectAccountData, (accountData) => {
	if (!accountData || accountData.provider === PerpsProvider.SNX_V2_OP) return []
	return accountData.pnlSnapshots.map((s) => ({
		total: wei(s.pnl).toNumber(),
		timestamp: s.timestamp * 1000,
	}))
})

const INTERPOLATION_GAPS: Partial<Record<Period, number>> = {
	[Period.ONE_WEEK]: PERIOD_IN_SECONDS[Period.ONE_HOUR],
	[Period.ONE_MONTH]: PERIOD_IN_SECONDS[Period.ONE_HOUR] * 6,
	[Period.ONE_YEAR]: PERIOD_IN_SECONDS[Period.ONE_DAY],
}

export const selectChartData = createSelector(
	selectPerpsProvider,
	selectPortfolioChartType,
	selectPnlChartData,
	selectV3MarginChartData,
	selectSmartMarginPortfolioValues,
	(provider, type, pnl, margin, smartMargin) => {
		if (providerIsCrossMargin(provider)) return type === 'pnl' ? pnl : margin
		if (provider === PerpsProvider.SNX_V2_OP) return smartMargin
		if (provider === PerpsProvider.PERENNIAL_V2_ARB) return pnl
		return []
	}
)

export const selectPortfolioChartData = createSelector(
	selectPerpsProvider,
	selectChartData,
	selectSelectedPortfolioTimeframe,
	(provider, chartData, timeframe) => {
		// get the timeframe for interpolation
		const interpolationGap = INTERPOLATION_GAPS[timeframe] ?? PERIOD_IN_SECONDS[Period.ONE_WEEK]

		const minTimestamp = Date.now() - PERIOD_IN_SECONDS[timeframe] * 1000

		const createPortfolioData = (portfolioValues: PortfolioValues[]) => {
			if (portfolioValues.length === 0) return []
			const filteredPortfolioValues = portfolioValues.filter(
				({ timestamp }) => timestamp >= minTimestamp
			)

			const portfolioData: PortfolioValues[] = []
			const totalCount = filteredPortfolioValues.length
				? filteredPortfolioValues.length > 1
					? filteredPortfolioValues.length
					: 2
				: 0
			for (let i = 0; i < totalCount; i++) {
				if (i < totalCount - 1) {
					const index = filteredPortfolioValues.length > 1 ? i : 0
					const currentTimestamp = truncateTimestamp(
						filteredPortfolioValues[index].timestamp,
						interpolationGap * 1000
					)
					const nextTimestamp = truncateTimestamp(
						filteredPortfolioValues[index + 1]?.timestamp ?? minTimestamp,
						interpolationGap * 1000
					)
					const timeDiff = nextTimestamp - currentTimestamp

					if (nextTimestamp !== currentTimestamp) {
						portfolioData.push({
							timestamp: currentTimestamp,
							total: filteredPortfolioValues[index].total,
						})
					}
					if (timeDiff > interpolationGap * 1000) {
						const gapCount = Math.floor(timeDiff / (interpolationGap * 1000)) - 1
						for (let j = 1; j <= gapCount; j++) {
							portfolioData.push({
								timestamp: currentTimestamp + j * interpolationGap * 1000,
								total: filteredPortfolioValues[index].total,
							})
						}
					}
				}
			}

			if (
				provider !== PerpsProvider.PERENNIAL_V2_ARB ||
				(portfolioData.length === 1 &&
					portfolioValues[portfolioValues.length - 1]?.timestamp !== portfolioData[0]?.timestamp)
			) {
				portfolioData.push(portfolioValues[portfolioValues.length - 1])
			}

			return portfolioData
		}

		return createPortfolioData(chartData)
	}
)

export const selectMaxUsdSizeInput = createSelector(
	selectPerpsProvider,
	selectMaxUsdSizeInputCrossMargin,
	selectMaxLeverage,
	selectMarginDeltaInputValue,
	(futuresType, maxCrossMarginInput, maxLeverage, marginDelta) => {
		switch (futuresType) {
			case PerpsProvider.SNX_V3_BASE:
				return maxCrossMarginInput
			case PerpsProvider.SNX_V2_OP:
			case PerpsProvider.PERENNIAL_V2_ARB:
				return maxLeverage.mul(marginDelta || 0)
			default:
				return wei(0)
		}
	}
)

export const selectAvailableOi = createSelector(selectMarketInfo, (marketInfo) => {
	const availableOiUsdShort =
		marketInfo?.marketLimitUsd.short.sub(marketInfo.openInterest.shortUSD) ?? wei(0)

	const availableOiUsdLong =
		marketInfo?.marketLimitUsd.long.sub(marketInfo.openInterest.longUSD) ?? wei(0)

	const availableOiNativeShort =
		marketInfo?.marketLimitNative.short.sub(marketInfo.openInterest.short) ?? wei(0)

	const availableOiNativeLong =
		marketInfo?.marketLimitNative.long.sub(marketInfo.openInterest.long) ?? wei(0)

	return {
		short: {
			usd: availableOiUsdShort,
			native: availableOiNativeShort,
		},
		long: {
			usd: availableOiUsdLong,
			native: availableOiNativeLong,
		},
	}
})

export const selectHistoricalFundingRatePeriod = (state: RootState) =>
	state.futures.preferences.historicalFundingRatePeriod

export const selectLeaderboardTableData = createSelector(
	selectActivePositions,
	selectPositionHistoryForSelectedTrader,
	selectPerpsProvider,
	(positions, history, provider) => formatPositionForTable(positions, history, provider)
)

export const selectPositionsHistoryTableData = createSelector(
	selectActivePositions,
	selectUsersPositionHistory,
	selectPerpsProvider,
	(positions, history, provider) => formatPositionForTable(positions, history, provider)
)

export const selectCMTradesHistoryTableData = createSelector(
	selectMarketAsset,
	selectCrossMarginAccountTrades,
	selectCrossMarginAccountLiquidations,
	selectUserInfoShowAllMarkets,
	(state: RootState) => state.futures.preferences.userInfoTableFilter['trades'],
	(marketAsset, trades, liquidations, userInfoShowAllMarkets, tableFilter) => {
		const liquidationTrades = liquidations.map((liquidation) => {
			return {
				market: {
					asset: marketAsset,
					marketKey: liquidation.market.marketKey,
					marketName: liquidation.market.marketName,
				},
				pnl: null,
				feesPaid: null,
				funding: null,
				netPnl: null,
				side: null,
				notionalValue: null,
				price: liquidation.estimatedPrice,
				amount: liquidation.amount.abs(),
				timestamp: liquidation.timestamp * 1000,
				settlementTxHash: null,
				committedTxHash: null,
				displayAsset: getDisplayAsset(liquidation?.market?.asset),
				orderType: 'Liquidation',
			}
		})
		const normalTrades = trades.map((trade) => {
			const pnl = wei(trade.pnl)
			const feesPaid = trade.totalFees
			const funding = trade.fundingAccrued

			return {
				market: {
					asset: marketAsset,
					marketKey: trade.market.marketKey,
					marketName: trade.market.marketName,
				},
				pnl,
				feesPaid,
				funding,
				netPnl: trade.pnlWithFeesPaid,
				side: trade.sizeDelta.lt(0) ? PositionSide.SHORT : PositionSide.LONG,
				notionalValue: trade?.fillPrice.mul(trade?.sizeDelta.abs()),
				price: trade.fillPrice,
				amount: trade.sizeDelta.abs(),
				timestamp: trade.timestamp * 1000,
				settlementTxHash: trade.settlementTxHash,
				committedTxHash: trade.commitmentTxHash,
				displayAsset: getDisplayAsset(trade?.market?.asset),
				interestCharged: trade.interestCharged,
				orderType: 'Market',
			}
		})
		return [...normalTrades, ...liquidationTrades]
			.filter((t) => t.market.asset === marketAsset || userInfoShowAllMarkets)
			.filter((t) => shouldFilterUserInfoTable(new Date(t.timestamp), tableFilter))
			.sort((a, b) => b.timestamp - a.timestamp)
	}
)

export const selectSMTradesHistoryTableData = createSelector(
	selectAllSmartMarginTrades,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(state: RootState) => state.futures.preferences.userInfoTableFilter['trades'],
	(history, userInfoShowAllMarkets, marketAsset, tableFilter) => {
		return history
			.map((trade) => {
				const pnl = trade.pnl
				const feesPaid = trade.totalFees
				const funding = trade.fundingAccrued
				const netPnl = pnl.sub(wei(feesPaid)).add(wei(funding))
				const notionalValue = trade?.fillPrice.mul(trade?.sizeDelta.abs())

				return {
					side: trade.side,
					price: trade.fillPrice,
					market: {
						asset: marketAsset,
						marketKey: trade.market.marketKey,
						marketName: trade.market.marketName,
					},
					pnl,
					feesPaid,
					funding,
					netPnl,
					notionalValue,
					amount: trade.sizeDelta.abs(),
					timestamp: trade.timestamp * 1000,
					settlementTxHash: trade.txnHash,
					committedTxHash: trade.commitmentTxHash,
					displayAsset: getDisplayAsset(trade?.asset),
					orderType: trade?.orderType,
				}
			})
			.filter((t) => t.market.asset === marketAsset || userInfoShowAllMarkets)
			.filter((t) => shouldFilterUserInfoTable(new Date(t.timestamp), tableFilter))
	}
)

export const selectPerennialTradesHistoryTableData = createSelector(
	selectPerennialAccountTrades,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(state: RootState) => state.futures.preferences.userInfoTableFilter['trades'],
	(history, userInfoShowAllMarkets, marketAsset, tableFilter) => {
		return history
			.map((trade) => {
				const pnl = trade.pnl
				const feesPaid = trade.totalFees
				const funding = trade.fundingAccrued
				const netPnl = trade.pnlWithFeesPaid
				const notionalValue = trade?.fillPrice.mul(trade?.sizeDelta.abs())

				return {
					side: trade.side,
					price: trade.fillPrice,
					market: {
						asset: marketAsset,
						marketKey: trade.market.marketKey,
						marketName: trade.market.marketName,
					},
					pnl,
					feesPaid,
					funding,
					interestCharged: trade.interestCharged,
					netPnl,
					notionalValue,
					amount: trade.sizeDelta.abs(),
					timestamp: trade.timestamp * 1000,
					settlementTxHash: trade.txnHash,
					committedTxHash: trade.commitmentTxHash,
					displayAsset: getDisplayAsset(trade?.asset),
					orderType: trade?.orderType,
				}
			})
			.filter((t) => t.market.asset === marketAsset || userInfoShowAllMarkets)
			.filter((t) => shouldFilterUserInfoTable(new Date(t.timestamp), tableFilter))
	}
)

export const selectTradesHistoryTableData = createSelector(
	selectPerpsProvider,
	selectCMTradesHistoryTableData,
	selectSMTradesHistoryTableData,
	selectPerennialTradesHistoryTableData,
	(type, cmHistory, smHistory, perennialHistory) => {
		switch (type) {
			case PerpsProvider.SNX_V2_OP:
				return smHistory
			case PerpsProvider.SNX_V3_BASE:
				return cmHistory
			case PerpsProvider.PERENNIAL_V2_ARB:
				return perennialHistory
			default:
				return []
		}
	}
)

export const selectPositionTradesTableData = (state: RootState, positionId: string) => {
	const accountData = selectAccountData(state)
	const markets = selectMarkets(state)
	const trades = get(accountData?.tradesByPosition, positionId, [])
	return trades.reduce<Trade[]>((acc, trade) => {
		const market = markets.find((m) =>
			providerIsCrossMargin(m.provider) ? m.marketId === trade.marketId : m.asset === trade.asset
		)

		if (!market) return acc

		acc.push({
			market: {
				asset: market.asset,
				marketKey: market.marketKey,
				marketName: market.marketName,
			},
			pnl: wei(trade.pnl),
			netPnl: wei(trade.pnlWithFeesPaid),
			side: trade.side,
			price: wei(trade.fillPrice),
			amount: wei(trade.sizeDelta).abs(),
			timestamp: trade.timestamp * 1000,
			settlementTxHash: trade.settlementTxHash || trade.txnHash,
			committedTxHash: trade.commitmentTxHash,
			displayAsset: getDisplayAsset(market.asset),
			interestCharged: trade.interestCharged ? wei(trade.interestCharged) : undefined,
			orderType: 'Market',
			feesPaid: wei(trade.totalFees),
			funding: wei(trade.fundingAccrued),
		})
		return acc
	}, [])
}

export const selectSelectedDelegationAddress = (state: RootState) =>
	state.futures.delegation.selectedDelegationAddress as Address

export const selectNewNickname = (state: RootState) => state.futures.delegation.newNickname

export const selectAddressBook = createSelector(
	selectSignerWallet,
	(state: RootState) => state.futures.delegation.addressBook,
	(wallet, addressBook) => {
		if (!wallet) return []
		return addressBook[wallet] ?? []
	}
)

export const selectSessionInfo = (state: RootState) => state.futures.oneClickTrading.sessionInfo

export const selectSessionExpiry = (state: RootState) =>
	(!!state.futures.oneClickTrading.sessionInfo &&
		state.futures.oneClickTrading.sessionInfo.validUntil) ||
	0
export const selectAbstractionDelegated = (state: RootState) =>
	state.futures.oneClickTrading.accountDelegated

export const selectAbstractionTxCost = (state: RootState) => state.futures.oneClickTrading.estTxCost

export const selectAbstractionUsedToken = createSelector(selectAccountContext, (context) => {
	// TODO: SNX ARB
	return context.provider === PerpsProvider.SNX_V3_BASE
		? AbstractionToken.USDC
		: AbstractionToken.ETH
})

export const selectShowOneClickTradingOnboard = (state: RootState) =>
	state.app.showModal?.type === 'one_click_trading_onboard'

export const selectIsSponsoredProvider = createSelector(
	selectAccountContext,
	({ provider }) => provider === PerpsProvider.PERENNIAL_V2_ARB
)

export const selectDelegationTradingWarning = createSelector(
	selectTradingMode,
	selectIsSubAccountMode,
	(mode, isSubAccountMode) => mode === TradingModes.ONE_CLICK && isSubAccountMode
)

export const selectHistoricalFundingRate = createSelector(
	selectPerpsProvider,
	(state: RootState) => state,
	(type, state) => state.futures.providerData.historicalFundingRates[type] ?? {}
)

export const selectSessionActive = (state: RootState) =>
	state.futures.oneClickTrading.isSessionActive

export const selectSelectedChart = (state: RootState) => state.futures.preferences.selectedChart
export const selectAccountDataBySignerWallet = createSelector(
	selectSignerWallet,
	selectPerpsProvider,
	selectProviderNetworks,
	(state: RootState) => state.futures.accounts,
	(wallet, provider, networks, accounts) => {
		const accountData = wallet ? accounts[provider][wallet] : undefined
		return accountData?.network === networks[provider] ? accountData : undefined
	}
)

export const selectDelegatesForAccount = createSelector(
	selectAccountDataBySignerWallet,
	(accountData) => accountData?.delegates ?? []
)

export const selectSubAccountsForAccount = createSelector(
	selectSignerWallet,
	(state: RootState) => state.futures.delegation.subAccountsForWallet,
	(wallet, delegates) => {
		if (!wallet || !delegates) return []
		return delegates[wallet] ?? []
	}
)

export const selectEditPositionModalInfo = createSelector(
	selectEditPositionModalMarket,
	selectActivePositions,
	selectPrices,
	selectAccountData,
	(modalMarketKey, positions, prices, accountData) => {
		const position = positions.find((p) => p.market.marketKey === modalMarketKey)
		if (!position)
			return { position: null, market: null, marketPrice: wei(0), onChainPrice: wei(0) }
		const price = prices[position.market.asset] ?? {
			offChain: wei(0),
			onChain: wei(0),
		}
		const marketPrice =
			!providerIsCrossMargin(position.market.provider) && position?.market?.settings?.skewScale
				? wei(price.offChain).mul(
						wei(position.market.marketSkew).div(position.market.settings.skewScale).add(1)
				  )
				: price.offChain ?? wei(0)

		return {
			position,
			market: position.market,
			indexPrice: price.offChain,
			marketPrice: marketPrice,
			onChainPrice: price.onChain ?? wei(0),
			accountId: accountData?.account,
		}
	}
)

export const selectTradePreviewKeeperDeposit = createSelector(
	selectSnxV2TradePreviewKeeperDeposit,
	selectTradePanelKeeperDepositV3,
	selectPerpsProvider,
	(keeperDeposit, crossMarginDeposit, perpsProvider) => {
		return providerIsCrossMargin(perpsProvider) ? crossMarginDeposit : keeperDeposit
	}
)

export const selectCloseModalKeeperDeposit = createSelector(
	selectCloseModalKeeperDepositV3,
	selectSnxV2TradePreviewKeeperDeposit,
	selectPerpsProvider,
	(closeModalKeeperDeposit, smartMarginKeeperDeposit, perpsProvider) => {
		return providerIsCrossMargin(perpsProvider) ? closeModalKeeperDeposit : smartMarginKeeperDeposit
	}
)

export const selectSLTPModalKeeperDeposit = createSelector(
	selectPerpsProvider,
	selectKeeperUSDBalance,
	selectKeeperEthBalance,
	selectRequiredUsdForPendingOrders,
	selectRequiredEthForPendingOrders,
	selectAllSnxV3SLTPOrders,
	selectAllSnxV2SLTPOrders,
	selectSlTpModalInputs,
	selectEditPositionModalInfo,
	(
		provider,
		usdBalance,
		ethBalance,
		reservedUsd,
		resrvedEth,
		v3Orders,
		v2Orders,
		inputs,
		marketInfo
	) => {
		if (provider === PerpsProvider.PERENNIAL_V2_ARB) return wei(0)
		const orders = provider === PerpsProvider.SNX_V3_BASE ? v3Orders : v2Orders
		const reservedFees = provider === PerpsProvider.SNX_V3_BASE ? reservedUsd : resrvedEth
		const balance = provider === PerpsProvider.SNX_V3_BASE ? usdBalance : ethBalance
		const minBalance =
			provider === PerpsProvider.SNX_V3_BASE
				? MIN_ACCOUNT_KEEPER_USDC_BAL
				: MIN_ACCOUNT_KEEPER_ETH_BAL
		const stopLoss = orders.find(
			(o) => o.marketKey === marketInfo?.market?.marketKey && o.orderType === OrderTypeEnum.STOP
		)
		const takeProfit = orders.find(
			(o) => o.marketKey === marketInfo?.market?.marketKey && o.orderType === OrderTypeEnum.LIMIT
		)

		const newTakeProfit = inputs.takeProfitPrice
		const newStopLoss = inputs.stopLossPrice

		const hasExistingSL = !!stopLoss
		const hasExistingTP = !!takeProfit

		let requiredDeposits = newTakeProfit && !hasExistingTP ? 1 : 0
		requiredDeposits += newStopLoss && !hasExistingSL ? 1 : 0

		const baseFee = provider === PerpsProvider.SNX_V3_BASE ? KEEPER_USD_GAS_FEE : KEEPER_ETH_GAS_FEE

		const extraGas = wei(baseFee).mul(requiredDeposits)
		const combined = reservedFees.add(extraGas)
		const requiredDeposit = combined.gt(0) && balance.lt(combined) ? combined.sub(balance) : wei(0)

		if ((newTakeProfit || newStopLoss) && balance.add(requiredDeposit).lt(minBalance))
			return minBalance.sub(balance)

		return requiredDeposit
	}
)

export const selectKeeperUsdDepositExceedsBal = createSelector(
	selectTradePanelKeeperDepositV3,
	selectSLTPModalKeeperDeposit,
	selectShowPositionModal,
	selectWithdrawableCrossMargin,
	selectUsdcBalance,
	(tradePanelDeposit, sltpDeposit, modal, availableMargin, walletUsdBalance) => {
		const deposit =
			modal?.type === 'futures_edit_stop_loss_take_profit' ? sltpDeposit : tradePanelDeposit
		// TODO: Allow to pull from 1CT gas tank when 1CT is hooked up and USDC paymaster supported
		return deposit.gt(walletUsdBalance.add(availableMargin))
	}
)

export const selectKeeperDepositExceedsAbstractionBal = createSelector(
	selectPerpsProvider,
	selectAbstractionUsdcBalance,
	selectAbstractionEthBalance,
	selectSLTPModalKeeperDeposit,
	selectRequiredKeeperUsdDeposit,
	selectShowPositionModal,
	(state: RootState) => state.futures.tradePreviews,
	(provider, abstractionUsdcBal, abstractionEthBal, sltpDeposit, v3Deposit, modal, previews) => {
		const bal = provider === PerpsProvider.SNX_V3_BASE ? abstractionUsdcBal : abstractionEthBal
		let deposit = wei(0)
		if (modal?.type === 'futures_edit_stop_loss_take_profit') {
			deposit = sltpDeposit
		} else {
			const preview = previews[provider]
			if (!preview) return false
			deposit =
				preview.marginType === FuturesMarginType.CROSS_MARGIN
					? v3Deposit
					: wei(preview.keeperEthDeposit)
		}
		const min = provider === PerpsProvider.SNX_V3_BASE ? wei(EST_TRADE_TX_COST_USDC) : wei(0.002)
		// Leave a small buffer after any deposit taken
		return deposit.gt(0) && wei(bal).sub(deposit).lt(min)
	}
)

export const selectKeeperDepositExceedsBal = createSelector(
	selectPerpsProvider,
	selectKeeperUsdDepositExceedsBal,
	selectTradePreviewKeeperDeposit,
	selectEthWalletBalance,
	selectOneClickTradingSelected,
	selectKeeperDepositExceedsAbstractionBal,
	(type, v3ExceedsBalance, deposit, walletEthBalance, oneClickSelected, exceedsAbstractionBal) => {
		if (type === PerpsProvider.PERENNIAL_V2_ARB) return false

		if (oneClickSelected && !exceedsAbstractionBal) {
			// There is enough in the abstraction wallet to deposit to smart margin
			return false
		}

		if (providerIsCrossMargin(type)) {
			return v3ExceedsBalance
		}

		return deposit.gt(walletEthBalance)
	}
)

export const selectOneClickTradeBtnI18n = createSelector(
	selectOneClickTradingSelected,
	selectAbstractionDelegated,
	selectKeeperDepositExceedsBal,
	(selected, isDelegated, exceedsAbstractionBal) => {
		if (!exceedsAbstractionBal && selected) {
			return isDelegated
				? 'futures.one-click-trading.start-session'
				: 'futures.one-click-trading.onboard.activate'
		}
	}
)

export const selectIsFetchingTradePreview = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_trade_preview_trade']?.status === FetchStatus.Loading
)

export const selectIsFetchingTradePreviewEdit = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_trade_preview_edit']?.status === FetchStatus.Loading
)

export const selectModalSLTPValidity = createSelector(
	selectSlTpModalInputs,
	selectEditPositionModalInfo,
	selectPerpsProvider,
	({ takeProfitPrice, stopLossPrice }, modalInfo, type) => {
		const { position, onChainPrice, marketPrice } = modalInfo

		// Remove onchain price for V3.
		const tpValidity = takeProfitValidity(
			takeProfitPrice,
			position?.details?.side || PositionSide.LONG,
			marketPrice,
			type === PerpsProvider.SNX_V2_OP ? onChainPrice : undefined
		)
		const slValidity = stopLossValidity(
			stopLossPrice,
			position?.details?.liquidationPrice,
			position?.details?.side || PositionSide.LONG,
			marketPrice,
			type === PerpsProvider.SNX_V2_OP ? onChainPrice : undefined
		)

		return {
			takeProfit: tpValidity,
			stopLoss: slValidity,
		}
	}
)

export const selectTradePreview = createSelector(
	selectAccountData,
	selectPerpsProvider,
	selectCrossMarginMarginInfo,
	selectMarketInfo,
	selectOrderFlowFee,
	(state: RootState) => state.futures.tradePreviews,
	(accountData, type, v3MarginInfo, market, orderFlowFee, previews) => {
		const preview = previews[type]

		if (!preview) return null

		const formattedPreview =
			preview.marginType === FuturesMarginType.CROSS_MARGIN
				? unserializeCrossMarginTradePreview(preview)
				: unserializeIsolatedMarginTradePreview(preview)

		if (!formattedPreview || !market) return
		const liqPrice =
			market.marginType === FuturesMarginType.CROSS_MARGIN
				? calculateV3LiqPrice(v3MarginInfo, formattedPreview as SnxPerpsV3TradePreview, market)
				: formattedPreview.liqPrice

		if (preview.provider === PerpsProvider.SNX_V2_OP) {
			formattedPreview.fee = formattedPreview.fee.add(orderFlowFee)
		}

		// @ts-ignore no margin on v3
		const margin = wei(preview?.margin ?? 0)
		if (preview) {
			const priceImpact = getDefaultPriceImpact(OrderTypeEnum.MARKET)
			const desiredFillPrice = calculateDesiredFillPrice(
				wei(preview.sizeDelta || 0),
				formattedPreview.fillPrice,
				priceImpact
			)

			return {
				...formattedPreview,
				margin,
				keeperEthDeposit: wei(
					preview.marginType !== FuturesMarginType.CROSS_MARGIN ? preview.keeperEthDeposit : 0
				),
				liqPrice:
					!accountData?.account && preview.provider === PerpsProvider.SNX_V3_BASE
						? undefined
						: liqPrice,
				desiredFillPrice,
				leverage:
					preview.provider === PerpsProvider.PERENNIAL_V2_ARB
						? wei(preview.leverage)
						: margin?.gt(0)
						? formattedPreview.notionalValue.div(margin).abs()
						: wei(0),
			}
		}
		return null
	}
)

export const selectAverageEntryPrice = createSelector(
	selectTradePreview,
	selectSelectedMarketPositionHistory,
	(tradePreview, positionHistory) => {
		if (positionHistory && tradePreview) {
			const { avgEntryPrice, side, size } = positionHistory
			const currentSize = side === PositionSide.SHORT ? size.neg() : size

			// If the trade switched sides (long -> short or short -> long), use oracle price
			if (currentSize.mul(tradePreview.newSize).lt(0)) return tradePreview.fillPrice

			// If the trade reduced position size on the same side, average entry remains the same
			if (tradePreview.newSize.abs().lt(size)) return avgEntryPrice

			// If the trade increased position size on the same side, calculate new average
			const existingValue = avgEntryPrice.mul(size)
			const newValue = tradePreview.fillPrice.mul(tradePreview.sizeDelta.abs())
			const totalValue = existingValue.add(newValue)
			return tradePreview.newSize.abs().gt(0) ? totalValue.div(tradePreview.newSize.abs()) : wei(0)
		}
		return null
	}
)

type PositionPreviewData = {
	fillPrice: Wei
	sizeIsNotZero: boolean
	positionSide: string
	positionSize: Wei
	leverage: Wei
	liquidationPrice: Wei
	avgEntryPrice: Wei
	notionalValue: Wei
	showStatus: boolean
}

export const selectPositionPreviewData = createSelector(
	selectTradePreview,
	selectPosition,
	selectAverageEntryPrice,
	(tradePreview, position, modifiedAverage) => {
		if (!position || !tradePreview || tradePreview?.marginType === FuturesMarginType.CROSS_MARGIN) {
			return null
		}

		return {
			fillPrice: tradePreview.fillPrice,
			sizeIsNotZero: tradePreview.newSize && !tradePreview.newSize?.eq(0),
			positionSide: tradePreview.newSize?.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
			positionSize: tradePreview.newSize?.abs(),
			notionalValue: tradePreview.notionalValue,
			leverage: tradePreview.margin.gt(0)
				? tradePreview.notionalValue.div(tradePreview.margin).abs()
				: ZERO_WEI,
			liquidationPrice: tradePreview.liqPrice,
			avgEntryPrice: modifiedAverage || ZERO_WEI,
			showStatus: tradePreview.showStatus,
		} as PositionPreviewData
	}
)

export const selectTradePanelSLTPValidity = createSelector(
	selectPerpsProvider,
	selectSlTpTradeInputs,
	selectTradePreview,
	selectTradePrice,
	selectMarketOnchainPrice,
	selectLeverageSide,
	(
		type,
		{ stopLossPrice, takeProfitPrice },
		smTradePreview,
		tradePrice,
		onChainPrice,
		leverageSide
	) => {
		if (providerIsCrossMargin(type)) {
			// TODO: Currently cross margin has no liquidation price value in the trade preview to base a max stop loss off of
			const furthestPrice = leverageSide === PositionSide.LONG ? wei(0) : wei(tradePrice.mul(10))
			return {
				takeProfit: takeProfitValidity(takeProfitPrice, leverageSide, tradePrice),
				stopLoss: {
					invalidLabel: undefined,
					minMaxStopPrice: furthestPrice,
				},
			}
		}

		const chainPrice =
			type === PerpsProvider.SNX_V2_OP && !onChainPrice.eq(0) ? onChainPrice : undefined

		const tpValidity = takeProfitValidity(takeProfitPrice, leverageSide, tradePrice, chainPrice)
		const slValidity = stopLossValidity(
			stopLossPrice,
			smTradePreview?.liqPrice || wei(0),
			leverageSide,
			tradePrice,
			chainPrice
		)
		return {
			takeProfit: tpValidity,
			stopLoss: slValidity,
		}
	}
)

export const selectClosePositionPreview = createSelector(selectTradePreview, (preview) => {
	return preview?.action === 'close' ? preview : undefined
})

export const selectEditPositionPreview = createSelector(selectTradePreview, (preview) => {
	return preview?.action === 'edit' ? preview : undefined
})

export const selectConditionalOrders = createSelector(
	selectAllCrossMarginOrderTableItems,
	selectAllIsolatedConditionalOrders,
	(crossMarginOrders, isolatedMarginOrders) => {
		return {
			[PerpsProvider.SNX_V2_OP]: isolatedMarginOrders[PerpsProvider.SNX_V2_OP] ?? [],
			[PerpsProvider.PERENNIAL_V2_ARB]: isolatedMarginOrders[PerpsProvider.PERENNIAL_V2_ARB] ?? [],
			[PerpsProvider.SNX_V3_BASE]: crossMarginOrders[PerpsProvider.SNX_V3_BASE] ?? [],
			[PerpsProvider.SNX_V3_ARB]: crossMarginOrders[PerpsProvider.SNX_V3_ARB] ?? [],
		}
	}
)

export const selectConditionalOrderTableItems = createSelector(
	selectPerpsProvider,
	selectUserInfoShowAllMarkets,
	selectConditionalOrders,
	selectMarketAsset,
	(type, userInfoShowAllMarkets, allOrders, currentMarket) => {
		const orders = allOrders[type]
		return userInfoShowAllMarkets ? orders : orders.filter((o) => o.asset === currentMarket)
	}
)

export const selectConditionalOrdersCount = createSelector(
	selectConditionalOrderTableItems,
	(items) => items.length
)

export const selectEditSLTPModalInfo = createSelector(
	selectPerpsProvider,
	selectActivePositions,
	selectMarkets,
	selectShowConditionalOrderModal,
	selectConditionalOrders,
	(provider, positions, markets, modalinfo, orders) => {
		if (!modalinfo) return undefined
		const position = positions.find((p) => p.market.marketKey === modalinfo?.marketKey)
		const market = markets.find((m) => m.marketKey === modalinfo?.marketKey)

		const order = orders[provider].find((o) => o.id === modalinfo.id)
		return { order, market, position }
	}
)

export const selectSignerSupportsProviders = createSelector(
	selectSignerSupportsSnxV3Base,
	selectSignerSupportsSnxV2Op,
	selectSignerSupportsPerennialArb,
	selectSignerSupportsSnxV3Arb,
	(snxV3Base, snxV2, perennial, snxV3Arb) => ({
		[PerpsProvider.SNX_V3_BASE]: snxV3Base,
		[PerpsProvider.SNX_V2_OP]: snxV2,
		[PerpsProvider.PERENNIAL_V2_ARB]: perennial,
		[PerpsProvider.SNX_V3_ARB]: snxV3Arb,
	})
)

export const selectShowSwitchNetwork = createSelector(
	selectWallet,
	selectSignerSupportsProviders,
	selectPerpsProvider,
	selectShowModal,
	selectIsWatcherMode,
	(wallet, signerSupports, provider, openModal, watching) => {
		const supported = signerSupports[provider]
		return (
			!watching && wallet && !supported && openModal !== 'futures_deposit_withdraw_isolated_margin'
		)
	}
)

export const selectProfitCalculatorVisible = (state: RootState) =>
	state.futures.preferences.profitCalculatorVisible

export const selectFavoriteMarkets = (state: RootState) => {
	return state.futures.preferences.favoriteMarkets
}

export const selectUserInfoTableFilter =
	(futuresTab: TableFilteredFuturesTabs) => (state: RootState) =>
		state.futures.preferences.userInfoTableFilter[futuresTab]

export const selectKeeperFeeAsset = createSelector(selectPerpsProvider, (type) => {
	return type === PerpsProvider.SNX_V3_BASE ? 'USDC' : 'ETH'
})

export const selectIsDraggingSlider = (state: RootState) =>
	state.futures.tradePanel.isDraggingSlider

export const selectAccountTransfers = createSelector(selectAccountData, (accountData) => {
	return accountData?.accountTransfers ?? []
})

export const selectAccountOrderHistory = createSelector(selectAccountData, (data) => {
	return data?.orderHistory ?? []
})

export const selectActiveConditionalOrdersForMarket = createSelector(
	selectPerpsProvider,
	selectMarketAsset,
	selectAccountData,
	selectCrossMarginOrderTableItemsForProvider,
	(type, asset, accountData, v3Orders) => {
		let orders = accountData?.conditionalOrders ?? []
		switch (type) {
			case PerpsProvider.SNX_V3_BASE:
				return v3Orders.filter((o) => o.status === 'Pending' && o.asset === asset)
			case PerpsProvider.PERENNIAL_V2_ARB:
			case PerpsProvider.SNX_V2_OP:
				orders = orders as ConditionalOrderV2<string>[]
				return unserializeConditionalOrdersV2(orders.filter((o) => o.asset === asset))
			default:
				return []
		}
	}
)

export const selectDashboardHistoryTableFilter = (historyTab: HistoryTab) => (state: RootState) =>
	state.futures.preferences.dashboardHistoryTableFilter[historyTab]

export const selectPositionsHistoryFilteredTableData = createSelector(
	selectPositionsHistoryTableData,
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter['positions'],
	(positions, tableFilter) => {
		return positions
			.filter((p) => shouldFilterHistoryTableByDate(new Date(p.openTimestamp), tableFilter))
			.filter((p) =>
				shouldFilterHistoryTable({ asset: p.asset, side: p.side, status: p.status }, tableFilter)
			)
	}
)

export const selectTradesHistoryFilteredTableData = createSelector(
	selectTradesHistoryTableData,
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter['trades'],
	(trades, tableFilter) => {
		return trades
			.filter((t) => shouldFilterHistoryTableByDate(new Date(t.timestamp), tableFilter))
			.filter((t) =>
				shouldFilterHistoryTable(
					{
						asset: t.displayAsset || '',
						side: t.side ?? undefined,
						settlementTxHash: t.settlementTxHash ?? undefined,
						committedTxHash: t.committedTxHash ?? undefined,
					},
					tableFilter
				)
			)
	}
)

export const selectAccountOrderFilteredHistory = createSelector(
	selectAccountOrderHistory,
	(state: RootState) => state.futures.preferences.userInfoTableFilter?.['order_history'],
	(state: RootState) => state.futures.preferences.dashboardHistoryTableFilter?.['order_history'],
	(orders, userInfoTableFilter, dashboardTableFilter) => {
		const pathName = window.location.pathname
		return pathName.includes('market')
			? orders
					.filter((o) => shouldFilterUserInfoTable(new Date(o.timestamp), userInfoTableFilter))
					.filter(
						(o) =>
							!userInfoTableFilter?.type ||
							userInfoTableFilter?.type === 'all_orders' ||
							toSnakeCase(o.orderType) === userInfoTableFilter?.type
					)
			: orders
					.filter((o) =>
						shouldFilterHistoryTableByDate(new Date(o.timestamp), dashboardTableFilter)
					)
					.filter((o) =>
						shouldFilterHistoryTable(
							{
								asset: o.displayAsset || '',
								type: o.orderType,
								side: o.side,
								status: o.status,
								settlementTxHash: o.txnHash || '',
							},
							dashboardTableFilter
						)
					)
	}
)

export const selectAccountFilteredTransfers = createSelector(
	selectAccountTransfers,
	(state: RootState) => state.futures.preferences.userInfoTableFilter?.['transaction_history'],
	(state: RootState) =>
		state.futures.preferences.dashboardHistoryTableFilter?.['transaction_history'],
	(transfers, userInfoTableFilter, dashboardTableFilter) => {
		const pathName = window.location.pathname
		return pathName.includes('market')
			? transfers
					.filter((tx) => shouldFilterUserInfoTable(new Date(tx.timestamp), userInfoTableFilter))
					.filter(
						(tx) =>
							!userInfoTableFilter?.type ||
							userInfoTableFilter?.type === 'all_transactions' ||
							toSnakeCase(tx.action) === userInfoTableFilter?.type
					)
			: transfers
					.filter((tx) =>
						shouldFilterHistoryTableByDate(new Date(tx.timestamp), dashboardTableFilter)
					)
					.filter((tx) =>
						shouldFilterHistoryTable(
							{ asset: tx.asset, settlementTxHash: tx.txHash, type: tx.action },
							dashboardTableFilter
						)
					)
	}
)

export const selectUserInfoOrderHistory = createSelector(
	selectAccountOrderFilteredHistory,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(orderHistory, userInfoShowAllMarkets, currentMarket) => {
		if (userInfoShowAllMarkets) {
			return orderHistory
		}
		return orderHistory.filter((order) => order.displayAsset === getDisplayAsset(currentMarket))
	}
)

export const selectUserInfoTradeHistory = createSelector(
	selectTradesHistoryTableData,
	selectUserInfoShowAllMarkets,
	selectMarketAsset,
	(tradeHistory, userInfoShowAllMarkets, currentMarket) => {
		if (userInfoShowAllMarkets) {
			return tradeHistory
		}
		return tradeHistory.filter((trade) => trade.displayAsset === getDisplayAsset(currentMarket))
	}
)

export const selectPerpsInterestRate = createSelector(selectV3SelectedMarketInfo, (marketInfo) => {
	const rate =
		marketInfo?.marginType === FuturesMarginType.CROSS_MARGIN
			? marketInfo?.settings.interestRate
			: undefined
	const rateWei = wei(rate ?? 0)
	return {
		yearly: rateWei,
		daily: rateWei.gt(0) ? rateWei.div(365) : wei(0),
		hourly: rateWei.gt(0) ? rateWei.div(365 * 24) : wei(0),
	}
})

export const selectLoadingGloablHistory = createSelector(selectQueryStatuses, (statuses) => {
	return statuses['get_global_trades']?.status === FetchStatus.Loading
})

export const selectPlaceOrderTranslationKey = createSelector(
	selectPerpsProvider,
	selectCMPlaceOrderTranslationKey,
	selectSMPlaceOrderTranslationKey,
	(perpsProvider, cmTranslationKey, smTranslationKey) => {
		return providerIsCrossMargin(perpsProvider) ? cmTranslationKey : smTranslationKey
	}
)

export const selectPlaceOrderDisabledReason = createSelector(
	selectPerpsProvider,
	selectCMPlaceOrderDisabledReason,
	selectIsolatedTradeDisabledReason,
	(perpsProvider, cmPlaceOrderDisabledReason, smPlaceOrderDisabledReason) => {
		return providerIsCrossMargin(perpsProvider)
			? cmPlaceOrderDisabledReason
			: smPlaceOrderDisabledReason
	}
)

export const selectTradesQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_trade_history']?.status
)

export const selectMarginTransfersQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_margin_transfers']?.status
)

export const selectOrderHistoryQueryStatus = createSelector(
	selectQueryStatuses,
	(statuses) => statuses['get_order_history']?.status
)

export const selectTradeHistoryByPerpsProvider = createSelector(
	selectPerpsProvider,
	selectMarketAsset,
	(state: RootState) => state.futures.providerData.globalTradeHistory,
	(perpsProvider, marketAsset, tradeHistory) => {
		return tradeHistory[perpsProvider][marketAsset] ?? []
	}
)

export const selectGlobalTradeHistoryTableData = createSelector(
	selectPerpsProvider,
	selectTradeHistoryByPerpsProvider,
	selectGlobalLiquidationsForMarket,
	(perpsProvider, globalTrades, crossMarginLiquidations) => {
		if (providerIsCrossMargin(perpsProvider)) {
			// TODO: Paginate cross margin trades
			const trades = unserializeTrades(globalTrades).map((trade) => {
				return {
					value: Number(trade.fillPrice),
					amount: trade.sizeDelta,
					time: Number(trade.timestamp),
					id: trade.id,
					orderType: 'market',
					account: trade.account,
					ownerAddress: trade.ownerAddress,
				}
			})

			const oldestTrade = trades[trades.length - 1]

			const liquidations = crossMarginLiquidations
				.filter((l) => l.timestamp > oldestTrade?.time)
				.map((liquidation) => {
					return {
						value: Number(liquidation.estimatedPrice),
						amount: liquidation.amount,
						time: Number(liquidation.timestamp),
						id: liquidation.id,
						orderType: 'Liquidation',
						account: liquidation.accountId,
						ownerAddress: liquidation.accountOwner,
					}
				})

			return liquidations.concat(trades).sort((a, b) => b.time - a.time)
		} else {
			const futuresTrades =
				globalTrades.length > 0
					? unserializeTrades(globalTrades)
							.flat()
							.filter(notNill)
							.map((trade) => {
								const size = trade.side === 'long' ? trade.sizeDelta : trade.sizeDelta.abs().neg()
								return {
									value: Number(trade.fillPrice),
									amount: size,
									time: Number(trade.timestamp),
									id: trade.txnHash,
									orderType: trade.orderType,
									account: trade.account,
									ownerAddress: trade.account,
								}
							})
							.filter((trade) => trade.amount.abs().gt(0.000001))
					: []
			return [...new Set(futuresTrades)]
		}
	}
)

export const selectAvailableMargin = createSelector(
	selectPerpsProvider,
	selectCrossMarginAvailableMargin,
	selectIsolatedAvailableMargin,
	(perpsProvider, v3Margin, v2Margin) => {
		return providerIsCrossMargin(perpsProvider) ? v3Margin : v2Margin
	}
)

export const selectActivePositionsCount = createSelector(
	selectPerpsProvider,
	selectActiveCrossMarginPositionsCount,
	selectIsolatedPositionsCount,
	(perpsProvider, cmCounts, isolatedCounts) => {
		return { ...isolatedCounts, ...cmCounts }[perpsProvider]
	}
)

export const selectMarginSnapshots = createSelector(
	selectPerpsProvider,
	selectV3MarginChartData,
	selectSmartMarginPortfolioValues,
	(perpsProvider, v3Data, v2Data) => {
		switch (perpsProvider) {
			case PerpsProvider.SNX_V3_BASE:
				return v3Data
			case PerpsProvider.SNX_V2_OP:
				return v2Data
			default:
				return []
		}
	}
)
export const selectAllSLTPOrders = createSelector(
	selectAllSnxV2SLTPOrders,
	selectAllSnxV3SLTPOrders,
	selectAllPerennialSLTPOrders,
	(snxV2Orders, snxV3Orders, perennialOrders) => {
		return {
			[PerpsProvider.PERENNIAL_V2_ARB]: perennialOrders,
			[PerpsProvider.SNX_V2_OP]: snxV2Orders,
			[PerpsProvider.SNX_V3_BASE]: snxV3Orders,
			[PerpsProvider.SNX_V3_ARB]: snxV3Orders,
		}
	}
)

export const selectSLTPModalInfo = createSelector(
	selectAllSLTPOrders,
	selectEditPositionModalInfo,
	(allOrders, modalInfo) => {
		if (!modalInfo?.market)
			return {
				takeProfitPrice: '',
				stopLossPrice: '',
			}

		const orders = allOrders[modalInfo.market.provider]
		const sl = orders.find(
			(o) => o.marketKey === modalInfo.market?.marketKey && o.orderTypeDisplay === 'Stop Loss'
		)
		const tp = orders.find(
			(o) => o.marketKey === modalInfo.market?.marketKey && o.orderTypeDisplay === 'Take Profit'
		)

		return {
			takeProfitPrice: tp?.targetPrice ? stripZeros(tp.targetPrice.toString()) : '',
			stopLossPrice: sl?.targetPrice ? stripZeros(sl.targetPrice.toString()) : '',
			position: modalInfo.position,
			market: modalInfo.market,
		}
	}
)

export const selectMissingKeeperDeposit = createSelector(
	selectRequiredKeeperEthDeposit,
	selectRequiredKeeperUsdDeposit,
	selectPerpsProvider,
	(eth, usd, perpsProvider) => {
		if (perpsProvider === PerpsProvider.PERENNIAL_V2_ARB) return wei(0)
		return perpsProvider === PerpsProvider.SNX_V3_BASE ? usd : eth
	}
)
