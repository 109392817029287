import KwentaSDK from '@kwenta/sdk'
import { MirrorPost, PerpsMarketV2, PerpsMarketV3 } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { notifyError } from 'components/ErrorNotifier'
import { ThunkConfig } from 'state/types'
import { serializeV2Markets, serializeV3Markets } from 'utils/futures'
import logError from 'utils/logError'

export const fetchOptimismMarkets = createAsyncThunk<
	{ markets: PerpsMarketV2<string>[] },
	void,
	ThunkConfig
>('home/fetchOptimismMarkets', async (_, { extra: { sdk } }) => {
	// For the home page we always fetch OP mainnet markets
	const markets = await sdk.snxPerpsV2.getMarkets(10)

	const serializedMarkets = serializeV2Markets(markets)
	return { markets: serializedMarkets }
})

export const fetchBaseMarkets = createAsyncThunk<
	{ markets: PerpsMarketV3<string>[] },
	void,
	ThunkConfig
>('home/fetchBaseMarkets', async (_, { extra: { sdk } }) => {
	// For the home page we always fetch BASE mainnet markets
	const markets = await sdk.snxPerpsV3.getMarkets(8453)
	const serializedMarkets = serializeV3Markets(markets)
	return { markets: serializedMarkets }
})

export const fetchFuturesStats = createAsyncThunk<
	Awaited<ReturnType<KwentaSDK['stats']['getFuturesStats']>>,
	void,
	ThunkConfig
>('home/fetchFuturesStats', async (_, { extra: { sdk } }) => {
	try {
		return await sdk.stats.getFuturesStats(10)
	} catch (error) {
		logError(error)
		throw error
	}
})

export const fetchMirrorPosts = createAsyncThunk<MirrorPost[], void, ThunkConfig>(
	'home/fetchMirrorPosts',
	async (_, { extra: { sdk } }) => {
		try {
			return sdk.mirror.getMirrorPosts()
		} catch (error) {
			logError(error)
			notifyError('Failed to fetch mirror posts', error)
			throw error
		}
	}
)
