import KwentaSDK from '@kwenta/sdk'
import { PerpsMarketV2, PerpsMarketV3, MirrorPost } from '@kwenta/sdk/types'
import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from 'state/types'

import {
	fetchBaseMarkets,
	fetchFuturesStats,
	fetchMirrorPosts,
	fetchOptimismMarkets,
} from './actions'

type HomeState = {
	optimismMarkets: PerpsMarketV2<string>[]
	baseMarkets: PerpsMarketV3<string>[]
	marketsQueryStatus: FetchStatus
	futuresStatsQueryStatus: FetchStatus
	futuresStats: Awaited<ReturnType<KwentaSDK['stats']['getFuturesStats']>>
	mirrorPostsQueryStatus: FetchStatus
	mirrorPosts: MirrorPost[]
}

export const HOME_INITIAL_STATE: HomeState = {
	optimismMarkets: [],
	baseMarkets: [],
	marketsQueryStatus: FetchStatus.Idle,
	futuresStatsQueryStatus: FetchStatus.Idle,
	futuresStats: [],
	mirrorPosts: [],
	mirrorPostsQueryStatus: FetchStatus.Idle,
}

export const homeSlice = createSlice({
	name: 'home',
	initialState: HOME_INITIAL_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchOptimismMarkets.pending, (state) => {
			state.marketsQueryStatus = FetchStatus.Loading
		})
		builder.addCase(fetchOptimismMarkets.fulfilled, (state, action) => {
			state.optimismMarkets = action.payload.markets
		})
		builder.addCase(fetchOptimismMarkets.rejected, (state) => {
			state.marketsQueryStatus = FetchStatus.Error
		})
		builder.addCase(fetchBaseMarkets.pending, (state) => {
			state.marketsQueryStatus = FetchStatus.Loading
		})
		builder.addCase(fetchBaseMarkets.fulfilled, (state, action) => {
			state.baseMarkets = action.payload.markets
		})
		builder.addCase(fetchBaseMarkets.rejected, (state) => {
			state.marketsQueryStatus = FetchStatus.Error
		})
		builder.addCase(fetchFuturesStats.pending, (state) => {
			state.futuresStatsQueryStatus = FetchStatus.Loading
		})
		builder.addCase(fetchFuturesStats.fulfilled, (state, action) => {
			state.futuresStatsQueryStatus = FetchStatus.Success
			state.futuresStats = action.payload
		})
		builder.addCase(fetchFuturesStats.rejected, (state) => {
			state.futuresStatsQueryStatus = FetchStatus.Error
		})
		builder.addCase(fetchMirrorPosts.pending, (state) => {
			state.mirrorPostsQueryStatus = FetchStatus.Loading
		})
		builder.addCase(fetchMirrorPosts.fulfilled, (state, action) => {
			state.mirrorPostsQueryStatus = FetchStatus.Success
			state.mirrorPosts = action.payload
		})
		builder.addCase(fetchMirrorPosts.rejected, (state) => {
			state.mirrorPostsQueryStatus = FetchStatus.Error
		})
	},
})

export default homeSlice.reducer
