import request, { gql } from 'graphql-request';
import { DEFAULT_SUBGRAPH_ENDPOINT } from '../constants/futures';
import { ADDRESSES_PER_LOOKUP, DEFAULT_LEADERBOARD_DATA, ENS_REVERSE_LOOKUP, } from '../constants/stats';
import { ETH_UNIT } from '../constants/transactions';
import { mapAndSortStats, mapStat, mapStatRank } from '../utils/stats';
import { truncateAddress } from '../utils/string';
import { parseAbi } from 'viem';
import chunk from 'lodash/chunk';
import { getFuturesEndpoint } from '../utils';
import { getFuturesStats } from '../queries/futures';
export default class StatsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async getStatsVolumes() { }
    async getFuturesTradersStats() { }
    async getFuturesStats(chainId) {
        try {
            const response = await getFuturesStats(getFuturesEndpoint(chainId), {
                first: 10,
                orderBy: 'pnlWithFeesPaid',
                orderDirection: 'desc',
            });
            const stats = response.map((stat, i) => ({
                trader: stat.account,
                traderShort: truncateAddress(stat.account),
                pnl: stat.pnlWithFeesPaid.div(ETH_UNIT).toString(),
                totalVolume: stat.totalVolume.div(ETH_UNIT).toString(),
                totalTrades: stat.totalTrades.toNumber(),
                liquidations: stat.liquidations.toNumber(),
                rank: i + 1,
                rankText: (i + 1).toString(),
            }));
            return stats;
        }
        catch (e) {
            this.sdk.context.logError(e);
            return [];
        }
    }
    async getV2Leaderboard(searchTerm, account, first = 10000, skip = 0) {
        try {
            const query = gql `
				fragment StatsBody on FuturesStat {
					accountOwner: account
					pnl
					pnlWithFeesPaid
					liquidations
					totalTrades
					totalVolume
				}

				query leaderboardStats($account: String!, $searchTerm: String!, $first: Int!, $skip: Int!) {
					top: futuresStats(orderBy: pnlWithFeesPaid, orderDirection: desc) {
						...StatsBody
					}
					wallet: futuresStats(where: { account: $account }) {
						...StatsBody
					}
					search: futuresStats(where: { account_contains: $searchTerm }) {
						...StatsBody
					}
				}
			`;
            const response = await request(DEFAULT_SUBGRAPH_ENDPOINT.v2, query, { account, searchTerm, first, skip });
            // TODO: Improve the time complexity of this operation.
            // We *should* be able to add the ENS and merge at the same time.
            const ensInfo = await this.batchGetENS(Object.values(response)
                .flat(1)
                .map(({ accountOwner }) => accountOwner));
            const statTransform = mapStat(ensInfo);
            const stats = {
                all: mapAndSortStats([...response.top, ...response.wallet, ...response.search]).map(statTransform),
                wallet: response.wallet.map(statTransform),
                search: searchTerm && searchTerm !== '' ? response.search.map(statTransform) : [],
            };
            return { ...stats };
        }
        catch (e) {
            this.sdk.context.logError(e);
            return DEFAULT_LEADERBOARD_DATA;
        }
    }
    async getV3Leaderboard(searchTerm, account, first = 10000, skip = 0) {
        try {
            const query = gql `
				fragment StatsBody on PerpsV3Stat {
					accountId
					accountOwner
					pnl
					pnlWithFeesPaid
					liquidations
					totalTrades
					totalVolume
				}

				query leaderboardStats($account: String!, $searchTerm: String!, $first: Int!, $skip: Int!) {
					top: perpsV3Stats(
						first: $first
						skip: $skip
						orderBy: pnlWithFeesPaid
						orderDirection: desc
					) {
						...StatsBody
					}
					wallet: perpsV3Stats(where: { accountOwner: $account }) {
						...StatsBody
					}
					search: perpsV3Stats(where: { accountOwner_contains: $searchTerm }) {
						...StatsBody
					}
				}
			`;
            const response = await request(DEFAULT_SUBGRAPH_ENDPOINT.v3, query, { account, searchTerm, first, skip });
            // TODO: Improve the time complexity of this operation.
            // We *should* be able to add the ENS and merge at the same time.
            const ensInfo = await this.batchGetENS(Object.values(response)
                .flat(1)
                .map(({ accountOwner }) => accountOwner));
            const statTransform = mapStat(ensInfo);
            const allStats = mapAndSortStats([...response.top, ...response.wallet, ...response.search]);
            const statRankTransform = mapStatRank(allStats);
            const stats = {
                all: allStats.map(statTransform),
                wallet: response.wallet.map(statTransform).map(statRankTransform),
                search: searchTerm && searchTerm !== '' ? response.search.map(statTransform) : [],
            };
            return { ...stats };
        }
        catch (e) {
            this.sdk.context.logError(e);
            return DEFAULT_LEADERBOARD_DATA;
        }
    }
    async batchGetENS(addresses) {
        const config = {
            address: ENS_REVERSE_LOOKUP,
            abi: parseAbi(['function getNames(address[] addresses) external view returns (string[] r)']),
            functionName: 'getNames',
        };
        const addressesChunks = chunk(addresses, ADDRESSES_PER_LOOKUP);
        const ensResults = await this.sdk.context.l1MainnetProvider.multicall({
            allowFailure: false,
            contracts: addressesChunks.map((addresses) => ({
                ...config,
                args: [addresses],
            })),
        });
        const ensInfo = {};
        ensResults.forEach((result, i) => {
            const addresses = addressesChunks[i];
            result.forEach((name, j) => {
                if (name !== '') {
                    ensInfo[addresses[j]] = name;
                }
            });
        });
        return ensInfo;
    }
}
