import { decodeFunctionResult, encodeFunctionData, isAddress } from 'viem';
import { SNX_V3_PERPS_ADDRESSES } from '../constants';
import MARGIN_ENGINE_ABI from '../contracts/abis/MarginEngine';
import Multicall from '../contracts/abis/Multicall3_1';
import TrustedMulticallForwarder from '../contracts/abis/TrustedMulticallForwarder';
import { EIP7412 } from './eip7412/EIP7412';
export default class CallsService {
    constructor(sdk) {
        this.sdk = sdk;
        this.eip7412 = new EIP7412();
    }
    async erc7412Read(txs, chainId, predefinedOracles = []) {
        const client = this.sdk.context.eip7412clients[chainId];
        if (!client)
            throw new Error(`Client not found for chainId: ${chainId}`);
        const txn = await this.eip7412.enableERC7412(client, txs, multicallReads, {
            predefinedOracles,
            chainId,
            useMarginEngine: false,
        });
        const result = await client.call({
            account: '0x4200000000000000000000000000000000000006', // simulate w/ wETH contract because it will have eth
            data: txn.data,
            to: txn.to,
            value: txn.value,
        });
        const decodedFunctionResult = decodeFunctionResult({
            abi: Multicall,
            functionName: 'aggregate3Value',
            data: result.data,
        });
        const last = decodeFunctionResult({
            abi: Multicall,
            functionName: 'aggregate3Value',
            data: decodedFunctionResult[decodedFunctionResult.length - 1].returnData,
        });
        return last;
    }
    async erc7412Write(txs, chainId, useMarginEngine = false) {
        const txn = await this.eip7412.enableERC7412(this.sdk.context.signerPublicEIP7412Client, txs, (calls) => multicallWrites(calls, chainId, useMarginEngine, this.sdk.context.walletAddress), {
            chainId,
            useMarginEngine,
        });
        return txn;
    }
}
export const multicallWrites = (calls, chainId, useMarginEngine = false, walletAddress) => {
    const address = useMarginEngine
        ? SNX_V3_PERPS_ADDRESSES.MarginEngine[chainId]
        : SNX_V3_PERPS_ADDRESSES.SnxV3TrustedMulticallForwarder[chainId];
    if (!isAddress(address)) {
        throw new Error(`Invalid multicall address: ${address}`);
    }
    const multicallData = useMarginEngine
        ? encodeFunctionData({
            abi: MARGIN_ENGINE_ABI,
            functionName: 'multicall',
            args: [calls.map((c) => c.data)],
        })
        : encodeFunctionData({
            abi: TrustedMulticallForwarder,
            functionName: 'aggregate3Value',
            args: [
                calls.map((c) => ({
                    target: c.to,
                    requireSuccess: true,
                    value: c.value || BigInt(0),
                    callData: c.data,
                })),
            ],
        });
    let totalValue = BigInt(0);
    for (const call of calls) {
        totalValue += call.value || BigInt(0);
    }
    return {
        account: walletAddress,
        to: address,
        data: multicallData,
        value: totalValue,
    };
};
export const multicallReads = (calls, chainId) => {
    const address = SNX_V3_PERPS_ADDRESSES.SnxV3TrustedMulticallForwarder[chainId];
    if (!isAddress(address)) {
        throw new Error(`Invalid TrustedMulticallForwarder address: ${address}`);
    }
    const ret = encodeFunctionData({
        abi: TrustedMulticallForwarder,
        functionName: 'aggregate3Value',
        args: [
            calls.map((call) => ({
                target: call.to,
                callData: call.data,
                value: call.value || BigInt(0),
                requireSuccess: true,
            })),
        ],
    });
    let totalValue = BigInt(0);
    for (const call of calls) {
        totalValue += call.value || BigInt(0);
    }
    return {
        to: address,
        data: ret,
        value: BigInt(totalValue),
    };
};
