import { EventEmitter } from 'events';
import { createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains';
import * as sdkErrors from './constants/errors';
import { allContractsByNetwork } from './contracts';
import { SupportedNetworkIds, } from './types';
import { createClients, createEIP7412Clients } from './utils';
const DEFAULT_CHAIN = process.env.NEXT_PUBLIC_PERPS_VERSIONS_ENABLED === 'v2'
    ? SupportedNetworkIds.OPTIMISM_MAINNET
    : SupportedNetworkIds.BASE_MAINNET;
const DEFAULT_CONTEXT = {
    supportedChains: [DEFAULT_CHAIN],
};
export default class Context {
    constructor(context) {
        this.events = new EventEmitter().setMaxListeners(100);
        this.context = { ...DEFAULT_CONTEXT, ...context };
        if (context.walletClient) {
            this.setWalletClient(context.walletClient);
        }
        this.clients = createClients(this.context.supportedChains);
        this.eip7412clients = createEIP7412Clients(this.context.supportedChains);
        this.contractConfigs = this.context.supportedChains.reduce((acc, network) => {
            acc[network] = allContractsByNetwork(network);
            return acc;
        }, {});
        this.l1MainnetProvider = createPublicClient({
            chain: mainnet,
            transport: http(),
        });
    }
    get supportedChains() {
        return this.context.supportedChains;
    }
    get signerChainId() {
        if (!this.context.walletClient) {
            throw new Error(sdkErrors.NO_SIGNER);
        }
        return this.context.walletClient.chain.id;
    }
    get signerPublicClient() {
        const client = this.clients[this.signerChainId] ?? this.clients[DEFAULT_CHAIN];
        if (!client)
            throw new Error('No client found for network ' + this.signerChainId);
        return client;
    }
    get signerPublicEIP7412Client() {
        const client = this.eip7412clients[this.signerChainId] ?? this.eip7412clients[DEFAULT_CHAIN];
        if (!client)
            throw new Error('No client found for network ' + this.signerChainId);
        return client;
    }
    get walletClient() {
        if (!this.context.walletClient) {
            throw new Error(sdkErrors.NO_SIGNER);
        }
        return this.context.walletClient;
    }
    get walletAddress() {
        if (!this.context.walletAddress) {
            throw new Error(sdkErrors.NO_SIGNER);
        }
        return this.context.walletAddress;
    }
    get isBase() {
        return [8453, 84532].includes(this.signerChainId);
    }
    get isL2() {
        return [10, 11155420].includes(this.signerChainId);
    }
    get isMainnet() {
        return [1, 10].includes(this.signerChainId);
    }
    get isTestnet() {
        return [11155420, 84532].includes(this.signerChainId);
    }
    get apiUrl() {
        if (!process.env.NEXT_PUBLIC_KWENTA_SERVER)
            throw new Error('NEXT_PUBLIC_KWENTA_SERVER not set');
        return process.env.NEXT_PUBLIC_KWENTA_SERVER;
    }
    publicClient(chainId) {
        const client = this.clients[chainId];
        if (client)
            return client;
        throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
    }
    async setWalletClient(client) {
        // TODO: Handle multiple addresses
        this.context.walletAddress = (await client.getAddresses())[0];
        this.context.walletClient = client;
    }
    setWalletAddress(walletAddress) {
        this.context.walletAddress = walletAddress;
    }
    logError(err, skipReport = false) {
        return this.context.logError?.(err, skipReport);
    }
}
